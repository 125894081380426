import Axios from 'axios';
import UserController from './UserController';
import { APIPath, APIRoute } from '../helpers/Constants';
import { generateResponse } from '../helpers/Utils';

const MoneyPlanController = {
	getMyInstance,
	updateInstance,
	addNewValue,
	editValue,
	deleteValue,
};

async function getMyInstance() {
	const userHeaders = await UserController.getUserHeaders();
	return Axios.get(APIRoute + APIPath.GET_MY_INSTANCE, userHeaders)
		.then(response => {
			return generateResponse(false, response.data, response);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function updateInstance(state) {
	const userHeaders = await UserController.getUserHeaders();
	const instanceModel = { state };
	return Axios.post(APIRoute + APIPath.UPDATE_INSTANCE, instanceModel, userHeaders)
		.then(response => {
			return generateResponse(false, response.data, response);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function addNewValue(icon, category, value, yearlyOccurrence, description) {
	const userHeaders = await UserController.getUserHeaders();
	const moneyPlanModel = {
		icon,
		category,
		value,
		yearlyOccurrence,
		description,
	};
	return Axios.post(APIRoute + APIPath.ADD_NEW_VALUE, moneyPlanModel, userHeaders)
		.then(response => {
			return generateResponse(false, response.data, response);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function editValue(id, icon, category, value, yearlyOccurrence, description) {
	const userHeaders = await UserController.getUserHeaders();
	const moneyPlanModel = {
		id,
		icon,
		category,
		value,
		yearlyOccurrence,
		description,
	};
	return Axios.post(APIRoute + APIPath.EDIT_VALUE, moneyPlanModel, userHeaders)
		.then(response => {
			return generateResponse(false, response.data, response);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function deleteValue(id) {
	const userHeaders = await UserController.getUserHeaders();
	return Axios.post(APIRoute + APIPath.DELETE_VALUE + id, null, userHeaders)
		.then(response => {
			return generateResponse(false, response.data, response);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

export default MoneyPlanController;
