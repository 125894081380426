import React from 'react';
import { Container, Typography, makeStyles } from '@material-ui/core';
import { Colours } from '../../helpers/Colours';

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		color: Colours.bodyText,
		fontWeight: 300,
		minHeight: '50vh',
	},
}));

export default function NotFound() {
	const classes = useStyles();

	return (
		<Container maxWidth="xs" className={classes.root}>
			<Typography variant="h2" align="center">
				<b>404</b>
			</Typography>
			<Typography variant="subtitle2">Page Not Found</Typography>
		</Container>
	);
}
