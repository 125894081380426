import React from 'react';
import PropTypes from 'prop-types';
import { Container, makeStyles } from '@material-ui/core';
import { Colours } from '../../helpers/Colours';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		minHeight: '100%',
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},
	},
	centered: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	leftSide: {
		flexGrow: 1,
		backgroundColor: Colours.white,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		'& img': {
			maxHeight: 300,
		},
	},
	rightSide: {
		backgroundColor: Colours.bg_grey_1,
		[theme.breakpoints.down('xs')]: {
			paddingTop: 40,
			paddingBottom: 40,
		},
	},
}));

export function AuthLayout(props) {
	const { leftChild, rightChild } = props;
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Container className={`${classes.leftSide} ${classes.centered}`} maxWidth="sm">
				{leftChild}
			</Container>
			<Container className={`${classes.rightSide} ${classes.centered}`} maxWidth={false}>
				{rightChild}
			</Container>
		</div>
	);
}

AuthLayout.propTypes = {
	leftChild: PropTypes.object,
	rightChild: PropTypes.object,
};
