import React from 'react';
import { versionName, versionNum } from '../../helpers/Constants';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardHeader, CardContent, CardActions, Divider } from '@material-ui/core';
import VersionController from '../../controllers/VersionController';

const useStyles = makeStyles(theme => ({
	root: {
		position: 'fixed',
		bottom: 10,
		zIndex: 25,
		right: 10,
		width: 400,
		[theme.breakpoints.down('xs')]: {
			width: 'calc(100% - 20px)',
		},
	},
}));

export function CheckVersion() {
	const [needsUpdate, setNeedsUpdate] = React.useState(false);
	const classes = useStyles();

	const refreshNoCache = React.useCallback(() => {
		navigator.serviceWorker
			.getRegistrations()
			.then(registrations => {
				registrations.forEach(registration => {
					registration.unregister();
				});
			})
			.then(() => {
				window.location.reload(true);
			});
	}, []);

	const checkVersion = React.useCallback(async () => {
		const response = await VersionController.getVersion(versionName, versionNum);
		if (response.hasError) {
			setTimeout(() => {
				checkVersion();
			}, 10000);
		} else {
			setNeedsUpdate(response.data.num > versionNum);
			if (response.data.isRequired) {
				refreshNoCache();
			}
		}
	}, [refreshNoCache]);

	// initialise
	React.useState(() => {
		checkVersion();
	}, [checkVersion]);

	return needsUpdate ? (
		<Card elevation={6} className={classes.root}>
			<CardHeader title="New Update Available" />
			<Divider />
			<CardContent>
				You have an out of date version currently loaded. This means that you could be missing new features, or
				experience problems with this version due to the updates. Click the button to refresh to the latest
				version.
			</CardContent>
			<CardActions>
				<Button fullWidth color="secondary" variant="contained" onClick={() => refreshNoCache()}>
					Update Now
				</Button>
			</CardActions>
		</Card>
	) : null;
}
