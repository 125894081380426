import React from 'react';
import PropTypes from 'prop-types';
import UserController from '../../controllers/UserController';
import moment from 'moment';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import {
	Button,
	Grid,
	Container,
	Typography,
	ListItemAvatar,
	Avatar,
	ListItemText,
	ListItem,
	List,
	Card,
} from '@material-ui/core';
import { Alert } from '../../components/Common/Alert';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import MailIcon from '@material-ui/icons/Mail';

function Profile(props) {
	const [email, setEmail] = React.useState(null);
	const [warningText, setWarningText] = React.useState(null);
	const [emailConfirmed, setEmailConfirmed] = React.useState(false);
	const [joined, setJoined] = React.useState('');
	const [loading, setLoading] = React.useState(true);

	// initialise
	React.useEffect(() => {
		async function init() {
			if (!props.Auth.isAuthenticated) {
				props.PushHistory('/Login');
				return;
			}
			const response = await UserController.getUserInfo();
			if (!response.hasError) {
				const { email, emailConfirmed, joined } = response.data;
				setEmail(email);
				setEmailConfirmed(emailConfirmed);
				setJoined(joined);
			} else {
				setWarningText(response.data);
			}
			setLoading(false);
		}
		init();
	}, [props]);

	function buildListItem(icon, primary, secondary = null) {
		return (
			<ListItem dense>
				<ListItemAvatar>
					<Avatar>{icon}</Avatar>
				</ListItemAvatar>
				<ListItemText primary={primary} secondary={secondary} />
			</ListItem>
		);
	}

	return (
		<Container maxWidth="xs">
			<LoadingOverlay loading={loading} />

			<Typography variant="h2" align="center" gutterBottom style={{ marginTop: 40 }}>
				Profile
			</Typography>

			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Card>
						<List>
							{buildListItem(<MailIcon />, email, emailConfirmed ? 'Confirmed' : 'Not Confirmed')}
							{buildListItem(<AccessTimeIcon />, 'Date Joined', moment(joined).format('Do MMMM YYYY'))}
						</List>
					</Card>
				</Grid>
				<Grid item xs={12}>
					<Alert text={warningText} />
				</Grid>
				<Grid item xs={12}>
					<Button component={Link} to="/ChangePassword" variant="contained" color="primary" fullWidth>
						Change Password
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Button component={Link} to="/ChangeEmail" variant="contained" color="primary" fullWidth>
						Change Email
					</Button>
				</Grid>
			</Grid>
		</Container>
	);
}

const mapStateToProps = state => ({
	Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
	PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

Profile.propTypes = {
	Auth: PropTypes.object,
	PushHistory: PropTypes.func,
};
