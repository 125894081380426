import Axios from 'axios';
import UserController from './UserController';
import { APIPath, APIRoute } from '../helpers/Constants';
import { generateResponse } from '../helpers/Utils';

const CategoryController = {
	getCategories,
};

async function getCategories() {
	const userHeaders = await UserController.getUserHeaders();
	return Axios.get(APIRoute + APIPath.GET_CATEGORY, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

export default CategoryController;
