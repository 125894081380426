import Axios from 'axios';
import UserController from './UserController';
import { APIPath, APIRoute } from '../helpers/Constants';
import { generateResponse } from '../helpers/Utils';

const RoutineController = {
	getRoutines,
	addRoutine,
	editRoutine,
	deleteRoutine,
	addUpdate,
	editUpdate,
	removeUpdate,
};

async function getRoutines() {
	const userHeaders = await UserController.getUserHeaders();
	return Axios.get(APIRoute + APIPath.GET_ROUTINES, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function addRoutine(name, iconName, iconBGColour, amount, recurrence, starts, ends, accountName, categoryId) {
	const userHeaders = await UserController.getUserHeaders();
	const routineModel = {
		name,
		iconName,
		iconBGColour,
		amount,
		recurrence,
		starts,
		ends,
		accountName,
		categoryId,
	};
	return Axios.post(APIRoute + APIPath.ADD_ROUTINE, routineModel, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function editRoutine(
	id,
	name,
	iconName,
	iconBGColour,
	amount,
	recurrence,
	starts,
	ends,
	accountName,
	categoryId,
	removeExclusion = false
) {
	const userHeaders = await UserController.getUserHeaders();
	const routineModel = {
		id,
		removeExclusion,
		name,
		iconName,
		iconBGColour,
		amount,
		recurrence,
		starts,
		ends,
		accountName,
		categoryId,
	};
	return Axios.post(APIRoute + APIPath.EDIT_ROUTINE, routineModel, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function deleteRoutine(id) {
	const userHeaders = await UserController.getUserHeaders();
	return Axios.post(APIRoute + APIPath.DELETE_ROUTINE + id, null, userHeaders)
		.then(() => {
			return generateResponse(false, id);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function addUpdate(routineId, amount, date) {
	const userHeaders = await UserController.getUserHeaders();
	const updateModel = {
		routineId,
		amount,
		date,
	};
	return Axios.post(APIRoute + APIPath.ADD_ROUTINE_UPDATE, updateModel, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function editUpdate(id, routineId, amount, date) {
	const userHeaders = await UserController.getUserHeaders();
	const updateModel = {
		id,
		routineId,
		amount,
		date,
	};
	return Axios.post(APIRoute + APIPath.EDIT_ROUTINE_UPDATE, updateModel, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function removeUpdate(id) {
	const userHeaders = await UserController.getUserHeaders();
	return Axios.post(APIRoute + APIPath.REMOVE_ROUTINE_UPDATE + id, null, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

export default RoutineController;
