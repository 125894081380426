import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import currency from 'currency.js';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import RoutineController from '../../controllers/RoutineController';
import {
	makeStyles,
	Dialog,
	DialogTitle,
	Divider,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	Grid,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	InputAdornment,
	useTheme,
	useMediaQuery,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Accounts, RepeatIntervals, Category } from '../../helpers/Constants';
import { ItemIconField } from '../../components/Item/ItemIconField';
import { isNullOrUndefined, getCategoryColour } from '../../helpers/Utils';
import { Alert } from '../../components/Common/Alert';
import { ItemIcons } from '../../helpers/ItemIconLib';

const useStyles = makeStyles(theme => ({
	numberInput: {
		'& input': {
			MozAppearance: 'textfield',
		},
		'& input::-webkit-inner-spin-button, input::-webkit-outer-spin-button': {
			WebkitAppearance: 'none',
			margin: 0,
		},
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
	},
}));

export function RoutineAddEdit(props) {
	const { categories, routine, open, onClose } = props;
	const [name, setName] = React.useState('');
	const [category, setCategory] = React.useState('');
	const [iconName, setIconName] = React.useState('default');
	const [amount, setAmount] = React.useState('');
	const [recurrence, setRecurrence] = React.useState('');
	const [starts, setStarts] = React.useState(null);
	const [ends, setEnds] = React.useState(null);
	const [account, setAccount] = React.useState('');
	const [warningText, setWarningText] = React.useState(null);
	const [submitting, setSubmitting] = React.useState(false);
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('xs'));

	const categoryColour = () => getCategoryColour(categories?.find(e => e.id === category)?.name ?? Category.Basics);

	const isOneInTheYear = useMemo(() => recurrence === RepeatIntervals[0], [recurrence]);

	// update with new routine data
	React.useEffect(() => {
		setName(routine?.name ?? '');
		if (categories.length > 0) {
			setCategory(
				(routine?.categoryId === categories[0].id && routine?.accountName === 'Credit Card'
					? categories[2].id
					: routine?.categoryId) ?? ''
			);
		}
		setIconName(routine?.iconName ?? 'default');
		setAmount(routine?.amount ?? '');
		setRecurrence(routine?.recurrence ?? '');
		setStarts(routine?.starts ? moment(routine.starts) : null);
		setEnds(routine?.ends ? moment(routine.ends) : null);
		setAccount(routine?.accountName ?? '');
	}, [routine]);

	function closeAndClearData(newRoutine = null) {
		setName('');
		setCategory('');
		setIconName('default');
		setAmount('');
		setRecurrence('');
		setStarts(null);
		setEnds(null);
		setAccount('');
		setSubmitting(false);
		onClose(newRoutine);
	}

	async function handleSubmit(event) {
		event.preventDefault();
		setWarningText(null);
		setSubmitting(true);

		if (!moment.isMoment(starts) || !starts.isValid()) {
			setWarningText('Please specify a valid start date');
			setSubmitting(false);
			return;
		}

		const realAccount = account === 'Credit Card' && category === categories[2].id ? 'Bank' : account;
		const response = isNullOrUndefined(routine?.id)
			? await RoutineController.addRoutine(
					name,
					iconName,
					categoryColour(),
					currency(amount).toJSON(),
					recurrence,
					starts.format(),
					ends?.format(),
					realAccount,
					category
			  )
			: await RoutineController.editRoutine(
					routine.id,
					name,
					iconName,
					categoryColour(),
					currency(amount).toJSON(),
					recurrence,
					starts.format(),
					ends?.format(),
					realAccount,
					category
			  );
		if (response.hasError) {
			setWarningText(response.data);
			setSubmitting(false);
			return;
		}

		closeAndClearData(response.data);
	}

	function handleInput(event) {
		const { name, value } = event.target;
		switch (name) {
			case 'name':
				setName(value?.name ?? value);
				//handleUpdateAutofillName(value);
				break;
			case 'category':
				if (category !== value) {
					setIconName(ItemIcons.DEFAULT.name);
				}
				setCategory(value);
				break;
			case 'amount':
				setAmount(
					value.length > 0
						? currency(value.toString().replace(/-/g, ''), {
								symbol: '',
								precision: 0,
								separator: ',',
						  }).format()
						: value
				);
				break;
			case 'recurrence':
				setRecurrence(value);
				if (value === RepeatIntervals[0]) {
					setEnds(null);
				}
				break;
			case 'account':
				handleUpdateAccount(value);
				break;
			default:
				return;
		}
	}

	function handleUpdateAccount(value) {
		// if changing a credit card item to the credit card account, nullify the category as it is invalid
		const catId = categories.filter(a => a.id === category)[0]?.id;
		const acc = Accounts[1];
		if (value === acc && category === catId) {
			setCategory('');
		}
		setAccount(value);
	}

	function handleIconUpdate(name) {
		setIconName(name);
	}

	return (
		<Dialog
			PaperComponent="form"
			PaperProps={{ className: classes.paper }}
			onSubmit={handleSubmit}
			open={open}
			fullScreen={matches}
		>
			<DialogTitle>Add/Edit Item</DialogTitle>
			<Divider />
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							variant="filled"
							label="Item Name"
							name="name"
							value={name}
							className={classes.numberInput}
							onChange={handleInput}
							fullWidth
							required
						/>
					</Grid>
					<Grid item xs={12}>
						<ItemIconField
							category={categories.filter(e => e.id === category)[0]?.name ?? ''}
							iconName={iconName ?? 'default'}
							iconBGColour={categoryColour()}
							itemName={name ?? ''}
							itemValue={currency(amount, { symbol: '£', precision: 0, separator: ',' }).format()}
							onChange={handleIconUpdate}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							variant="filled"
							label="Value"
							value={amount}
							className={classes.numberInput}
							name="amount"
							onChange={handleInput}
							fullWidth
							InputProps={{
								startAdornment: <InputAdornment position="start">£</InputAdornment>,
							}}
							required
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControl variant="filled" fullWidth required>
							<InputLabel id="recurrence-label">Occurs</InputLabel>
							<Select
								fullWidth
								labelId="recurrence-label"
								value={recurrence}
								name="recurrence"
								onChange={handleInput}
							>
								{RepeatIntervals.map(interval => (
									<MenuItem key={interval} value={interval}>
										{interval}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item sm={isOneInTheYear ? 12 : 6} xs={12}>
						<MuiPickersUtilsProvider utils={MomentUtils}>
							<KeyboardDatePicker
								fullWidth
								margin="none"
								label="Start Date"
								format="DD/MM/YY"
								inputVariant="filled"
								value={starts}
								onChange={e => setStarts(e)}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								InputProps={{
									required: true,
								}}
								InputLabelProps={{
									required: true,
								}}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
					{isOneInTheYear ? null : (
						<Grid item sm={6} xs={12}>
							<MuiPickersUtilsProvider utils={MomentUtils}>
								<KeyboardDatePicker
									fullWidth
									margin="none"
									label="End Date"
									format="DD/MM/YY"
									inputVariant="filled"
									value={ends}
									onChange={e => setEnds(e)}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
					)}
					<Grid item xs={12}>
						<Alert header="Something went wrong!" text={warningText} />
					</Grid>
				</Grid>
			</DialogContent>
			<Divider />
			<DialogActions>
				<Button color="primary" onClick={() => closeAndClearData()}>
					Cancel
				</Button>
				<Button color="primary" type="submit" disabled={submitting}>{`${
					submitting ? 'Saving' : 'Save'
				}`}</Button>
			</DialogActions>
		</Dialog>
	);
}

RoutineAddEdit.propTypes = {
	categories: PropTypes.array.isRequired,
	routine: PropTypes.object,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};
