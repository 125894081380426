import React from 'react';
import PropTypes from 'prop-types';
import TorchbearerLogo from '../../images/tb-logo.png';
import FlipsideLogo from '../../images/logo.svg';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
	makeStyles,
	useTheme,
	Button,
	Toolbar,
	MenuItem,
	Menu,
	ListItemText,
	ListItem,
	List,
	IconButton,
	Drawer,
	CssBaseline,
	Avatar,
	AppBar,
	Divider,
	Tabs,
	Tab,
	ListItemIcon,
} from '@material-ui/core';
import { AddNotif, AddNotifs, MarkAsRead, MarkAllAsRead } from '../../stores/Actions/Notifications';
import { isNullOrUndefined } from '../../helpers/Utils';
import MenuIcon from '@material-ui/icons/Menu';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import { Dashboard, Home } from '@material-ui/icons';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		overflowY: 'auto',
		maxHeight: '100vh',
	},
	drawer: {
		flexShrink: 0,
	},
	appBar: {
		height: 48,
		[theme.breakpoints.up('sm')]: {
			width: `100%`,
		},
		'&.hidden-nav': {
			display: 'none',
		},
	},
	menuButton: {
		marginLeft: theme.spacing(1),
		display: 'none',
		[theme.breakpoints.down('xs')]: {
			display: 'unset',
		},
	},
	toolbar: {
		height: 48,
		minHeight: 'unset',
		padding: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		[theme.breakpoints.down('xs')]: {
			marginTop: '0',
		},
	},
	content: {
		flexGrow: 1,
		marginTop: ({ isAuthenticated }) => (isAuthenticated ? 48 : 0),
		minHeight: ({ isAuthenticated }) => `calc(100vh - ${isAuthenticated ? 48 : 0}px)`,
		overflowX: 'hidden',
		'&.hidden-nav': {
			paddingTop: 0 + ' !important',
		},
	},
	avatarButton: {
		position: 'absolute',
		marginRight: 24,
		marginTop: 4,
		minWidth: 'unset',
		borderRadius: 64,
		padding: 0,
		right: 0,
		top: 0,
	},
	notifButton: {
		position: 'absolute',
		right: 76,
		top: 9,
		[theme.breakpoints.down('xs')]: {
			top: 7,
		},
	},
	notifWrapper: {
		maxHeight: 500,
		overflowY: 'auto',
	},
	notif: {
		position: 'relative',
		padding: '12px 16px',
		width: 260,
	},
	notifViewAll: {
		position: 'absolute',
		top: 14,
		right: 16,
	},
	tab: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	hidden: {
		display: 'none',
	},
	navLogo: {
		height: '80%',
		padding: 6,
		borderRadius: 4,
		backgroundColor: '#fff',
		'& img': {
			height: '100%',
		},
	},
	fsLogo: {
		marginRight: 4,
		padding: 8,
	},
}));

function NavMenu(props) {
	const { PushHistory, container } = props;
	const { userName, isAuthenticated } = props.LogIn;
	const classes = useStyles({ isAuthenticated });
	const theme = useTheme();
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [userMenuOpen, setUserMenuOpen] = React.useState(false);
	const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
	const [redirectUrl, setRedirectUrl] = React.useState(null);
	const [, updateState] = React.useState();
	const location = useLocation();

	// force refresh callback
	const forceUpdate = React.useCallback(() => updateState({}), []);

	// redirect
	React.useEffect(() => {
		if (!isNullOrUndefined(redirectUrl)) {
			PushHistory(redirectUrl);
			setRedirectUrl(null);
		}
	}, [redirectUrl, PushHistory]);

	// on location updated
	React.useEffect(() => {
		forceUpdate();
	}, [location, forceUpdate]);

	function getHiddenNavClass() {
		if (!isAuthenticated || location.pathname === '/MoneyPlan') {
			return 'hidden-nav';
		}
		return '';
	}

	function getHideNavbarContent() {
		return getPageNum() == 0;
	}

	function getPageNum() {
		const path = location.pathname.toLowerCase();
		if (path.includes('routine')) {
			return 1;
		} else if (path.includes('variable')) {
			return 2;
		} else if (path.includes('visualise')) {
			return 3;
		}
		return 0;
	}

	function handleDrawerToggle() {
		setMobileOpen(!mobileOpen);
		handleUserMenuClose();
	}

	function handleUserMenuToggle(event) {
		setUserMenuOpen(!userMenuOpen);
		setProfileAnchorEl(event.currentTarget);
	}

	function handleUserMenuClose() {
		setUserMenuOpen(false);
		setProfileAnchorEl(null);
	}

	function buildDrawerOption(name, route, icon, key = null) {
		return (
			<ListItem button component={Link} to={route} key={key ?? name} onClick={handleDrawerToggle}>
				<ListItemIcon>{icon}</ListItemIcon>
				<ListItemText primary={name} />
			</ListItem>
		);
	}

	function buildDrawerOptions() {
		const drawerOptions = [buildDrawerOption('Home', '/', <HomeOutlinedIcon />)];
		if (userName) {
			drawerOptions.push(<Divider key="div-1" />);
			drawerOptions.push(buildDrawerOption('Budgeting App', '/MoneyPlan', <Dashboard />));
			drawerOptions.push(buildDrawerOption('Cash Forecast', '/CashForcast', <AssessmentOutlinedIcon />));
		} else {
			drawerOptions.push(<Divider key="div-2" />);
			drawerOptions.push(buildDrawerOption('Login', '/Login', <VpnKeyOutlinedIcon />));
			drawerOptions.push(buildDrawerOption('Register', '/Register', <AssignmentIndOutlinedIcon />));
		}
		return <List>{drawerOptions}</List>;
	}

	function buildProfileOptions() {
		return userName ? (
			<>
				<Button
					className={classes.avatarButton}
					onClick={handleUserMenuToggle}
					aria-controls="simple-menu"
					aria-haspopup="true"
				>
					<Avatar>{userName.charAt(0).toUpperCase()}</Avatar>
				</Button>
				<Menu
					id="user-menu"
					anchorEl={profileAnchorEl}
					keepMounted
					open={Boolean(userMenuOpen)}
					onClose={handleUserMenuClose}
				>
					<MenuItem onClick={handleUserMenuClose} component={Link} to="/UserManagement" key="userManagement">
						Profile
					</MenuItem>
					<MenuItem onClick={handleUserMenuClose} component={Link} to="/Logout" key="logout">
						Logout
					</MenuItem>
				</Menu>
			</>
		) : null;
	}

	function buildNavMenu() {
		return (
			<>
				<AppBar position="fixed" className={`${classes.appBar} ${getHiddenNavClass()}`} elevation={1}>
					<Toolbar className={classes.toolbar}>
						<IconButton
							color="inherit"
							aria-label="Open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							className={classes.menuButton}
						>
							<MenuIcon />
						</IconButton>
						{!getHideNavbarContent() ? (
							<>
								<IconButton className={classes.tab} component={Link} to="/Guide" color="inherit">
									<HelpOutlineOutlinedIcon />
								</IconButton>

								<Tabs value={getPageNum()}>
									<Tab label="0. Guide" className={classes.hidden}></Tab>
									<Tab
										label="1. Routine"
										className={classes.tab}
										onClick={() => PushHistory('/Routine')}
									/>
									<Tab
										label="2. Variable"
										className={classes.tab}
										onClick={() => PushHistory('/Variable')}
									/>
									<Tab
										label="3. Visualise"
										className={classes.tab}
										onClick={() => PushHistory('/Visualise')}
									/>
								</Tabs>
							</>
						) : (
							<>
								<IconButton className={classes.tab} component={Link} to="/" color="inherit">
									<Home />
								</IconButton>
								<a
									className={`${classes.navLogo} ${classes.fsLogo}`}
									href="https://flipside.uk.com"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={FlipsideLogo} />
								</a>
								<a
									className={classes.navLogo}
									href="https://torchbearer.dev"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={TorchbearerLogo} />
								</a>
							</>
						)}
						{/* {buildNotificationTray()} */}
						{buildProfileOptions()}
					</Toolbar>
				</AppBar>
				<nav className={classes.drawer} aria-label="Mailbox folders">
					<Drawer
						container={container}
						variant="temporary"
						anchor={theme.direction === 'rtl' ? 'right' : 'left'}
						open={mobileOpen}
						onClose={handleDrawerToggle}
						classes={{ paper: classes.drawerPaper }}
						ModalProps={{ keepMounted: true }}
					>
						{buildDrawerOptions()}
					</Drawer>
				</nav>
			</>
		);
	}

	return (
		<div className={classes.root}>
			<CssBaseline />
			{buildNavMenu()}
			<main id="main-wrapper" className={`${classes.content} ${getHiddenNavClass()}`}>
				{props.children}
			</main>
		</div>
	);
}

const mapStateToProps = state => ({
	LogIn: state.Authentication,
	Notifications: state.Notifications,
});

const mapDispatchToProps = dispatch => ({
	PushHistory: data => dispatch(push(data)),
	AddNotif: item => dispatch(AddNotif(item)),
	AddNotifs: items => dispatch(AddNotifs(items)),
	MarkAsRead: id => dispatch(MarkAsRead(id)),
	MarkAllAsRead: () => dispatch(MarkAllAsRead()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);

NavMenu.propTypes = {
	container: PropTypes.object,
	children: PropTypes.any,
	LogIn: PropTypes.object,
	PushHistory: PropTypes.func,
	AddNotif: PropTypes.func,
	AddNotifs: PropTypes.func,
	MarkAsRead: PropTypes.func,
	MarkAllAsRead: PropTypes.func,
	Notifications: PropTypes.array,
};
