import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Colours } from '../../helpers/Colours';

const useStyles = makeStyles(theme => ({
	root: props => ({
		position: 'relative',
		display: 'flex',
		width: '100%',
		border: `4px solid ${props.category.borderColor}`,
		backgroundColor: Colours.white,
		marginBottom: theme.spacing(2),
		padding: '46px 12px',
		alignItems: 'center',
		justifyContent: 'center',
		flexWrap: 'wrap',
		'&:last-child': {
			marginBottom: 0,
		},
	}),
	header: props => ({
		position: 'absolute',
		top: 0,
		left: 0,
		backgroundColor: props.category.color,
		minWidth: 120,
		padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
		textAlign: 'center',
		borderBottomRightRadius: 12,
		fontWeight: 500,
		whiteSpace: 'nowrap',
		[theme.breakpoints.down('xs')]: {
			minWidth: '100%',
			borderRadius: 0,
			padding: `${theme.spacing(1)}px 0px`,
		},
	}),
}));

export default function MoneyPlanItemContainer(props) {
	const { category, children, total } = props;
	const classes = useStyles(props);

	return (
		<div className={classes.root}>
			<div className={classes.header}>{category.name}</div>
			{children}
		</div>
	);
}

MoneyPlanItemContainer.propTypes = {
	category: PropTypes.object.isRequired,
	children: PropTypes.any,
	total: PropTypes.number.isRequired,
};
