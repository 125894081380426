import { Category, CategoryColours } from './Constants';
import TokenRefresher from './TokenRefresher';

export function isNullOrUndefined(obj) {
	return obj === null || obj === undefined;
}

export function isNullOrEmpty(obj) {
	return obj === null || obj === undefined || obj === '';
}

export function isNullOrWhitespace(string) {
	return isNullOrUndefined(string) || string.trim().length === 0;
}

export function isStatusOK(status = 0) {
	return status >= 200 && status < 300;
}

export function generateResponse(hasError = false, data, response = null) {
	if (!isNullOrUndefined(response)) {
		TokenRefresher.checkAndRefeshToken(response);
	}
	return { hasError, data };
}

export function checkPassword(passwordRequirements, password) {
	if (isNullOrUndefined(passwordRequirements) || isNullOrUndefined(password)) {
		return;
	}
	const validChars = Array.from('ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789!@$?_-');
	const currentChars = Array.from(password);
	currentChars.forEach(element => {
		if (!validChars.includes(element)) {
			return 'Invalid character detected';
		}
	});
	if (currentChars.length < passwordRequirements.requiredLength) {
		return 'Password must be at least ' + passwordRequirements.requiredLength + ' characters long';
	}
	if (passwordRequirements.requireDigit === true) {
		if (!/\d/.test(password)) {
			return 'Password must contain one number';
		}
	}
	if (passwordRequirements.requireLowercase === true) {
		if (!/[a-z]/.test(password)) {
			return 'Password must contain one lowercase character';
		}
	}
	if (passwordRequirements.requireUppercase === true) {
		if (!/[A-Z]/.test(password)) {
			return 'Password must contain one uppercase character';
		}
	}
	if (passwordRequirements.requireNonAlphanumeric === true) {
		if (!/[!@$?_-]/.test(password)) {
			return 'Password must contain one special character (!@$?_-)';
		}
	}
	return null;
}

export function returnNumberWithCommas(x) {
	const parsed = parseInt(x);
	if (!isNaN(parsed)) {
		return parsed.toLocaleString();
	} else {
		return '';
	}
}

export function getCategoryColour(categoryName) {
	const cats = Object.values(Category).indexOf(categoryName);
	return CategoryColours[cats];
}

export function getRenamedLoanCategory(category, isLoan = false) {
	switch (category) {
		case 'Credit Card Bill':
			return 'Loan Repayments';
		case 'Choices':
			return isLoan ? 'Interest Payable' : 'Discretionary Payments';
		case 'Basics':
			return isLoan ? 'Additional Loans' : 'Obligatory Payments';
		case 'Income':
			return 'Bank Receipts';
		default:
			return '';
	}
}
export function getRenamedCategory(category) {
	switch (category) {
		case 'Credit Card Bill':
			return 'Loan Repayments';
		case 'Choices':
			return 'Discretionary Payments';
		case 'Basics':
			return 'Obligatory Payments';
		case 'Income':
			return 'Bank Receipts';
		default:
			return '';
	}
}

export function getIconName(itemName) {
	return itemName.replace(/\s/g, '').slice(0, 3);
}

export function getIconFontSize(itemName) {
	const nameLength = itemName?.length;

	switch (nameLength) {
		case 1:
			return 22;
		case 2:
			return 18;
		case 3:
			return 14;
		default:
			return 14;
	}
}
