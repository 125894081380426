import React from 'react';
import NavMenu from './NavMenu.js';
import PropTypes from 'prop-types';
import MOTD from './MOTD.js';
import { CheckVersion } from './CheckVersion';
import CheckToken from './CheckToken';

export function Layout(props) {
	return (
		<NavMenu>
			<MOTD />
			<CheckVersion />
			<CheckToken />
			{props.children}
		</NavMenu>
	);
}

Layout.propTypes = {
	children: PropTypes.object,
};
