import React from 'react';
import moment from 'moment';
import currency from 'currency.js';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import VariableController from '../../controllers/VariableController';
import CategoryController from '../../controllers/CategoryController';
import RoutineController from '../../controllers/RoutineController';
import ForecastController from '../../controllers/ForecastController';
import AddIcon from '@material-ui/icons/Add';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import {
	makeStyles,
	Grid,
	TextField,
	InputAdornment,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
	Typography,
	Fab,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Card,
	Box,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Accounts, Category, ItemModifierType, ProjectionLengths, RepeatIntervals } from '../../helpers/Constants';
import { Alert } from '../../components/Common/Alert';
import { Colours } from '../../helpers/Colours';
import { getCategoryColour, getRenamedLoanCategory, isNullOrUndefined, isNullOrWhitespace } from '../../helpers/Utils';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { ItemIcon } from '../../components/Item/ItemIcon';
import { TimelineAddRemove } from './TimelineAddRemove';
import DeferRender from '../../components/Timeline/DeferRender';

const useStyles = makeStyles(theme => ({
	root: {
		minHeight: '100%',
		width: '100%',
	},
	configBtn: {
		display: 'none',
		padding: 8,
		backgroundColor: Colours.bg_grey_1,
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
		'& button': {
			position: 'relative',
			height: 56,
		},
	},
	configArea: {
		width: '100%',
		padding: 16,
		position: 'relative',
		overflow: 'hidden',
		WebkitTransition: 'all 0.2s ease',
		MozTransition: 'all 0.2s ease',
		OTransition: 'all 0.2s ease',
		transition: 'all 0.2s ease',
		[theme.breakpoints.down('xl')]: {
			padding: 8,
		},
		[theme.breakpoints.down('md')]: {
			// padding: 0,

			'&.collapsed': {
				height: 0,
				padding: 0,
			},
		},
	},
	refresh: {
		[theme.breakpoints.up('lg')]: {
			display: 'inline-block',
			position: 'absolute',
			right: 8,
		},
		'& button': {
			position: 'relative',
			height: 56,
		},
	},
	hideAtMd: {
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	timeline: {
		width: '100%',
		minHeight: 'calc(100vh - 120px)',
		overflowX: 'scroll',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'stretch',
		[theme.breakpoints.down('xs')]: {
			minHeight: 'calc(100vh - 192px)',
		},
	},
	columnWrapper: {
		display: 'flex',
		'& .day-column': {
			minWidth: 90,
			width: 'max-content',
			position: 'relative',
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'stretch',
			flexDirection: 'column',
			'& .col-value': {
				display: 'flex',
				justifyContent: 'center',
				borderTop: `1px solid ${Colours.primary25}`,
				borderRight: '1px solid ' + Colours.primary25,
				borderLeft: '1px solid ' + Colours.primary25,
				padding: '4px',
			},
			'& .date': {
				position: 'relative',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				color: Colours.white,
				backgroundColor: Colours.primary,
				height: 46,
				'@media (max-width: 1366px)': {
					fontSize: 12,
				},
			},
			'& .graph': {
				flexGrow: 1.5,
				backgroundColor: Colours.bg_grey_1,
				position: 'relative',
				padding: '0px 10px',
				'&.no-pad': {
					padding: 0,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				},
				'& .positive-wrapper': {
					display: 'flex',
					justifyContent: 'stretch',
					alignItems: 'flex-end',
					width: 'calc(100% - 20px)',
					backgroundColor: Colours.bg_grey_3,
					position: 'absolute',
					maxHeight: '100%',
				},
				'& .negative-wrapper': {
					width: 'calc(100% - 20px)',
					backgroundColor: Colours.bg_grey_3,
					position: 'absolute',
					bottom: 0,
					maxHeight: '100%',
				},
				'& .positive': {
					background: Colours.positive,
					width: '100%',
					opacity: 0.5,
					'&.credit': {
						background: Colours.negative,
					},
				},
				'& .negative': {
					background: Colours.negative,
					width: '100%',
					opacity: 0.5,
					'&.credit': {
						background: Colours.positive,
					},
				},
			},
			'& .item-row': {
				flexGrow: 0.25,
				position: 'relative',
				display: 'flex',
				flexWrap: 'wrap',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				height: 90,
				maxHeight: 90,
				padding: 4,
				borderLeft: `1px solid ${Colours.primary25}`,
				borderRight: `1px solid ${Colours.primary25}`,
				WebkitTransition: 'all 0.2s ease',
				MozTransition: 'all 0.2s ease',
				OTransition: 'all 0.2s ease',
				transition: 'all 0.2s ease',
				'& .add-instance': {
					color: Colours.white,
					backgroundColor: Colours.bg_grey_1,
					boxShadow: 'none',
					height: 40,
					width: 40,
					cursor: 'pointer',
				},
				'&:nth-child(odd)': {
					backgroundColor: Colours.bg_grey_1,
					'& .add-instance': {
						color: Colours.bg_grey_1,
						backgroundColor: Colours.white,
					},
				},
				'& .subtitle-wrapper': {
					position: 'absolute',
					top: 0,
					right: 0,
					left: 0,
					bottom: 0,
					pointerEvents: 'none',
				},
				'& .subtitle': {
					position: 'fixed',
					color: Colours.bodyText,
					fontSize: 11,
					zIndex: 1,
					padding: 2,
					paddingTop: 1,
				},
				'&.interactive': {
					cursor: 'pointer',
					'&:hover': {
						backgroundColor: Colours.primary25 + '!important',
						'& .add-instance': {
							backgroundColor: Colours.secondary + '!important',
							color: Colours.white + '!important',
						},
					},
				},
			},
		},
		'&:nth-child(odd) .day-column .date': {
			backgroundColor: Colours.timelineAlt,
		},
	},
	noConfig: {
		color: Colours.bodyText,
		textTransform: 'uppercase',
		padding: '60px 30px',
	},
	smallInput: {
		minWidth: 166,
	},
	balanceInput: {
		minWidth: 200,
		maxWidth: 260,
		'& input': {
			backgroundColor: 'yellow',
			borderTopLeftRadius: 4,
			borderTopRightRadius: 4,
			color: 'black',
		},
	},
	accountInput: props => ({
		'& .MuiSelect-root': {
			backgroundColor:
				(props.account !== 'Loans' ? Colours.positiveTrans : Colours.negativeTrans) + ' !important',
			borderTopLeftRadius: 4,
			borderTopRightRadius: 4,
		},
	}),
	deleteFab: {
		position: 'fixed',
		bottom: 130,
		left: 30,
		[theme.breakpoints.down('md')]: {
			bottom: 30,
		},
	},
}));

function Timeline(props) {
	const { Auth, PushHistory } = props;
	const [starts, setStarts] = React.useState(null);
	const [length, setLength] = React.useState(ProjectionLengths[6]);
	const [balance, setBalance] = React.useState('');
	const [credit, setCredit] = React.useState('');
	const [account, setAccount] = React.useState(Accounts[0]);
	const [categories, setCategories] = React.useState([]);
	const [routines, setRoutines] = React.useState([]);
	const [contextRoutines, setContextRoutines] = React.useState([]);
	const [variables, setVariables] = React.useState([]);
	const [showConfig, setShowConfig] = React.useState(false);
	const [addDate, setAddDate] = React.useState(null);
	const [addCategory, setCategory] = React.useState(null);
	const [warningText, setWarningText] = React.useState('');
	const [redirectUrl, setRedirectUrl] = React.useState(null);
	const [highestVal, setHighestVal] = React.useState(0);
	const [lowestVal, setLowestVal] = React.useState(0);
	const [highestDay, setHighestDay] = React.useState(null);
	const [lowestDay, setLowestDay] = React.useState(null);
	const [loading, setLoading] = React.useState(true);
	const [addingRemoving, setAddingRemoving] = React.useState(false);
	const [refreshShowing, setRefreshShowing] = React.useState(false);
	const classes = useStyles({ account });

	const saveVariables = React.useCallback((variables = []) => {
		setVariables(
			variables.sort((a, b) => {
				return a?.categoryId - b?.categoryId || a?.name.localeCompare(b?.name, 'en', { sensitivity: 'base' });
			})
		);
	}, []);

	const saveRoutines = React.useCallback((routines = []) => {
		const newRoutines = routines.sort((a, b) => {
			return a?.categoryId - b?.categoryId || a?.name.localeCompare(b?.name, 'en', { sensitivity: 'base' });
		});
		setRoutines(newRoutines);
		setContextRoutines(newRoutines);
	}, []);

	const validRoutines = React.useMemo(() => routines.filter(e => e.categoryId !== '002'), [routines]);

	const fetchForecast = React.useCallback(async () => {
		setWarningText(null);
		const response = await ForecastController.getForecast();
		if (!response.hasError) {
			const bankStart = response.data?.bankStart?.toString() ?? '';
			const credit = response.data?.creditStart?.toString() ?? '';
			setBalance(
				bankStart.length > 0
					? currency(bankStart.toString().replace(/-/g, ''), {
							symbol: '',
							precision: 0,
							separator: ',',
					  }).format()
					: ''
			);
			setCredit(
				credit.length > 0
					? currency(credit.toString().replace(/-/g, ''), {
							symbol: '',
							precision: 0,
							separator: ',',
					  }).format()
					: ''
			);
			setStarts(response.data?.startDate ? moment(response.data?.startDate) : null);
		} else {
			setWarningText(response.data);
		}
	}, []);

	const fetchRoutines = React.useCallback(async () => {
		setWarningText(null);
		const response = await RoutineController.getRoutines();
		if (!response.hasError) {
			setRoutines(
				response.data.map(e => ({
					...e,
					starts: moment.utc(e.starts).local().format(),
					ends: e.ends ? moment.utc(e.ends).local().format() : null,
				}))
			);
		} else {
			setWarningText(response.data);
		}
	}, []);

	const fetchVariables = React.useCallback(async () => {
		setWarningText(null);
		const response = await VariableController.getVariables();
		if (!response.hasError) {
			setVariables(response.data);
		} else {
			setWarningText(response.data);
		}
	}, []);

	const fetchCategories = React.useCallback(async () => {
		setWarningText(null);
		const response = await CategoryController.getCategories();
		if (!response.hasError) {
			setCategories(response.data);
		} else {
			setWarningText(response.data);
		}
	}, []);

	// Get Y Scroll
	React.useEffect(() => {
		const onScroll = () => {
			const yScroll = document.getElementById('main-wrapper')?.scrollTop ?? 0;
			const subtitles = document.getElementsByClassName('subtitle');
			for (let i = 0; i < subtitles.length; i++) {
				subtitles[i].style.marginTop = `${-yScroll}px`;
			}
		};
		document.getElementById('main-wrapper').addEventListener('scroll', onScroll);
		return () => document.getElementById('main-wrapper').removeEventListener('scoll', onScroll);
	});

	// redirect
	React.useEffect(() => {
		if (!isNullOrUndefined(redirectUrl)) {
			PushHistory(redirectUrl);
		}
	}, [redirectUrl, PushHistory]);

	// init
	React.useEffect(() => {
		async function init() {
			if (!Auth.isAuthenticated) {
				setRedirectUrl('/');
				return;
			}
			await fetchForecast();
			await fetchCategories();
			await fetchRoutines();
			await fetchVariables();
			setLoading(false);
		}
		init();
	}, [Auth, fetchForecast, fetchCategories, fetchVariables, fetchRoutines]);

	function getCategories() {
		return categories.filter(e => e.name !== Category.CreditCardBill);
	}

	function getCategoryItems(categoryId, items) {
		if (account === Accounts[0]) {
			// Bank
			return items.filter(e => e.categoryId === categoryId && account === e.accountName);
		} else {
			// Credit Card
			if (categoryId === categories[2].id) {
				return items.filter(e => e.categoryId === categories[2].id);
			}
			return items.filter(e => e.categoryId === categoryId && account === e.accountName);
		}
	}

	function getRoutineRealAmount(routine, date = moment()) {
		let realAmount = routine.amount;
		const updates = (routine.updates ?? [])
			.filter(e => moment.utc(e.date).local().startOf('day').isSameOrBefore(date.startOf('day')))
			.sort(
				(a, b) =>
					moment.utc(b.date).local().startOf('day').valueOf() -
					moment.utc(a.date).local().startOf('day').valueOf()
			);
		if (updates.length > 0) {
			realAmount = updates[0].amount;
		}
		return realAmount;
	}

	function getBalanceChange(items, date, isRoutine = false) {
		let value = 0;
		items.forEach(e => {
			const realAmount = isRoutine ? getRoutineRealAmount(e, date) : e.amount;
			if (e.accountName === Accounts[0] && account === Accounts[0]) {
				// Bank
				if (e.categoryId === categories[0].id) {
					// Money In
					value += realAmount;
				} else {
					// Spending
					value -= realAmount;
				}
			} else if (e.accountName === Accounts[1] && account === Accounts[1]) {
				// Credit Card
				value += realAmount;
			}

			// Special case for money in from bank to credit
			if (account === Accounts[1] && e.categoryId === categories[2].id && e.accountName === Accounts[0]) {
				value -= realAmount;
			}
		});
		return value;
	}

	function getConfigComplete(startsOverride = null) {
		if (!moment.isMoment(startsOverride ?? starts) || !(startsOverride ?? starts).isValid()) {
			return false;
		} else if (isNullOrWhitespace(balance)) {
			return false;
		} else if (isNullOrWhitespace(credit)) {
			return false;
		}
		return true;
	}

	function getTotalDays() {
		if (!getConfigComplete()) {
			return 0;
		}
		const endDate = moment(starts).add(length.days, 'days').add(length.months, 'months').add(length.years, 'years');
		return endDate.diff(starts, 'days');
	}

	function getIntervalDuration(interval) {
		switch (interval) {
			case RepeatIntervals[0]: // Once in the Year
				return { days: 0, months: 0, years: 1 };
			case RepeatIntervals[1]: // Weekly
				return { days: 7, months: 0, years: 0 };
			case RepeatIntervals[2]: // Two Weeks
				return { days: 14, months: 0, years: 0 };
			case RepeatIntervals[3]: // Four Weeks
				return { days: 28, months: 0, years: 0 };
			case RepeatIntervals[4]: // Monthly
				return { days: 0, months: 1, years: 0 };
			case RepeatIntervals[5]: // Three Months
				return { days: 0, months: 3, years: 0 };
			case RepeatIntervals[6]: // Six Months
				return { days: 0, months: 6, years: 0 };
			case RepeatIntervals[7]: // One Year
				return { days: 0, months: 0, years: 1 };
			default:
				return null;
		}
	}

	function getRoutineDays(startDate = moment(), endDate = moment(), interval) {
		const dateArray = [];
		const intervalDuration = getIntervalDuration(interval);
		if (isNullOrUndefined(intervalDuration)) {
			return dateArray;
		}
		const add = (date = moment(), interval, multiplier) => {
			return date
				.add(interval.days * multiplier, 'days')
				.add(interval.months * multiplier, 'months')
				.add(interval.years * multiplier, 'years');
		};
		let currentDate = add(moment(startDate), intervalDuration);
		let counter = 1;

		while (currentDate.isSameOrBefore(endDate)) {
			if (
				!startDate.startOf('day').isSame(currentDate.startOf('day')) &&
				currentDate.isSameOrAfter(moment(starts).startOf('day'))
			) {
				if (interval === RepeatIntervals[0] && moment(starts).isAfter(moment(startDate).startOf('day'))) break;

				dateArray.push(currentDate);
			}
			currentDate = add(moment(startDate), intervalDuration, counter);
			counter++;
		}
		return [startDate, ...dateArray];
	}

	async function handleSaveForecast(startsOverride = null) {
		if (!getConfigComplete(startsOverride)) {
			return;
		}
		const response = await ForecastController.setForecast(
			startsOverride ?? starts,
			currency(balance).value,
			currency(credit).value
		);
		if (response.hasError) {
			setWarningText(response.data);
		}
	}

	async function handleAddModifier(type, data) {
		if (addingRemoving) {
			return;
		}
		setAddingRemoving(true);
		if (type === ItemModifierType.Instance) {
			const { variable, date } = data;
			const response = await VariableController.addInstance(
				variable?.id,
				account,
				date.format(),
				variable.amount
			);
			if (response.hasError) {
				setWarningText(response.data);
				setAddingRemoving(false);
				return null;
			} else {
				const newVars = variables.find(v => v?.id === variable?.id)
					? variables.map(e => {
							if (e.id === variable.id) {
								e.occasionInstances = [response.data, ...e.occasionInstances];
								return e;
							} else {
								return e;
							}
					  })
					: [...variables, { ...variable, occasionInstances: [response.data] }];
				setVariables(newVars);
			}
		} else if (type === ItemModifierType.Update) {
			const { routine, amount, date } = data;
			const response = await RoutineController.addUpdate(routine.id, amount, date.format());
			if (response.hasError) {
				setWarningText(response.data);
				setAddingRemoving(false);
				return null;
			}
			const newRoutines = validRoutines.map(e => {
				if (e.id === routine.id) {
					e.updates = [response.data, ...(e.updates ?? [])];
					return e;
				} else {
					return e;
				}
			});
			setRoutines(newRoutines);
		}
		setAddingRemoving(false);
		return;
	}

	async function handleRemoveModifier(type, data) {
		if (addingRemoving || isNullOrUndefined(type) || isNullOrUndefined(data)) {
			return;
		} else if (
			(isNullOrUndefined(data.variableId) || isNullOrUndefined(data.instanceId)) &&
			isNullOrUndefined(data.id)
		) {
			return;
		}
		setAddingRemoving(true);
		if (type === ItemModifierType.Instance) {
			const { variableId, instanceId } = data;
			const response = await VariableController.removeInstance(instanceId);
			if (response.hasError) {
				setWarningText(response.data);
				setAddingRemoving(false);
				return false;
			}
			const newVars = variables.map(e => {
				if (e.id === variableId) {
					e.occasionInstances = e.occasionInstances.filter(a => a.id !== instanceId);
					return e;
				} else {
					return e;
				}
			});
			setVariables(newVars);
		} else if (type === ItemModifierType.Update) {
			const { id } = data;
			const response = await RoutineController.removeUpdate(id);
			if (response.hasError) {
				setWarningText(response.data);
				setAddingRemoving(false);
				return null;
			}
			const newRoutines = validRoutines.map(e => {
				e.updates = e.updates.filter(a => a.id !== id);
				return e;
			});
			setRoutines(newRoutines);
		}
		setAddingRemoving(false);
		return true;
	}

	async function handleEditModifier(type, data) {
		setLoading(true);
		if (type === ItemModifierType.Instance) {
			const { instance, variableId } = data;
			const response = await VariableController.editInstance(
				instance.id,
				variableId,
				instance.accountName,
				instance.on,
				Number(instance.amount)
			);
			if (response.hasError) {
				setWarningText(response.data);
			} else {
				const newVars = variables.map(e => {
					if (e.id === variableId) {
						e.occasionInstances = e.occasionInstances.map(i =>
							i.id === response.data.id ? response.data : i
						);
						return e;
					} else {
						return e;
					}
				});
				setVariables(newVars);
			}
		} else if (type === ItemModifierType.Update) {
			const { update, routineId } = data;
			const response = await RoutineController.editUpdate(
				update.id,
				routineId,
				Number(update.amount),
				update.date
			);
			if (response.hasError) {
				setWarningText(response.data);
			} else {
				const newRoutines = validRoutines.map(e => {
					if (e.id === routineId) {
						e.updates = e.updates.map(i => (i.id === response.data.id ? response.data : i));
						return e;
					} else {
						return e;
					}
				});
				setRoutines(newRoutines);
			}
		}
		setLoading(false);
	}

	function handleAddEditVariable(variable) {
		if (variables.filter(e => e.id === variable.id).length > 0) {
			// replace an existing variable
			saveVariables(variables.map(e => (e.id === variable.id ? variable : e)));
		} else {
			// add new variable to array
			saveVariables([variable, ...variables]);
		}
	}

	async function handleDeleteVariable(variable) {
		setLoading(true);
		setWarningText(null);

		const response = await VariableController.deleteVariable(variable?.id);
		if (response.hasError) {
			setWarningText(response.data);
		} else {
			saveVariables(variables.filter(e => e.id !== variable.id));
		}

		setLoading(false);
	}

	function handleAddEditRoutine(routine) {
		if (validRoutines.filter(e => e.id === routine.id).length > 0) {
			// replace an existing routine
			saveRoutines(validRoutines.map(e => (e.id === routine.id ? routine : e)));
		} else {
			// add new routine to array
			saveRoutines([routine, ...validRoutines]);
		}
	}

	async function handleDeleteRoutine(routine) {
		setLoading(true);
		setWarningText(null);

		const response = await RoutineController.deleteRoutine(routine?.id);
		if (response.hasError) {
			setWarningText(response.data);
		} else {
			saveRoutines(validRoutines.filter(e => e.id !== routine.id));
		}

		setLoading(false);
	}

	function handleInput(event) {
		const { name, value } = event.target;
		switch (name) {
			case 'balance':
				setBalance(
					value.length > 0
						? currency(value.toString().replace(/-/g, ''), {
								symbol: '',
								precision: 0,
								separator: ',',
						  }).format()
						: ''
				);
				break;
			case 'credit':
				setCredit(
					value.length > 0
						? currency(value.toString().replace(/-/g, ''), {
								symbol: '',
								precision: 0,
								separator: ',',
						  }).format()
						: ''
				);
				break;
			case 'account':
				setHighestVal(currency(value === Accounts[0] ? balance : credit).value);
				setLowestVal(currency(value === Accounts[0] ? balance : credit).value);
				setAccount(value);
				break;
			case 'length':
				setLength(value);
				break;
			default:
				return;
		}
	}

	async function handleRefresh() {
		setRefreshShowing(false);
		setLoading(true);
		const varsCopy = [...variables];
		for (let i = 0; i < varsCopy.length; i++) {
			const v = varsCopy[i];
			for (let j = 0; j < v.occasionInstances.length; j++) {
				const instance = v.occasionInstances[j];
				const response = await VariableController.removeInstance(instance.id);
				if (response.hasError) {
					setWarningText(response.data);
				}
			}
		}
		const routinesCopy = [...validRoutines];
		for (let i = 0; i < routinesCopy.length; i++) {
			const r = routinesCopy[i];
			for (let j = 0; j < r.updates.length; j++) {
				const update = r.updates[j];
				await handleRemoveModifier(ItemModifierType.Update, { id: update.id });
			}
		}
		await fetchVariables();
		await fetchRoutines();
		setLoading(false);
	}

	function buildBalancePeak() {
		let text = '',
			label = '';
		if (!getConfigComplete()) {
			label = `${account === Accounts[0] ? 'Lowest' : 'Highest'} Balance`;
		} else if (account === Accounts[0]) {
			// Bank
			label = 'Lowest Balance';
			text =
				currency(lowestVal, { symbol: '£', precision: 0, separator: ',' }).format() +
				' on ' +
				moment(lowestDay ?? starts).format('DD/MM/YY');
		} else {
			// Credit Card
			label = 'Highest Balance';
			text =
				currency(highestVal, { symbol: '£', precision: 0, separator: ',' }).format() +
				' on ' +
				moment(highestDay ?? starts).format('DD/MM/YY');
		}
		return <TextField variant="filled" label={label} type="text" value={text} fullWidth disabled />;
	}

	function buildConfigArea() {
		return (
			<>
				<div className={classes.configBtn}>
					<Grid container spacing={2}>
						<Grid item lg={2} md={4} sm={6} xs={12}>
							<Button
								variant="contained"
								color="secondary"
								onClick={() => setShowConfig(!showConfig)}
								startIcon={<SettingsOutlinedIcon />}
								fullWidth
							>
								{!showConfig ? 'Configure Timeline' : 'Hide Config'}
							</Button>
						</Grid>
						<Grid item lg={2} md={4} sm={6} xs={12}>
							{buildBalancePeak()}
						</Grid>
					</Grid>
				</div>
				<div className={`${classes.configArea} ${!showConfig ? 'collapsed' : ''}`}>
					<Grid container spacing={2}>
						<Grid item xl={1} lg={1} md={4} sm={6} xs={12} className={classes.smallInput}>
							<MuiPickersUtilsProvider utils={MomentUtils}>
								<KeyboardDatePicker
									fullWidth
									margin="none"
									label="Start Date"
									format="DD/MM/YY"
									inputVariant="filled"
									value={starts}
									onChange={e => {
										setStarts(e);
										setTimeout(() => handleSaveForecast(e), 1000);
									}}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									InputProps={{
										required: true,
									}}
									InputLabelProps={{
										required: true,
									}}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item xl={1} lg={1} md={4} sm={6} xs={12} className={classes.smallInput}>
							<TextField
								variant="filled"
								label="Bank at Start"
								value={balance}
								name="balance"
								onBlur={() => handleSaveForecast()}
								onChange={handleInput}
								fullWidth
								InputProps={{
									startAdornment: <InputAdornment position="start">£</InputAdornment>,
								}}
								required
							/>
						</Grid>
						<Grid item xl={1} lg={1} md={4} sm={6} xs={12} className={classes.smallInput}>
							<FormControl variant="filled" fullWidth>
								<InputLabel id="length-label">Forecast Period</InputLabel>
								<Select
									fullWidth
									labelId="length-label"
									value={length}
									name="length"
									onChange={handleInput}
								>
									{ProjectionLengths.map((acc, i) => (
										<MenuItem key={i} value={acc}>
											{acc.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item lg={2} className={`${classes.hideAtMd} ${classes.balanceInput}`}>
							{buildBalancePeak()}
						</Grid>
					</Grid>
				</div>
			</>
		);
	}

	function buildRoutineItem(routine, date, index) {
		return (
			<Card
				key={index}
				style={{
					width: '100%',
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					padding: 4,
				}}
			>
				<ItemIcon
					size={22}
					itemName={routine.name}
					iconName={routine.iconName}
					iconBGColour={getCategoryColour(
						categories?.find(e => e.id === routine.categoryId)?.name ?? Category.Basics
					)}
				/>
				<div style={{ marginLeft: 4, fontSize: 11, maxWidth: 44, zIndex: 1 }}>
					<Typography noWrap style={{ fontSize: 11, lineHeight: 1 }}>
						{routine.name}
					</Typography>
					{currency(getRoutineRealAmount(routine, date), {
						symbol: '£',
						precision: 0,
						separator: ',',
					}).format()}
				</div>
			</Card>
		);
	}

	function buildVariableItem(variable, key) {
		return (
			<div
				key={key}
				style={{
					width: '100%',
					height: '100%',
					maxHeight: 33,
					display: 'flex',
					alignItems: 'center',
					padding: 4,
				}}
			>
				<ItemIcon
					size={24}
					itemName={variable.name}
					iconName={variable.iconName}
					iconBGColour={getCategoryColour(
						categories?.find(e => e.id === variable.categoryId)?.name ?? Category.Basics
					)}
				/>
				<div style={{ marginLeft: 4, fontSize: 11, maxWidth: 44, zIndex: 1 }}>
					<Typography noWrap style={{ fontSize: 11, lineHeight: 1 }}>
						{variable.name}
					</Typography>
					{currency(variable.amount, { symbol: '£', precision: 0, separator: ',' }).format()}
				</div>
			</div>
		);
	}

	function buildCellContent(routines, variableInstances, columnIndex, date, numColumns) {
		if (routines.length === 0 && variableInstances.length === 0) {
			return (
				<Fab className="add-instance">
					<AddIcon />
				</Fab>
			);
		}

		let routineContent = [],
			variableContent = [];
		if (!isNullOrUndefined(routines) || routines.length > 0) {
			routineContent = routines.map((a, i) => buildRoutineItem(a, date, columnIndex + '-' + i));
		}
		if (!isNullOrUndefined(variableInstances) || variableInstances.length > 0) {
			variableContent = variableInstances.map((a, i) =>
				buildVariableItem(a, routineContent.length + columnIndex + '-' + i)
			);
		}

		const gridTemplateColumns = [...Array(numColumns)].map(() => '80px').join(' ');

		return (
			<Box
				display="grid"
				style={{
					gridTemplateRows: '33px 33px',
					gridTemplateColumns,
					gridGap: '4px',
					marginTop: 12,
				}}
			>
				{[...routineContent, ...variableContent]}
			</Box>
		);
	}

	function buildGraph(value) {
		const totalArea = Math.max(1, highestVal - lowestVal);
		const percentile = totalArea / 100;
		const positiveHeight = highestVal > 0 ? highestVal / percentile : 0;
		const negativeHeight = -(lowestVal < 0 ? lowestVal / percentile : 0);
		const innerPosHeight = value > 0 ? value / (highestVal / 100) : 0;
		const innerNegHeight = -(value < 0 ? value / (-lowestVal / 100) : 0);
		const creditOverride = account === Accounts[0] ? '' : 'credit';

		return (
			<div className="graph">
				<div style={{ height: `${positiveHeight}%` }} className="positive-wrapper">
					<div className={`positive ${creditOverride}`} style={{ height: `${innerPosHeight}%` }}></div>
				</div>
				<div style={{ height: `${negativeHeight}%` }} className="negative-wrapper">
					<div className={`negative ${creditOverride}`} style={{ height: `${innerNegHeight}%` }}></div>
				</div>
			</div>
		);
	}

	function buildColumn(dayIndex, value, date = moment(), routines = [], variables = []) {
		const colCategories = getCategories();
		let numColumns = 1;
		return (
			<DeferRender className={classes.columnWrapper} key={dayIndex}>
				<div className="day-column">
					<div className="date">
						<span>
							<b>{date.format('ddd')}</b>
						</span>
						<span>{date.format('DD/MM/YY')}</span>
					</div>
					{colCategories.map((e, i) => {
						const isLoan = account === 'Loans';

						const realCatId =
							account === Accounts[1] && e.name === Category.MoneyIn
								? categories.find(e => e.name === Category.CreditCardBill).id
								: e.id;
						const hasContent = realCatId !== 'empty';

						const categoryRoutines = getCategoryItems(realCatId, routines);
						const variableInstances = getCategoryItems(realCatId, variables);
						const categoryColumns =
							Math.round((categoryRoutines.length + variableInstances.length) / 2) ?? 1;

						if (categoryColumns > numColumns) {
							numColumns = categoryColumns;
						}

						const categoryContent = () => {
							if (hasContent) {
								return (
									<div
										key={realCatId}
										className="item-row interactive"
										onClick={() => {
											setAddDate(date);
											setCategory(e.id);
											setContextRoutines(routines);
										}}
									>
										<div className="subtitle-wrapper">
											{dayIndex === 0 ? (
												<span className="subtitle">
													{e.name === 'Income' && isLoan
														? 'Repayment'
														: getRenamedLoanCategory(e.name, isLoan)}
												</span>
											) : null}
										</div>
										{buildCellContent(categoryRoutines, variableInstances, i, date, numColumns)}
									</div>
								);
							} else {
								return <div key={realCatId} className="item-row"></div>;
							}
						};

						const bottomContent = () => {
							if (colCategories.length - 1 !== i) return null;

							const isNegative = value.value < 0;

							return (
								<>
									<div className="col-value" style={{ color: isNegative ? 'red' : '#000' }}>
										{currency(value, { symbol: '£', precision: 0, separator: ',' }).format()}
									</div>
									{buildGraph(value)}
								</>
							);
						};

						return (
							<>
								{categoryContent()}
								{bottomContent()}
							</>
						);
					})}
				</div>
			</DeferRender>
		);
	}

	function buildTimeline() {
		if (!getConfigComplete()) {
			return (
				<Typography variant="subtitle2" align="center" className={classes.noConfig}>
					<SettingsOutlinedIcon />
					<br />
					Please configure a start date and starting balances
				</Typography>
			);
		}

		const totalDays = getTotalDays() - 1;
		const timelineEndDate = moment(starts).startOf('day').add(totalDays, 'days');
		const routinesAndDays = validRoutines.map(e => {
			return {
				routine: e,
				days: getRoutineDays(
					moment(e.starts).startOf('day'),
					isNullOrWhitespace(e.ends) ? timelineEndDate : moment(e.ends).startOf('day'),
					e.recurrence
				),
			};
		});

		const values = [];
		const columns = [];
		let value = currency(account === Accounts[0] ? balance : credit);
		let lowDay = moment(starts);
		let lowVal = value.value;
		let highDay = moment(starts);
		let highVal = value.value;

		for (let i = 0; i <= totalDays; i++) {
			const date = moment(starts).startOf('day').add(i, 'days');
			const rawDateVariables = variables.filter(
				e =>
					(e.occasionInstances ?? []).filter(
						i => date.startOf('day').format() === moment.utc(i.on).local().startOf('day').format()
					).length > 0
			);
			const accountedDateVariables = [];
			rawDateVariables.forEach(e => {
				const todays = (e.occasionInstances ?? []).filter(
					i => date.startOf('day').format() === moment.utc(i.on).local().startOf('day').format()
				);
				todays.forEach(d => {
					const copy = JSON.parse(JSON.stringify(e));
					copy.accountName = e.categoryId === categories[2].id ? Accounts[0] : d.accountName;
					copy.amount = d.amount;
					accountedDateVariables.push(copy);
				});
			});
			const dateRoutines = routinesAndDays
				.filter(e => e.days.filter(d => date.startOf('day').format() === d.startOf('day').format()).length > 0)
				.map(e => e.routine);
			value = value.add(currency(getBalanceChange(accountedDateVariables, date, false)));
			value = value.add(currency(getBalanceChange(dateRoutines, date, true)));
			if (i === 0) {
				lowVal = value.value;
				highVal = value.value;
			} else {
				if (value.value < lowVal) {
					lowVal = value.value;
					lowDay = date;
				}
				if (value.value > highVal) {
					highVal = value.value;
					highDay = date;
				}
			}
			values.push(value);
			columns.push(buildColumn(i, value, date, dateRoutines, accountedDateVariables));
		}

		if (highestVal !== highVal || lowestVal !== lowVal) {
			setHighestDay(highDay);
			setLowestDay(lowDay);
			setHighestVal(highVal);
			setLowestVal(lowVal);
		}

		return <div className={classes.timeline}>{columns}</div>;
	}

	return (
		<>
			<div className={classes.root}>
				{buildConfigArea()}
				{buildTimeline()}
			</div>

			<TimelineAddRemove
				date={addDate}
				account={account}
				category={addCategory}
				categories={categories}
				variables={variables}
				routines={contextRoutines}
				onClose={() => setAddDate(null)}
				onAdd={(type, data) => handleAddModifier(type, data)}
				onRemove={(type, data) => handleRemoveModifier(type, data)}
				onEdit={(type, data) => handleEditModifier(type, data)}
				onAddEditVariable={handleAddEditVariable}
				onAddEditRoutine={handleAddEditRoutine}
				onDeleteVariable={handleDeleteVariable}
				onDeleteRoutine={handleDeleteRoutine}
			/>

			<Dialog open={refreshShowing}>
				<DialogTitle>Are you sure?</DialogTitle>
				<DialogContent>
					This will remove all non-routine items from your forecast and clear any changes to routine item
					values you have made.
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={handleRefresh}>
						Yes
					</Button>
					<Button color="primary" onClick={() => setRefreshShowing(false)}>
						No
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={!isNullOrUndefined(warningText)}>
				<DialogTitle>Error</DialogTitle>
				<DialogContent>
					<Alert header="Something went wrong!" text={warningText} />
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={() => setWarningText(null)}>
						OK
					</Button>
				</DialogActions>
			</Dialog>

			<LoadingOverlay loading={loading} />
		</>
	);
}

const mapStateToProps = state => ({
	Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
	PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);

Timeline.propTypes = {
	Auth: PropTypes.object,
	PushHistory: PropTypes.func,
};
