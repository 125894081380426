import { Category, Accounts, RepeatIntervals } from './Constants';
import { ItemIcons } from './ItemIconLib';

// NOTE: name property is used as a unique identifier, ensure all names are used once only
export const RoutineItemPresets = [
	{
		name: 'Wage',
		account: Accounts[0],
		category: Category.MoneyIn,
		iconName: ItemIcons.MI_WAGES.name,
		iconBGColour: '#008000',
		repeats: RepeatIntervals[4],
	},
	{
		name: 'Rent',
		account: Accounts[0],
		category: Category.Basics,
		iconName: ItemIcons.BA_RENT.name,
		iconBGColour: '#dc143c',
		repeats: RepeatIntervals[4],
	},
	{
		name: 'Credit Card Bill',
		account: Accounts[0],
		category: Category.CreditCardBill,
		iconName: ItemIcons.CC_BILL.name,
		iconBGColour: '#1e90ff',
		repeats: RepeatIntervals[4],
	},
	{
		name: 'Rent',
		account: Accounts[1],
		category: Category.Basics,
		iconName: ItemIcons.BA_RENT.name,
		iconBGColour: '#dc143c',
		repeats: RepeatIntervals[4],
	},
	{
		name: 'Credit Card Bill',
		account: Accounts[1],
		category: Category.CreditCardBill,
		iconName: ItemIcons.CC_BILL.name,
		iconBGColour: '#1e90ff',
		repeats: RepeatIntervals[4],
	},
];

export const VariableItemPresets = [
	{
		name: 'Transfer from Savings',
		category: Category.MoneyIn,
		iconName: ItemIcons.MI_TRANSFER_FROM_SAVINGS.name,
		iconBGColour: '#708090',
	},
	{
		name: 'Clothes & Shoes',
		category: Category.Basics,
		iconName: ItemIcons.BA_CLOTHES.name,
		iconBGColour: '#808000',
	},
	{
		name: 'Cafe Visit',
		category: Category.Choices,
		iconName: ItemIcons.CH_CAFE.name,
		iconBGColour: '#6a5acd',
	},
];
