import React from 'react';
import PropTypes from 'prop-types';
import MoneyPlanController from '../../controllers/MoneyPlanController';
import MoneyPlanItemContainer from '../../components/MoneyPlan/MoneyPlanItemContainer';
import MoneyPlanIconItem from '../../components/MoneyPlan/MoneyPlanIconItem';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Add, Home } from '@material-ui/icons';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Fab,
	makeStyles,
	Popover,
	Typography,
} from '@material-ui/core';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { Alert } from '../../components/Common/Alert';
import { MoneyPlanCategory } from '../../helpers/Constants';
import { isNullOrUndefined, returnNumberWithCommas } from '../../helpers/Utils';
import { Colours } from '../../helpers/Colours';
import MoneyPlanIconList from '../../components/MoneyPlan/MoneyPlanIconList';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	root: {
		margin: 'auto',
		marginTop: 104,
		maxWidth: 1024,
		height: 'calc(100% - 104px)',
		backgroundColor: Colours.bg_grey_1,
		overflow: 'auto',
		'& .MuiTypography-h3': {
			color: Colours.white,
			fontSize: 24,
			[theme.breakpoints.down('xs')]: {
				fontSize: 20,
				marginRight: 20,
			},
		},
		'& .MuiTypography-h4': {
			color: Colours.whiteBodyText,
			fontSize: 18,
			[theme.breakpoints.down('xs')]: {
				fontSize: 16,
			},
		},
	},
	headerBar: {
		backgroundColor: Colours.primary,
		borderBottom: `3px solid ${Colours.bg_grey_3}`,
		width: '100%',
		maxWidth: 1024,
		height: 104,
		top: 48,
		padding: '24px 36px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		position: 'fixed',
	},
	comicWrapper: {
		padding: theme.spacing(2),
		height: 'auto',
		minHeight: '100%',
		display: 'flex',
		flexWrap: 'wrap',
	},
	categoryTotal: {
		fontSize: 40,
	},
	addBtn: {
		margin: 63,
		boxShadow: 'none',
		color: Colours.white,
		[theme.breakpoints.down('xs')]: {
			margin: 32,
			width: 45,
			height: 45,
			minWidth: '45px !important',
			minHeight: '45px !important',
		},
	},
	resetButtonContainer: {
		position: 'absolute',
		bottom: 24,
		left: 20,
		[theme.breakpoints.down('md')]: {
			position: 'static',
			display: 'flex',
			justifyContent: 'center',
			marginBottom: 20,
		},
	},
	buttonContainer: {
		position: 'absolute',
		bottom: 24,
		right: 20,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		[theme.breakpoints.down('md')]: {
			position: 'static',
			alignItems: 'center',
		},
	},
	button: {
		width: 'fit-content',
		marginTop: 12,
	},
	homeIcon: {
		[theme.breakpoints.down('xs')]: {
			width: 45,
			height: 45,
			minWidth: '45px !important',
			minHeight: '45px !important',
		},
	},
	popover: {
		pointerEvents: 'none',
	},
	paper: {
		padding: theme.spacing(1),
	},
}));

function MoneyPlan(props) {
	const classes = useStyles();
	const { Auth, PushHistory } = props;
	const [redirectUrl, setRedirectUrl] = React.useState(null);
	const [instance, setInstance] = React.useState(0);
	const [selectedCategory, setSelectedCategory] = React.useState(null);
	const [deletingItem, setDeletingItem] = React.useState(null);
	const [allFlipped, setAllFlipped] = React.useState(false);
	const [planItems, setPlanItems] = React.useState([]);
	const [warning, setWarning] = React.useState(null);
	const [warningText, setWarningText] = React.useState('');
	const [loading, setLoading] = React.useState(true);
	const [deleting, setDeleting] = React.useState(false);
	const [allCardsFilled, setAllCardsFilled] = React.useState(false);
	const [resetBudgetOpen, setResetBudgetOpen] = React.useState(false);
	const [includedAllItems, setIncludedAllItems] = React.useState(false);

	const [anchorEl, setAnchorEl] = React.useState(null);

	const open = Boolean(anchorEl);

	const fetchInstance = React.useCallback(async () => {
		setWarning(null);
		const response = await MoneyPlanController.getMyInstance();
		if (!response.hasError) {
			const fetchedItems = response.data.plannerValues;
			setPlanItems(
				fetchedItems.map(e => ({
					...e,
					tempValue: null,
					tempYearlyOccurrence: null,
					tempDescription: null,
				}))
			);
			setInstance(response.data.state);
		} else {
			setWarning(response.data);
		}
	}, []);

	React.useEffect(() => {
		async function init() {
			if (!Auth.isAuthenticated) {
				setRedirectUrl('/Login');
				return;
			}
			await fetchInstance();
			setLoading(false);
		}
		init();
	}, [Auth, fetchInstance]);

	React.useEffect(() => {
		if (!isNullOrUndefined(redirectUrl)) {
			PushHistory(redirectUrl);
		}
	}, [redirectUrl, PushHistory]);

	React.useEffect(() => {
		checkItemValidity();
	}, [planItems, deleting, selectedCategory]);

	function checkItemValidity() {
		for (let i = 0; i < planItems.length; i++) {
			if (planItems[i].value === 0 || planItems[i].yearlyOccurrence === 0) {
				setAllCardsFilled(false);
				break;
			} else {
				setAllCardsFilled(true);
			}
		}
	}

	function getCardTotal(item) {
		const { value, tempValue, yearlyOccurrence, tempYearlyOccurrence } = item;
		const realValue = !isNullOrUndefined(tempValue) ? tempValue : value;
		const realYearlyOccurrence = !isNullOrUndefined(tempYearlyOccurrence) ? tempYearlyOccurrence : yearlyOccurrence;
		return realValue * realYearlyOccurrence;
	}

	const handlePopoverOpen = event => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	function handleResetBudgetClose() {
		setResetBudgetOpen(false);
	}

	function handleIncludedAllItemsOpen() {
		setIncludedAllItems(true);
	}

	function handleIncludedAllItemsClose() {
		setIncludedAllItems(false);
	}

	function handleResetBudgetOpen() {
		setResetBudgetOpen(true);
	}

	async function handleUpdateInstance(phase) {
		// setLoading(true);
		setInstance(phase);
		const response = await MoneyPlanController.updateInstance(phase);
		if (response.hasError) {
			setWarningText(response.data);
			setLoading(false);
			return;
		}
		// setLoading(false);
	}

	async function handleResetBudgetPlan() {
		setDeleting(true);
		handleResetBudgetClose();
		await handleUpdateInstance(0);
		planItems.forEach(async el => {
			const response = await MoneyPlanController.deleteValue(el.id);
			if (response.hasError) {
				setWarningText(response.data);
				return;
			}
		});
		setPlanItems([]);
		setDeleting(false);
	}

	async function handleSaveItem(id) {
		const item = planItems.find(e => e.id === id);
		const response = await MoneyPlanController.editValue(
			item?.id,
			item?.icon,
			item?.category,
			item?.tempValue ?? item?.value,
			item?.tempYearlyOccurrence ?? item?.yearlyOccurrence,
			item?.tempDescription
		);
		if (response.hasError) {
			setWarning(response.data);
		} else {
			setPlanItems(
				planItems.map(e =>
					e.id === id
						? { ...response.data, tempValue: null, tempYearlyOccurrence: null, tempDescription: null }
						: e
				)
			);
		}
	}

	async function handleDeleteItem() {
		setLoading(true);
		setDeleting(true);
		const response = await MoneyPlanController.deleteValue(deletingItem.id);
		if (!response.hasError) {
			setPlanItems(planItems.filter(e => e.id !== deletingItem.id));
		} else {
			setWarning(response.data);
		}
		setDeletingItem(null);
		setLoading(false);
		setDeleting(false);
	}

	function handleAddCategoryItem(newItem) {
		setSelectedCategory(null);
		setPlanItems([...planItems, newItem]);
	}

	function handleUpdateItem(id, newValue, newYearlyOccurrence, newDescription) {
		setPlanItems(
			planItems.map(e =>
				e.id === id
					? {
							...e,
							tempValue: newValue,
							tempYearlyOccurrence: newYearlyOccurrence,
							tempDescription: newDescription,
					  }
					: e
			)
		);
	}

	function handleCancelItem(id) {
		setPlanItems(
			planItems.map(e =>
				e.id === id ? { ...e, tempValue: null, tempYearlyOccurrence: null, tempDescription: null } : e
			)
		);
	}

	function handleGo() {
		handleUpdateInstance(1);
		handleIncludedAllItemsClose();
		setAllFlipped(false);
	}

	function handleFineTune() {
		handleUpdateInstance(2);
		setAllFlipped(true);
	}

	function handleOverview() {
		handleUpdateInstance(3);
		setAllFlipped(false);
	}

	function handleSeeSummary() {
		handleUpdateInstance(4);
		setAllFlipped(false);
	}

	function buildResetBudgetButton() {
		return (
			<div className={classes.resetButtonContainer}>
				<Fab
					className={`${classes.button}`}
					variant="extended"
					color="primary"
					onClick={() => handleResetBudgetOpen()}
				>
					Reset Budget
				</Fab>
			</div>
		);
	}

	function PhaseButton({ name, color, callback, disabled }) {
		return (
			<Fab
				className={`${classes.button}`}
				variant="extended"
				color={color}
				disabled={disabled}
				onClick={() => callback()}
			>
				{name}
			</Fab>
		);
	}

	function buildButtonContainer() {
		return (
			<div className={classes.buttonContainer}>
				{instance === 0 && (
					<PhaseButton
						name={'GO'}
						color={'secondary'}
						callback={handleIncludedAllItemsOpen}
						disabled={!allCardsFilled}
					/>
				)}
				{instance === 1 && (
					<PhaseButton name={'Show Results'} color={'secondary'} callback={handleFineTune} disabled={false} />
				)}
				{(instance === 2 || instance === 3) && (
					<>
						<PhaseButton
							name={'Fine-Tune'}
							color={'primary'}
							callback={handleFineTune}
							disabled={instance === 2}
						/>
						<PhaseButton
							name={'Overview'}
							color={'primary'}
							callback={handleOverview}
							disabled={instance === 3}
						/>
						<PhaseButton name={'See Summary'} color={'secondary'} callback={handleSeeSummary} />
					</>
				)}
				{instance === 4 && <PhaseButton name={'Fine-Tune'} color={'secondary'} callback={handleFineTune} />}
			</div>
		);
	}

	function buildTitle() {
		let title = '';
		const grandTotal = localStorage.getItem('grandTotal');
		const titles = {
			default: 'Building Your Budget',
			surplus: "You're making a SURPLUS!",
			breakEven: "You've BROKEN EVEN",
			deficit: "You're running a DEFICIT",
		};

		if (instance === 0) {
			title = titles.default;
		} else if (grandTotal < 0) {
			title = titles.deficit;
		} else if (grandTotal === 0) {
			title = titles.breakEven;
		} else {
			title = titles.surplus;
		}

		return (
			<div>
				<Typography
					variant="h3"
					aria-owns={open ? 'mouse-over-popover' : undefined}
					aria-haspopup="true"
					onMouseEnter={handlePopoverOpen}
					onMouseLeave={handlePopoverClose}
					gutterBottom
				>
					{title}
				</Typography>
				{instance !== 0 && buildPopover()}
				{instance !== 0 && <Typography variant="h4">£{returnNumberWithCommas(grandTotal)}</Typography>}
			</div>
		);
	}

	function buildHeaderBar() {
		const buttonSize = window.innerWidth < 600 ? 'small' : 'large';
		return (
			<div className={classes.headerBar}>
				{buildTitle()}
				<Fab className={classes.homeIcon} to="/" component={Link} color="secondary">
					<Home fontSize={buttonSize} />
				</Fab>
			</div>
		);
	}

	function buildCategories() {
		const categories = Object.values(MoneyPlanCategory);
		let overallTotal = 0;
		return (
			<div className={classes.comicWrapper}>
				{categories.map(category => {
					const items = planItems.filter(item => item.category === category.id);
					let categoryTotal = 0;
					items.forEach(e => (categoryTotal += getCardTotal(e)));
					if (category.id === 0) {
						overallTotal += categoryTotal;
					} else {
						overallTotal -= categoryTotal;
					}
					return (
						<MoneyPlanItemContainer category={category} key={category.id} total={categoryTotal}>
							{instance !== 4 ? (
								<>
									{items.map(item => (
										<MoneyPlanIconItem
											key={item.id}
											item={item}
											flipped={allFlipped}
											total={getCardTotal(item)}
											color={category.borderColor}
											altColor={category.color}
											onChange={handleUpdateItem}
											onCancel={handleCancelItem}
											onSave={handleSaveItem}
											instance={instance}
											onDelete={() => setDeletingItem(item)}
										/>
									))}
									{instance !== 1 && (
										<Fab className={classes.addBtn} onClick={() => setSelectedCategory(category)}>
											<Add />
										</Fab>
									)}
								</>
							) : (
								<div className={classes.categoryTotal}>£{returnNumberWithCommas(categoryTotal)}</div>
							)}
						</MoneyPlanItemContainer>
					);
				})}
				{localStorage.setItem('grandTotal', overallTotal)}
			</div>
		);
	}

	function buildError() {
		return (
			<Dialog open={!isNullOrUndefined(warning)} onClose={() => setWarning(null)}>
				<DialogTitle>Error</DialogTitle>
				<DialogContent>
					<Alert text={warning} header="Something went wrong!" />
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={() => setWarning(null)}>
						OK
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function buildConfirmDelete() {
		return (
			<Dialog open={!isNullOrUndefined(deletingItem)} onClose={() => setDeletingItem(null)}>
				<DialogTitle>Confirm Delete</DialogTitle>
				<DialogContent>
					<Typography>Are you sure you want to delete this item?</Typography>
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={handleDeleteItem}>
						Yes
					</Button>
					<Button color="primary" onClick={() => setDeletingItem(null)}>
						No
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function buildResetBudget() {
		return (
			<Dialog
				open={resetBudgetOpen}
				onClose={() => handleResetBudgetClose()}
				aria-labelledby="alert-dialog-title"
			>
				<DialogTitle id="alert-dialog-title">Are you sure you want to reset your entire budget?</DialogTitle>
				<DialogContent>
					<DialogContentText>Press 'Yes' to delete all items or 'No' to cancel</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleResetBudgetPlan()} color="primary" autoFocus>
						Yes
					</Button>
					<Button onClick={() => handleResetBudgetClose()} color="primary">
						No
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function buildIncludedAllItems() {
		return (
			<Dialog
				open={includedAllItems}
				onClose={() => handleIncludedAllItemsClose()}
				aria-labelledby="alert-dialog-title"
			>
				<DialogTitle id="alert-dialog-title">Have you included all items?</DialogTitle>
				<DialogContent>
					<DialogContentText>Press 'Yes' to confirm and see result or 'No' to go back</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleGo()} color="primary" autoFocus>
						Yes
					</Button>
					<Button onClick={() => handleIncludedAllItemsClose()} color="primary">
						No
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function buildPopover() {
		let title = '';
		const grandTotal = localStorage.getItem('grandTotal');
		const titles = {
			default: '',
			surplus: 'Your income is greater than your expenses',
			breakEven: 'Your income and expenses are the same',
			deficit: 'Your income is less than your expenses',
		};

		if (grandTotal < 0) {
			title = titles.deficit;
		} else if (grandTotal === 0) {
			title = titles.breakEven;
		} else {
			title = titles.surplus;
		}

		return (
			<Popover
				id="mouse-over-popover"
				className={classes.popover}
				classes={{
					paper: classes.paper,
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Typography>{title}</Typography>
			</Popover>
		);
	}

	return (
		<div className={classes.root}>
			{buildHeaderBar()}
			{buildCategories()}
			<MoneyPlanIconList
				category={selectedCategory}
				onDone={handleAddCategoryItem}
				onCancel={() => setSelectedCategory(null)}
				onError={setWarning}
			/>
			{buildConfirmDelete()}
			{buildError()}
			{buildResetBudget()}
			{buildIncludedAllItems()}
			{buildButtonContainer()}
			{buildResetBudgetButton()}
			<LoadingOverlay loading={loading} />
		</div>
	);
}

const mapStateToProps = instance => ({
	Auth: instance.Authentication,
});

const mapDispatchToProps = dispatch => ({
	PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MoneyPlan);

MoneyPlan.propTypes = {
	Auth: PropTypes.object,
	PushHistory: PropTypes.func,
};
