import { Colours } from './Colours';

export const versionName = 'WebApp';

export const versionNum = 37;

export const APIDomain = 'https://flipsidecashforecasting-api.azurewebsites.net';

export const APIRoute = APIDomain + '/api/';

export const APIPath = {
	// Users
	PASSWORD_REQUIREMENTS: 'User/PasswordRequirements',
	LOGIN: 'User/LogIn',
	LOGIN_PASSWORD_RESET: 'User/LogInWithPasswordReset',
	LOGIN_GOOGLE: 'User/LoginWithGoogle',
	LOGOUT: 'User/LogOut',
	REGISTER: 'User/Register',
	REGISTER_GOOGLE: 'User/RegisterWithGoogle',
	EMAIL_CONFIRMATION: 'User/EmailConfirmation',
	FORGOT_PASSWORD: 'User/ForgotPassword',
	RESET_PASSWORD: 'User/ResetPassword',
	CHANGE_PASSWORD: 'User/ChangePassword',
	GET_USER_INFO: 'User/GetUsersInfo',
	REQUEST_EMAIL_CHANGE: 'User/RequestChangeEmail',
	CONFIRM_EMAIL_CHANGE: 'User/ConfirmChangeEmail',
	REQUEST_EMAIL_CONFIRMATION: 'User/RequestEmailConfirmation',
	FINISH_EXTERNAL_LOGIN: 'User/CheckLocalUser',
	FINISH_EXTERNAL_SIGNUP: 'User/CreateLocalUser',
	TWO_FACTOR_GET_KEY: 'User/GetAuthenticatorCode',
	TWO_FACTOR_ADD: 'User/AddAuthentication',
	TWO_FACTOR_REMOVE: 'User/RemoveAuthentication',
	ADD_DEVICE: 'User/AddDevice',
	UPDATE_USER_ALTERNATE_LOGINS: 'User/UpdateUsersAlternateLogins',
	VERIFY_TWO_FACTOR: 'User/VerifyTwoFactor',
	GET_MOTD: 'User/GetMOTD',
	SEEN_MOTD: 'User/SeenMOTD',
	REFRESH_TOKEN: 'User/RefreshToken',

	// Notifications
	GET_UNREAD_NOTIF_COUNT: 'Notifications/GetUnreadCount',
	GET_TOP_NOTIFS: 'Notifications/GetTop',
	GET_ALL_NOTIFS: 'Notifications/GetAll',
	MARK_NOTIF_AS_READ: 'Notifications/MarkAsRead/',
	MARK_ALL_NOTIFS_AS_READ: 'Notifications/MarkAllAsRead',

	// File
	FILE_UPlOAD: 'File/Upload',
	FILE_GET_USERS: 'File/GetUsersFiles',

	// Version
	GET_VERSION: 'Version',

	// Money Planner
	GET_MY_INSTANCE: 'MoneyPlanner/MyInstance',
	UPDATE_INSTANCE: 'MoneyPlanner/UpdateInstance',
	ADD_NEW_VALUE: 'MoneyPlanner/AddNewValue',
	EDIT_VALUE: 'MoneyPlanner/EditValue',
	DELETE_VALUE: 'MoneyPlanner/DeleteValue/',

	// Routine
	GET_ROUTINES: 'Routine',
	ADD_ROUTINE: 'Routine/Add',
	EDIT_ROUTINE: 'Routine/Edit',
	DELETE_ROUTINE: 'Routine/Delete/',
	ADD_ROUTINE_UPDATE: 'Routine/AddUpdate',
	EDIT_ROUTINE_UPDATE: 'Routine/EditUpdate',
	REMOVE_ROUTINE_UPDATE: 'Routine/RemoveUpdate/',

	// Occasion (aka Variable)
	GET_OCCASIONS: 'Occasion',
	ADD_OCCASION: 'Occasion/Add',
	EDIT_OCCASION: 'Occasion/Edit',
	DELETE_OCCASION: 'Occasion/Delete/',
	ADD_OCCASION_INSTANCE: 'Occasion/AddInstance',
	REMOVE_OCCASION_INSTANCE: 'Occasion/RemoveInstance/',
	EDIT_OCCASION_INSTANCE: 'Occasion/EditInstance/',

	// Category
	GET_CATEGORY: 'Category',

	// Forecast
	GET_CURRENT_FORECAST: 'CurrentForecast',
	SET_CURRENT_FORECAST: 'CurrentForecast/Update',
};

export const SIGNALRPath = {
	Notifications: APIDomain + '/webhooks/notification',
};

export const Role = {
	Admin: 'Admin',
};

export const Accounts = ['Bank', 'Loans'];

export const RepeatIntervals = [
	'Once in the Year',
	'Weekly',
	'Every Two Weeks',
	'Every Four Weeks',
	'Monthly',
	'Every Three Months',
	'Every Six Months',
	'Yearly',
];

export const ProjectionLengths = [
	{ name: '1 Week', days: 7, months: 0, years: 0 },
	{ name: '2 Weeks', days: 14, months: 0, years: 0 },
	{ name: '4 Weeks', days: 28, months: 0, years: 0 },
	{ name: '1 Month', days: 0, months: 1, years: 0 },
	{ name: '3 Months', days: 0, months: 3, years: 0 },
	{ name: '6 Months', days: 0, months: 6, years: 0 },
	{ name: '1 Year', days: 0, months: 0, years: 1 },
];

export const MoneyPlanCategory = {
	Income: {
		id: 0,
		name: 'Bank Receipts',
		color: Colours.income,
		borderColor: Colours.incomeBorder,
	},
	RepayDebts: {
		id: 1,
		name: 'Repayment of Debts',
		color: Colours.repayDebts,
		borderColor: Colours.repayDebtsBorder,
	},
	Basics: {
		id: 3,
		name: 'Obligatory Payments',
		color: Colours.basics,
		borderColor: Colours.basicsBorder,
	},
	Choices: {
		id: 4,
		name: 'Discretionary Payments',
		color: Colours.choices,
		borderColor: Colours.choicesBorder,
	},
};

export const Category = {
	MoneyIn: 'Income',
	Basics: 'Basics',
	CreditCardBill: 'Credit Card Bill',
	Choices: 'Choices',
};

export const CategoryColours = [
	Colours.incomeBorder,
	Colours.basicsBorder,
	Colours.repayDebtsBorder,
	Colours.choicesBorder,
];

export const ItemModifierType = {
	Instance: 'OccasionInstance',
	Update: 'RoutineUpdate',
};

export const SelectableColours = [
	'#00FFFF',
	'#7FFFD4',
	'#FFE4C4',
	'#000000',
	'#FFEBCD',
	'#0000FF',
	'#8A2BE2',
	'#A52A2A',
	'#DEB887',
	'#5F9EA0',
	'#7FFF00',
	'#D2691E',
	'#FF7F50',
	'#6495ED',
	'#DC143C',
	'#00FFFF',
	'#00008B',
	'#008B8B',
	'#B886BB',
	'#A9A9A9',
	'#006400',
	'#BDB76B',
	'#8B008B',
	'#556B2F',
	'#FF8C00',
	'#9932CC',
	'#8B0000',
	'#E9967A',
	'#8FBC8B',
	'#483D8B',
	'#2F4F4F',
	'#00CED1',
	'#9400D3',
	'#FF1493',
	'#00BFFF',
	'#696969',
	'#1E90FF',
	'#B22222',
	'#228B22',
	'#FF00FF',
	'#DCDCDC',
	'#FFD700',
	'#DAA520',
	'#808080',
	'#008000',
	'#ADFF2F',
	'#FF69B4',
	'#CD5C5C',
	'#4B0082',
	'#F0E68C',
	'#7CFC00',
	'#ADD8E6',
	'#F08080',
	'#D3D3D3',
	'#90EE90',
	'#FFB6C1',
	'#FFA07A',
	'#20B2AA',
	'#87CEFA',
	'#778899',
	'#B0C4DE',
	'#00FF00',
	'#32CD32',
	'#FF00FF',
	'#800000',
	'#66CDAA',
	'#0000CD',
	'#BA55D3',
	'#9370DB',
	'#3CB371',
	'#7B68EE',
	'#00FA9A',
	'#48D1CC',
	'#C71585',
	'#191970',
	'#FFDEAD',
	'#000080',
	'#808000',
	'#6B8E23',
	'#FFA500',
	'#FF4500',
	'#DA70D6',
	'#98FB98',
	'#AFEEEE',
	'#DB7093',
	'#FFDAB9',
	'#CD853F',
	'#FFC0CB',
	'#DDA0DD',
	'#B0E0E6',
	'#800080',
	'#FF0000',
	'#BC8F8F',
	'#4169E1',
	'#8B4513',
	'#FA8072',
	'#F4A460',
	'#2E8B57',
	'#A0522D',
	'#C0C0C0',
	'#87CEEB',
	'#6A5ACD',
	'#708090',
	'#00FF7F',
	'#4682B4',
	'#D2B48C',
	'#008080',
	'#D8BFD8',
	'#FF6347',
	'#40E0D0',
	'#EE82EE',
	'#F5DEB3',
	'#FFFF00',
	'#9ACD32',
];

export const DragDropTypes = {
	ICON: 'Icon',
};
