import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Authentication from './Reducers/Authentication';
import Notifications from './Reducers/Notifications';

export default history =>
	combineReducers({
		router: connectRouter(history),
		Authentication,
		Notifications,
	});
