import Axios from 'axios';
import moment from 'moment';
import { APIRoute, APIPath } from '../helpers/Constants';
import { generateResponse, isNullOrUndefined, isStatusOK } from '../helpers/Utils';
import { IndexedDB } from '../helpers/IndexedDB';

const UserController = {
	getUserHeaders,
	getToken,
	hasTokenExpired,
	refreshToken,
	register,
	registerGoogle,
	login,
	loginWithPasswordReset,
	loginGoogle,
	logout,
	finishExternalLogin,
	getPasswordRequirements,
	forgotPassword,
	resetPassword,
	changePassword,
	addDeviceToken,
	getMOTD,
	seenMOTD,
	requestEmailConfirmation,
	checkForEmailConfirmation,
	requestEmailChange,
	confirmEmailChange,
	getUserInfo,
	getAuthenticatorCode,
	addTwoFactor,
	removeTwoFactor,
	verifyTwoFactor,
	refreshTokenCache,
	fetchCachedUserData,
};

async function getUserHeaders(contentType = null) {
	return await IndexedDB.fetch('authToken')
		.then(response => {
			if (isNullOrUndefined(contentType)) {
				return { headers: { Authorization: 'Bearer ' + response.data?.content } };
			} else {
				return {
					headers: {
						'Content-Type': contentType,
						Authorization: 'Bearer ' + response.data?.content,
					},
				};
			}
		})
		.catch(() => {
			return null;
		});
}

async function getToken() {
	return await IndexedDB.fetch('authToken')
		.then(response => {
			return response.data?.content;
		})
		.catch(() => {
			return null;
		});
}

async function hasTokenExpired() {
	return await IndexedDB.fetch('expires')
		.then(response => {
			return moment.utc(response.data.content).isSameOrBefore(moment.utc());
		})
		.catch(() => {
			return null;
		});
}

async function refreshToken(refreshToken) {
	const userHeaders = await getUserHeaders();
	const refreshModel = {
		refreshToken,
	};
	return Axios.post(APIRoute + APIPath.REFRESH_TOKEN, refreshModel, userHeaders)
		.then(async response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function register(email, password, passwordConfirmation, rememberMe) {
	const registerModel = {
		email,
		password,
		passwordConfirmation,
		rememberMe,
	};
	return Axios.post(APIRoute + APIPath.REGISTER, registerModel)
		.then(async response => {
			if (isStatusOK(response.status)) {
				await clearCachedUserData();
				return await cacheUserData(response.data).then(cacheResponse => {
					const output = cacheResponse.hasError ? cacheResponse.data : response.data;
					return generateResponse(cacheResponse.hasError, output, response);
				});
			} else {
				return generateResponse(true, response.data);
			}
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function registerGoogle(jwt) {
	const registerModel = {
		jwt,
	};
	return Axios.post(APIRoute + APIPath.REGISTER_GOOGLE, registerModel)
		.then(async response => {
			if (isStatusOK(response.status)) {
				await clearCachedUserData();
				return await cacheUserData(response.data).then(cacheResponse => {
					const output = cacheResponse.hasError ? cacheResponse.data : response.data;
					return generateResponse(cacheResponse.hasError, output, response);
				});
			} else {
				return generateResponse(true, response.data);
			}
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function login(email, password, rememberMe, twoFactorToken = null) {
	const loginModel = {
		email,
		password,
		rememberMe,
		twoFactorToken,
	};
	return Axios.post(APIRoute + APIPath.LOGIN, loginModel)
		.then(async response => {
			if (isStatusOK(response.status)) {
				await clearCachedUserData();
				return await cacheUserData(response.data).then(cacheResponse => {
					const output = cacheResponse.hasError ? cacheResponse.data : response.data;
					return generateResponse(cacheResponse.hasError, output, response);
				});
			} else {
				return generateResponse(true, response.data);
			}
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function loginGoogle(jwt, twoFactorToken = null) {
	const loginModel = {
		jwt,
		twoFactorToken,
	};
	return Axios.post(APIRoute + APIPath.LOGIN_GOOGLE, loginModel)
		.then(async response => {
			if (isStatusOK(response.status)) {
				await clearCachedUserData();
				return await cacheUserData(response.data).then(cacheResponse => {
					const output = cacheResponse.hasError ? cacheResponse.data : response.data;
					return generateResponse(cacheResponse.hasError, output, response);
				});
			} else {
				return generateResponse(true, response.data);
			}
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function loginWithPasswordReset(
	email,
	password,
	rememberMe,
	twoFactorToken,
	newPassword,
	newConfirmationPassword
) {
	const resetModel = {
		email,
		password,
		rememberMe,
		twoFactorToken,
		newPassword,
		newConfirmationPassword,
	};
	return Axios.post(APIRoute + APIPath.LOGIN_PASSWORD_RESET, resetModel)
		.then(async response => {
			if (isStatusOK(response.status)) {
				return await cacheUserData(response.data).then(cacheResponse => {
					const output = cacheResponse.hasError ? cacheResponse.data : response.data;
					return generateResponse(cacheResponse.hasError, output, response);
				});
			} else {
				return generateResponse(true, response.data);
			}
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function logout() {
	const userHeaders = await getUserHeaders();
	const clearResponse = await clearCachedUserData();
	if (clearResponse.hasError) {
		return clearResponse;
	}
	return Axios.post(APIRoute + APIPath.LOGOUT, null, userHeaders)
		.then(async response => {
			return generateResponse(false, null, response);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function finishExternalLogin() {
	const userHeaders = await getUserHeaders();
	return Axios.get(APIRoute + APIPath.FINISH_EXTERNAL_LOGIN, userHeaders)
		.then(response => {
			if (!response.data.exists) {
				return generateResponse(true, 'Unable to find account, please register first');
			} else if (!response.data) {
				return generateResponse(true, 'Failed to connect to service');
			}
			return generateResponse(false, response.data, response);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function getPasswordRequirements() {
	return Axios.get(APIRoute + APIPath.PASSWORD_REQUIREMENTS)
		.then(response => {
			return generateResponse(false, response.data, response);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function forgotPassword(email) {
	const forgotModel = { email };
	const userHeaders = await getUserHeaders();
	return Axios.post(APIRoute + APIPath.FORGOT_PASSWORD, forgotModel, userHeaders)
		.then(response => {
			return generateResponse(false, null, response);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function resetPassword(userId, code, password, passwordConfirmation) {
	const resetModel = {
		userId,
		code,
		password,
		passwordConfirmation,
	};
	const userHeaders = await getUserHeaders();
	return Axios.post(APIRoute + APIPath.RESET_PASSWORD, resetModel, userHeaders)
		.then(response => {
			return generateResponse(false, null, response);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function changePassword(currentPassword, newPassword, newPasswordConfirmation) {
	const changeModel = {
		currentPassword,
		newPassword,
		newPasswordConfirmation,
	};
	const userHeaders = await getUserHeaders();
	return Axios.post(APIRoute + APIPath.CHANGE_PASSWORD, changeModel, userHeaders)
		.then(response => {
			return generateResponse(false, null, response);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function addDeviceToken(token) {
	const userHeaders = await getUserHeaders();
	return Axios.post(APIRoute + APIPath.ADD_DEVICE + '?deviceToken=' + token, null, userHeaders)
		.then(() => {
			console.log('Device token updated.');
			return true;
		})
		.catch(reason => {
			console.log('Device token update failed!', reason);
			return false;
		});
}

async function getMOTD() {
	const userHeaders = await getUserHeaders();
	return Axios.get(APIRoute + APIPath.GET_MOTD, userHeaders)
		.then(response => {
			return generateResponse(false, response.data, response);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function seenMOTD() {
	const userHeaders = await getUserHeaders();
	return Axios.get(APIRoute + APIPath.SEEN_MOTD, userHeaders)
		.then(response => {
			return generateResponse(false, response.data, response);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function requestEmailConfirmation() {
	const userHeaders = await getUserHeaders();
	return Axios.post(APIRoute + APIPath.REQUEST_EMAIL_CONFIRMATION, null, userHeaders)
		.then(response => {
			return generateResponse(false, response.data, response);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function checkForEmailConfirmation(userId, code) {
	const data = { userId, code };
	return Axios.post(APIRoute + APIPath.EMAIL_CONFIRMATION, data)
		.then(response => {
			return generateResponse(false, response.data, response);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function requestEmailChange(newEmail, password) {
	const data = { newEmail, password };
	const userHeaders = await getUserHeaders();
	return Axios.post(APIRoute + APIPath.REQUEST_EMAIL_CHANGE, data, userHeaders)
		.then(response => {
			return generateResponse(false, response.data, response);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function confirmEmailChange(id) {
	const data = { id };
	const userHeaders = await getUserHeaders();
	return Axios.post(APIRoute + APIPath.CONFIRM_EMAIL_CHANGE, data, userHeaders)
		.then(response => {
			return generateResponse(false, response.data, response);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function getUserInfo() {
	const userHeaders = await getUserHeaders();
	return Axios.get(APIRoute + APIPath.GET_USER_INFO, userHeaders)
		.then(response => {
			return generateResponse(false, response.data, response);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function getAuthenticatorCode() {
	const userHeaders = await getUserHeaders();
	return Axios.get(APIRoute + APIPath.TWO_FACTOR_GET_KEY, userHeaders)
		.then(response => {
			return generateResponse(false, response.data, response);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function addTwoFactor(secret, token) {
	const addModel = { secret, token };
	const userHeaders = await getUserHeaders();
	return Axios.post(APIRoute + APIPath.TWO_FACTOR_ADD, addModel, userHeaders)
		.then(response => {
			return generateResponse(false, response.data, response);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function removeTwoFactor(token) {
	const userHeaders = await getUserHeaders();
	return Axios.post(APIRoute + APIPath.TWO_FACTOR_REMOVE, { token }, userHeaders)
		.then(response => {
			return generateResponse(false, response.data, response);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function verifyTwoFactor(twoFactorToken) {
	const data = { twoFactorToken };
	const userHeaders = await getUserHeaders();
	return Axios.post(APIRoute + APIPath.VERIFY_TWO_FACTOR, data, userHeaders)
		.then(response => {
			return generateResponse(false, response.data, response);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function refreshTokenCache(token, refreshToken, expires) {
	try {
		await IndexedDB.remove('expires').then(response => {
			if (response.hasError) {
				throw response;
			}
		});
		await IndexedDB.add('expires', expires).then(response => {
			if (response.hasError) {
				throw response;
			}
		});
		await IndexedDB.remove('authToken').then(response => {
			if (response.hasError) {
				throw response;
			}
		});
		await IndexedDB.add('authToken', token).then(response => {
			if (response.hasError) {
				throw response;
			}
		});
		await IndexedDB.remove('refreshToken').then(response => {
			if (response.hasError) {
				throw response;
			}
		});
		await IndexedDB.add('refreshToken', refreshToken).then(response => {
			if (response.hasError) {
				throw response;
			}
		});
		return generateResponse(false, { token, expires });
	} catch (e) {
		return generateResponse(true, e?.data);
	}
}

async function cacheUserData(userData) {
	try {
		const { userName, role, token, refreshToken, expires } = userData;
		await IndexedDB.add('userName', userName).then(response => {
			if (response.hasError) {
				throw response;
			}
		});
		await IndexedDB.add('role', role).then(response => {
			if (response.hasError) {
				throw response;
			}
		});
		await IndexedDB.add('expires', expires).then(response => {
			if (response.hasError) {
				throw response;
			}
		});
		await IndexedDB.add('authToken', token).then(response => {
			if (response.hasError) {
				throw response;
			}
		});
		await IndexedDB.add('refreshToken', refreshToken).then(response => {
			if (response.hasError) {
				throw response;
			}
		});
		return generateResponse(false, userData);
	} catch (e) {
		return generateResponse(true, e?.data);
	}
}

async function fetchCachedUserData() {
	try {
		let userName, role, token, refreshToken, expires;
		await IndexedDB.fetch('userName').then(response => {
			if (response.hasError) {
				throw response;
			}
			userName = response.data?.content;
		});
		await IndexedDB.fetch('role').then(response => {
			if (response.hasError) {
				throw response;
			}
			role = response.data?.content;
		});
		await IndexedDB.fetch('expires').then(response => {
			if (response.hasError) {
				throw response;
			}
			expires = response.data?.content;
		});
		await IndexedDB.fetch('authToken').then(response => {
			if (response.hasError) {
				throw response;
			}
			token = response.data?.content;
		});
		await IndexedDB.fetch('refreshToken').then(response => {
			if (response.hasError) {
				throw response;
			}
			refreshToken = response.data?.content;
		});
		return generateResponse(false, { userName, role, token, refreshToken, expires });
	} catch (e) {
		return generateResponse(true, e?.data);
	}
}

async function clearCachedUserData() {
	try {
		await IndexedDB.remove('userName').then(response => {
			if (response.hasError) {
				throw response;
			}
		});
		await IndexedDB.remove('role').then(response => {
			if (response.hasError) {
				throw response;
			}
		});
		await IndexedDB.remove('expires').then(response => {
			if (response.hasError) {
				throw response;
			}
		});
		await IndexedDB.remove('refreshToken').then(response => {
			if (response.hasError) {
				throw response;
			}
		});
		await IndexedDB.remove('authToken').then(response => {
			if (response.hasError) {
				throw response;
			}
		});
		return generateResponse(false, null);
	} catch (e) {
		return generateResponse(true, e?.data);
	}
}

export default UserController;
