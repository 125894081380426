import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UserController from '../controllers/UserController';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Role } from '../helpers/Constants';
import { PrivateRoute } from '../components/Common/PrivateRoute';
import { Layout } from '../domain/Layout/Layout';
import { LogIn, LogOut } from '../stores/Actions/Authentication';
import { IndexedDB } from '../helpers/IndexedDB';
import { isNullOrUndefined } from '../helpers/Utils';

// Pages
import Home from '../domain/Home/Home';
import Login from '../domain/Auth/Login';
import Logout from '../domain/Auth/Logout';
import Register from '../domain/Auth/Register';
import FileManager from '../domain/User/FileManager';
import Profile from '../domain/User/Profile';
import PasswordChange from '../domain/User/PasswordChange';
import EmailConfirm from '../domain/User/EmailConfirm';
import EmailChange from '../domain/User/EmailChange';
import EmailChangeConfirm from '../domain/User/EmailChangeConfirm';
import PasswordForgot from '../domain/User/PasswordForgot';
import PasswordReset from '../domain/User/PasswordReset';
import TwoFactorManager from '../domain/User/TwoFactorManager';
import TwoFactorVerify from '../domain/User/TwoFactorVerify';
import Admin from '../domain/Admin/Admin';
import NotFound from '../domain/Error/NotFound';
import NotificationList from '../domain/User/NotificationList';
import Routine from '../domain/Routine/Routine';
import MoneyPlan from '../domain/MoneyPlan/MoneyPlan';
import CashForecastGuide from '../domain/Home/CashForecastGuide';
import Variable from '../domain/Variable/Variable';
import Timeline from '../domain/Timeline/Timeline';
import EULA from '../domain/Auth/EULA';
import PrivacyPolicy from '../domain/Auth/Privacy';

class App extends Component {
	constructor(props) {
		super(props);
		this.initTokenStore(props);
	}

	initTokenStore = async props => {
		// initialise local data store
		const init = await IndexedDB.init();
		if (init.hasError) {
			console.warn(init.data);
			return;
		}
		// check if existing token is valid
		if (await UserController.hasTokenExpired()) {
			props.LogOut();
			return;
		}
		// check for existing token
		const userData = await UserController.fetchCachedUserData();
		if (userData.hasError) {
			console.warn(userData.data);
			props.LogOut();
			return;
		}
		// save cached data if any
		const { userName, role } = userData.data;
		if (!isNullOrUndefined(userName)) {
			props.LogIn({
				userName,
				role,
				isLoggingIn: false,
				isAuthenticated: true,
			});
		} else {
			props.LogOut();
		}
	};

	render() {
		if (this.props.Auth.isLoggingIn) {
			return <div />;
		}
		return (
			<Layout>
				<Switch>
					<Route exact path="/" component={Home} />
					<Route exact path="/CashForecastGuide" component={CashForecastGuide} />
					<Route path="/Login" component={Login} />
					<Route path="/Logout" component={Logout} />
					<Route path="/Register" component={Register} />
					<Route path="/Privacy" component={PrivacyPolicy} />
					<Route path="/EULA" component={EULA} />
					<Route path="/Routine" component={Routine} />
					{/*Left in to not cause errors */}
					<Route path="/MoneyPlan" component={MoneyPlan} />
					<Route path="/BudgetPlan" component={MoneyPlan} />
					<Route path="/Variable" component={Variable} />
					<Route path="/CashForecast" component={Timeline} />
					<Route path="/Notifications" component={NotificationList} />
					<Route path="/FileManagement" component={FileManager} />
					<Route path="/UserManagement" component={Profile} />
					<Route path="/ChangePassword" component={PasswordChange} />
					<Route path="/EmailConfirmation" component={EmailConfirm} />
					<Route path="/ChangeEmail" component={EmailChange} />
					<Route path="/ChangeEmailConfirmation" component={EmailChangeConfirm} />
					<Route path="/ForgotPassword" component={PasswordForgot} />
					<Route path="/ResetPassword" component={PasswordReset} />
					<Route path="/ManageTwoFactor" component={TwoFactorManager} />
					<Route path="/VerifyTwoFactor" component={TwoFactorVerify} />
					<PrivateRoute path="/Admin" role={this.props.Auth.role} roles={[Role.Admin]} component={Admin} />
					<Route path="" component={NotFound} />
				</Switch>
			</Layout>
		);
	}
}

const mapStateToProps = state => ({
	Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
	LogIn: data => dispatch(LogIn(data)),
	LogOut: () => dispatch(LogOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

App.propTypes = {
	Auth: PropTypes.object,
	LogIn: PropTypes.func,
	LogOut: PropTypes.func,
};
