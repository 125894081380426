import React from 'react';
import PropTypes from 'prop-types';
import {
	makeStyles,
	Container,
	Typography,
	Grid,
	Button,
	Card,
	ListItem,
	ListItemAvatar,
	ListItemText,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Colours } from '../../helpers/Colours';
import { push } from 'connected-react-router';
import { ItemIcon } from '../../components/Item/ItemIcon';
import { ItemIcons } from '../../helpers/ItemIconLib';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';

const useStyles = makeStyles(theme => ({
	section: {
		padding: `60px ${theme.spacing(2)}px`,
		'& p, h6': {
			color: Colours.bodyText,
		},
		'&.grey-bg': {
			backgroundColor: Colours.bg_grey_1,
		},
		'& h2': {
			fontSize: 50,
			[theme.breakpoints.down('xs')]: {
				fontSize: 36,
			},
		},
	},
	helpCard: {
		borderRadius: 0,
		padding: 16,
		backgroundColor: Colours.primary25,
		marginBottom: 16,
	},
	sampleIcon: {
		color: Colours.bodyText,
		padding: 0,
		marginBottom: 16,
		'&:last-child': {
			marginBottom: 0,
		},
	},
}));

function CashForecastGuide(props) {
	const { Auth, PushHistory } = props;
	const [loading, setLoading] = React.useState(false);
	const classes = useStyles();

	// check user is authed
	React.useEffect(() => {
		if (!Auth.isAuthenticated) {
			PushHistory('/');
		}
	}, [Auth, PushHistory]);

	async function handleStartForecasting() {
		setLoading(true);
		PushHistory('/CashForecast');
	}

	function buildIconSample(icon, colour, name) {
		return (
			<ListItem className={classes.sampleIcon}>
				<ListItemAvatar>
					<ItemIcon itemName={name} iconName={icon} iconBGColour={colour} />
				</ListItemAvatar>
				<ListItemText primary={name} />
			</ListItem>
		);
	}

	return (
		<>
			<LoadingOverlay loading={loading} />

			{/* STEP 1 */}
			<div className={classes.section}>
				<Container maxWidth="sm">
					<Grid container spacing={4}>
						<Grid item xs={12}>
							<Typography variant="h2">
								<b>Step 1</b> Routine
							</Typography>
							<Typography variant="subtitle2" gutterBottom>
								(You only do this once)
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body1" paragraph>
								1. Choose a Category - Money In / Basics
							</Typography>
							<Card variant="outlined" className={classes.helpCard}>
								<Typography variant="body2" paragraph>
									<b>Money In</b> - Wages or benefits
								</Typography>
								<Typography variant="body2">
									<b>Basics</b> - Spending on basic items or paying off loans
								</Typography>
							</Card>
							<Typography variant="body1" paragraph>
								2. Choose icons for all your routine items of income and payments – things you do every
								week or every month.
							</Typography>
							<Typography variant="body1" paragraph>
								<em>Tip – look on your bank statement for regular direct deposits and direct debits</em>
							</Typography>
							<Card variant="outlined" className={classes.helpCard}>
								<Typography variant="body2" paragraph>
									<b>Pick icon from Money In menu - e.g.</b>
								</Typography>
								{buildIconSample(ItemIcons.MI_WAGES.name, 'green', 'Wages')}
								<Typography variant="body2" paragraph>
									<b>Pick icon from Basics menu - e.g.</b>
								</Typography>
								{buildIconSample(ItemIcons.BA_RENT.name, 'red', 'Rent')}
								{buildIconSample(ItemIcons.BA_ELECTRIC.name, '#0090FF', 'Electric')}
							</Card>
							<Typography variant="body1" paragraph>
								3. Choose the value of the item, e.g. £200.00
							</Typography>
							<Typography variant="body1" paragraph>
								4. Choose when it repeats, e.g. Weekly, Monthly...
							</Typography>
							<Typography variant="body1" paragraph>
								5. Choose when it starts
							</Typography>
							<Typography variant="body1" paragraph>
								6. Choose when it ends or if it carries on
							</Typography>
							<Typography variant="body1">
								7. Choose the account you will use - bank or credit card
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</div>

			{/* STEP 2 */}
			<div className={`${classes.section} grey-bg`}>
				<Container maxWidth="sm">
					<Grid container spacing={4}>
						<Grid item xs={12}>
							<Typography variant="h2">
								<b>Step 2</b> Variable
							</Typography>
							<Typography variant="subtitle2" gutterBottom>
								(You only do this once)
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body1" paragraph>
								1. Choose a Category - Money In / Basics / Credit Card Bill / Choices
							</Typography>
							<Card variant="outlined" className={classes.helpCard}>
								<Typography variant="body2" paragraph>
									<b>Money In</b> - Occasional income - sale of computer games?
								</Typography>
								<Typography variant="body2" paragraph>
									<b>Basics</b> - Occasional essential expenditure - new washing machine?
								</Typography>
								<Typography variant="body2" paragraph>
									<b>Credit Card Bill</b> - Paying off monthly credit card balance
								</Typography>
								<Typography variant="body2">
									<b>Choices</b> - Spending on treats, leisure and entertainment
								</Typography>
							</Card>
							<Typography variant="body1" paragraph>
								2. Choose icons for all your variable items
							</Typography>
							<Card variant="outlined" className={classes.helpCard}>
								<Typography variant="body2" paragraph>
									<b>Pick icon from Choices menu - e.g.</b>
								</Typography>
								{buildIconSample(ItemIcons.CH_NIGHT_OUT.name, 'black', 'Night Out')}
								{buildIconSample(ItemIcons.CH_TREATS.name, 'orange', 'Supermarket Treats')}
								{buildIconSample(ItemIcons.CH_TAKEAWAY.name, 'lime', 'Takeaway')}
							</Card>
						</Grid>
					</Grid>
				</Container>
			</div>

			{/* STEP 3 */}
			<div className={classes.section}>
				<Container maxWidth="sm">
					<Grid container spacing={4}>
						<Grid item xs={12}>
							<Typography variant="h2">
								<b>Step 3</b> Starting Balances
							</Typography>
							<Typography variant="subtitle2" gutterBottom>
								(You do this for each new forecast)
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body1" paragraph>
								1. Input the date of the start of your new forecast
							</Typography>
							<Typography variant="body1" paragraph>
								2. Input your Bank Account balance (see your latest statement)
							</Typography>
							<Typography variant="body1" paragraph>
								3. Input your Credit Card balance (see your latest statement)
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</div>

			{/* STEP 4 */}
			<div className={`${classes.section} grey-bg`}>
				<Container maxWidth="sm">
					<Grid container spacing={4}>
						<Grid item xs={12}>
							<Typography variant="h2">
								<b>Step 4</b> Add, Drag, and Drop Your Variable Items
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body1" paragraph>
								1. Choose an account – Bank or Credit Card
							</Typography>
							<Typography variant="body1" paragraph>
								2. Click a &apos;+&apos; button on the timeline to add variable items to a day
							</Typography>
							<Typography variant="body1" paragraph>
								3. Move items on the timeline by dragging and dropping the icon between days on the
								timeline
							</Typography>
							<Typography variant="body1" paragraph>
								4. Continue until you have done all items
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</div>

			{/* STEP 5 */}
			<div className={classes.section}>
				<Container maxWidth="sm">
					<Grid container spacing={4}>
						<Grid item xs={12}>
							<Typography variant="h2">
								<b>Step 5</b> Check Your New Forecast
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body1" paragraph>
								Does the forecast look reasonable?
							</Typography>
							<Typography variant="body1" paragraph>
								Have you included all items – income and payments?
							</Typography>
							<Typography variant="body1" paragraph>
								Have you accidently duplicated any items?
							</Typography>
							<Typography variant="body1" paragraph>
								Are the values and dates right?
							</Typography>
							<Typography variant="body1" paragraph>
								Is your bank balance positive for each day of the forecast?
							</Typography>
							<Typography variant="body1" paragraph>
								Does your credit card balance stay within your credit limit?
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body1" paragraph>
								Make any changes that will improve your bank balance.
							</Typography>
							<Typography variant="body1" paragraph>
								Enjoy life and stay positive!
							</Typography>
							<Card variant="outlined" className={classes.helpCard}>
								<Typography variant="body2" paragraph>
									<b>Tips to improve your bank balance</b>
								</Typography>
								<Typography variant="body2" paragraph>
									1. Increase income, if you can
								</Typography>
								<Typography variant="body2" paragraph>
									2. Buy less
								</Typography>
								<Typography variant="body2" paragraph>
									3. Buy at a later date
								</Typography>
								<Typography variant="body2">4. Shop for a better deal</Typography>
							</Card>
							<Card variant="outlined" className={classes.helpCard}>
								<Typography variant="body2" paragraph>
									<b>Tips to manage your credit card successfully</b>
								</Typography>
								<Typography variant="body2" paragraph>
									1. Pay off your monthly balance on time and in full
								</Typography>
								<Typography variant="body2">
									2. Remember what you buy this month will be due for payment next month
								</Typography>
							</Card>
							<Button color="primary" variant="contained" onClick={() => handleStartForecasting()}>
								Start Forecasting
							</Button>
						</Grid>
					</Grid>
				</Container>
			</div>
		</>
	);
}

const mapStateToProps = state => ({
	Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
	PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CashForecastGuide);

CashForecastGuide.propTypes = {
	Auth: PropTypes.object,
	PushHistory: PropTypes.func,
};
