import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, makeStyles } from '@material-ui/core';
import { findIcon } from '../../helpers/ItemIconLib';
import { getIconFontSize, getIconName, isNullOrUndefined } from '../../helpers/Utils';

const useStyles = makeStyles(() => ({
	root: props => ({
		margin: props.size ? 0 : 'auto',
		height: props.size ?? 40,
		width: props.size ?? 40,
		'& img': {
			pointerEvents: 'none',
			objectFit: 'contain',
			objectPosition: 'center',
			height: '70%',
			width: '70%',
		},
	}),
}));

export function ItemIcon(props) {
	const { itemName, iconName, iconBGColour, size } = props;

	const classes = useStyles({ size });

	function buildIcon() {
		const icon = findIcon(iconName);

		if (isNullOrUndefined(icon?.icon)) {
			const name = getIconName(itemName);
			const fontSize = getIconFontSize(name);
			const realFontSize = size ? fontSize - (40 - size) / 4 : fontSize;

			return <span style={{ fontSize: realFontSize }}>{name}</span>;
		}
		return <img src={icon.icon} alt={icon.tag} />;
	}

	return (
		<Avatar className={classes.root} style={{ backgroundColor: iconBGColour }}>
			{buildIcon()}
		</Avatar>
	);
}

ItemIcon.propTypes = {
	itemName: PropTypes.string.isRequired,
	iconName: PropTypes.string,
	iconBGColour: PropTypes.string,
	size: PropTypes.number,
};
