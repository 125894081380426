import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Backdrop, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	backdrop: {
		zIndex: 10,
	},
}));

export function LoadingOverlay(props) {
	const classes = useStyles();
	return (
		<Backdrop open={props.loading} className={classes.backdrop}>
			<CircularProgress color="secondary" />
		</Backdrop>
	);
}

LoadingOverlay.propTypes = {
	loading: PropTypes.bool.isRequired,
};
