import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { Alert } from '../../components/Common/Alert';
import UserController from '../../controllers/UserController';
import { checkPassword, isNullOrUndefined } from '../../helpers/Utils';
import { Button, Container, TextField, Typography, Grid, makeStyles } from '@material-ui/core';
import { AuthLayout } from '../../components/Layout/AuthLayout';
import logoImg from '../../images/logo.svg';
import { TorchbearerLogo } from '../../components/Torchbearer/TorchbearerLogo';

const useStyles = makeStyles(() => ({
	fsLogo: {
		maxWidth: '100%',
		padding: 32,
	},
}));

function PasswordReset(props) {
	const classes = useStyles();
	const [password, setPassword] = React.useState('');
	const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
	const [warningText, setWarningText] = React.useState(null);
	const [passwordRequirements, setPasswordRequirements] = React.useState(null);
	const [passwordError, setPasswordError] = React.useState(null);
	const [redirectUrl, setRedirectUrl] = React.useState(null);
	const [submitting, setSubmitting] = React.useState(false);
	const values = queryString.parse(props.location.search);

	// initialise
	React.useEffect(() => {
		async function init() {
			if (props.Auth.isAuthenticated) {
				props.PushHistory('/');
			}
			const pwordReqResponse = await UserController.getPasswordRequirements();
			if (!pwordReqResponse.hasError) {
				setPasswordRequirements(pwordReqResponse.data);
			} else {
				setWarningText('Failed to fetch password requirements');
			}
		}
		init();
	}, [props]);

	// redirect
	React.useEffect(() => {
		if (isNullOrUndefined(redirectUrl)) {
			return;
		}
		props.PushHistory(redirectUrl);
	}, [redirectUrl, props]);

	async function handleSubmit(event) {
		event.preventDefault();
		setSubmitting(true);
		setWarningText(null);

		const { userId, emailCode } = values;
		const response = await UserController.resetPassword(userId, emailCode, password, passwordConfirmation);
		if (response.hasError) {
			setWarningText(response.data);
			setSubmitting(false);
		} else {
			setRedirectUrl('/Login');
		}
	}

	function handleInput(event) {
		const { name, value } = event.target;
		switch (name) {
			case 'password':
				setPassword(value);
				break;
			case 'passwordConfirmation':
				setPasswordConfirmation(value);
				break;
			default:
				break;
		}
	}

	async function checkPasswordIsValid() {
		setPasswordError(checkPassword(passwordRequirements, password));
	}

	function buildContent() {
		return (
			<Container maxWidth="xs">
				<form onSubmit={handleSubmit}>
					<Typography align="left" variant="h2" gutterBottom>
						Reset Password
					</Typography>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<TextField
								id="password-input"
								label="New Password"
								type="password"
								value={password}
								onChange={handleInput}
								variant="filled"
								name="password"
								onBlur={() => checkPasswordIsValid()}
								helperText={passwordError}
								fullWidth
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="confirm-password-input"
								label="Confirm Password"
								type="password"
								value={passwordConfirmation}
								onChange={handleInput}
								variant="filled"
								name="passwordConfirmation"
								fullWidth
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<Alert header="Something went wrong!" text={warningText} />
						</Grid>
						<Grid item xs={12}>
							<Button fullWidth disabled={submitting} color="primary" type="submit" variant="contained">
								Submit
							</Button>
						</Grid>
					</Grid>
				</form>
			</Container>
		);
	}

	return (
		<AuthLayout
			leftChild={
				<>
					<img className={classes.fsLogo} src={logoImg} alt="Logo" />
					<TorchbearerLogo />
				</>
			}
			rightChild={buildContent()}
		/>
	);
}

const mapStateToProps = state => ({
	Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
	PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);

PasswordReset.propTypes = {
	Auth: PropTypes.object,
	PushHistory: PropTypes.func,
	location: PropTypes.object,
};
