import React from 'react';
import PropTypes from 'prop-types';
import UserController from '../../controllers/UserController';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Button, Grid, Container, Typography, TextField, makeStyles } from '@material-ui/core';
import { Alert } from '../../components/Common/Alert';
import { Link } from 'react-router-dom';
import { AuthLayout } from '../../components/Layout/AuthLayout';
import logoImg from '../../images/logo.svg';
import { TorchbearerLogo } from '../../components/Torchbearer/TorchbearerLogo';

const useStyles = makeStyles(() => ({
	fsLogo: {
		maxWidth: '100%',
		padding: 32,
	},
}));

function PasswordForgot(props) {
	const classes = useStyles();
	const [email, setEmail] = React.useState('');
	const [warningText, setWarningText] = React.useState(null);
	const [emailSent, setEmailSent] = React.useState(false);
	const [submitting, setSubmitting] = React.useState(false);

	// initialise
	React.useEffect(() => {
		if (props.Auth.isAuthenticated) {
			props.PushHistory('/Login');
		}
	}, [props]);

	async function handleSubmit(event) {
		event.preventDefault();
		setWarningText(null);
		setSubmitting(true);
		const response = await UserController.forgotPassword(email);
		if (response.hasError) {
			setWarningText(response.data);
		} else {
			setEmailSent(true);
		}
		setSubmitting(false);
	}

	function handleInput(event) {
		const value = event.target.value;
		setEmail(value);
	}

	function buildEmailForm() {
		return (
			<form onSubmit={handleSubmit}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TextField
							name="email"
							label="Enter Email"
							placeholder="example@email.com"
							variant="filled"
							margin="normal"
							onChange={handleInput}
							value={email}
							type="text"
							fullWidth
							required
						/>
					</Grid>
					<Grid item xs={12}>
						<Alert header="Something went wrong!" text={warningText} />
					</Grid>
					<Grid item xs={6}>
						<Button disabled={submitting} variant="contained" type="submit" color="primary" fullWidth>
							Submit
						</Button>
					</Grid>
					<Grid item xs={6}>
						<Button component={Link} to="/Login" variant="outlined" color="primary" fullWidth>
							Sign In
						</Button>
					</Grid>
				</Grid>
			</form>
		);
	}

	function buildSuccessContent() {
		return (
			<Grid container spacing={3} style={{ marginTop: 20 }}>
				<Grid item xs={12}>
					<Typography variant="subtitle1" align="left" paragraph>
						An email has been sent to your account containing a reset password link
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Button component={Link} to="/Login" variant="contained" color="primary" fullWidth>
						Back to Login
					</Button>
				</Grid>
			</Grid>
		);
	}

	function buildContent() {
		return (
			<Container maxWidth="xs">
				<Typography variant="h2" align="left" gutterBottom>
					Forgot Password
				</Typography>
				{!emailSent ? buildEmailForm() : buildSuccessContent()}
			</Container>
		);
	}

	return (
		<AuthLayout
			leftChild={
				<>
					<img className={classes.fsLogo} src={logoImg} alt="Logo" />
					<TorchbearerLogo />
				</>
			}
			rightChild={buildContent()}
		/>
	);
}

const mapStateToProps = state => ({
	Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
	PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForgot);

PasswordForgot.propTypes = {
	Auth: PropTypes.object,
	PushHistory: PropTypes.func,
};
