import React from 'react';
import PropTypes from 'prop-types';
import UserController from '../../controllers/UserController';
import querystring from 'query-string';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '../../components/Common/Alert';
import { checkPassword, isNullOrUndefined } from '../../helpers/Utils';
import { Button, Container, Grid, Typography, TextField, FormControlLabel, Checkbox, Divider } from '@material-ui/core';
import { LogIn } from '../../stores/Actions/Authentication';
import { AuthLayout } from '../../components/Layout/AuthLayout';
import { Colours } from '../../helpers/Colours';
import { Link } from 'react-router-dom';
import logoImg from '../../images/logo.svg';
import { TorchbearerLogo } from '../../components/Torchbearer/TorchbearerLogo';

const useStyles = makeStyles(() => ({
	checkboxWrapper: {
		'& a': {
			color: Colours.primary,
			textDecoration: 'none',
			'&:hover': {
				textDecoration: 'underline',
			},
		},
	},
	fsLogo: {
		maxWidth: '100%',
		padding: 32,
	},
}));

function Register(props) {
	const { redirect } = querystring.parse(window.location.search);
	const [email, setEmail] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
	const [acceptsTerms, setAcceptsTerms] = React.useState(false);
	const [submitting, setSubmitting] = React.useState(false);
	const [warningText, setWarningText] = React.useState(null);
	const [passwordRequirements, setPasswordRequirements] = React.useState(null);
	const [passwordError, setPasswordError] = React.useState(null);
	const [redirectUrl, setRedirectUrl] = React.useState(null);
	const classes = useStyles();

	// initialise
	React.useEffect(() => {
		async function init() {
			const pwordReqResponse = await UserController.getPasswordRequirements();
			if (!pwordReqResponse.hasError) {
				setPasswordRequirements(pwordReqResponse.data);
			} else {
				setWarningText('Failed to fetch password requirements');
			}
		}
		init();
	}, []);

	// redirect
	React.useEffect(() => {
		if (isNullOrUndefined(redirectUrl)) {
			return;
		}
		props.PushHistory(redirectUrl);
	}, [redirectUrl, props]);

	async function handleSubmit(event) {
		event.preventDefault();
		setSubmitting(true);
		setWarningText(null);

		if (!acceptsTerms) {
			setWarningText('You must confirm you have read and agree to the Terms of Service and Privacy Policy');
			setSubmitting(false);
			return;
		}

		if (password !== passwordConfirmation) {
			setWarningText('The two passwords do not match');
			setSubmitting(false);
			return;
		}

		const response = await UserController.register(email, password, passwordConfirmation, false);
		if (!response.hasError) {
			const { userName, role } = response.data;
			login(userName, role);
		} else {
			setSubmitting(false);
			setWarningText(response.data);
		}
	}

	function handleInput(event) {
		const { name, value, checked } = event.target;
		switch (name) {
			case 'email':
				setEmail(value);
				break;
			case 'password':
				setPassword(value);
				break;
			case 'passwordConfirmation':
				setPasswordConfirmation(value);
				break;
			case 'acceptsTerms':
				setAcceptsTerms(checked);
				break;
			default:
				return;
		}
	}

	function login(userName, role) {
		props.LogIn({
			userName,
			role,
			isLoggingIn: false,
			isAuthenticated: true,
		});
		if (!isNullOrUndefined(redirect)) {
			setRedirectUrl(`/${redirect}`);
		} else {
			setRedirectUrl('/');
		}
	}

	async function checkPasswordIsValid() {
		setPasswordError(checkPassword(passwordRequirements, password));
	}

	function buildRegisterForm() {
		return (
			<form onSubmit={handleSubmit}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TextField
							id="email-input"
							label="Email"
							type="email"
							variant="filled"
							required
							value={email}
							onChange={handleInput}
							name="email"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="password-input"
							label="Password"
							type="password"
							variant="filled"
							required
							helperText={passwordError}
							value={password}
							onChange={handleInput}
							onBlur={() => checkPasswordIsValid()}
							name="password"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="confirm-password-input"
							label="Confirm Password"
							type="password"
							variant="filled"
							required
							value={passwordConfirmation}
							onChange={handleInput}
							name="passwordConfirmation"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} className={classes.checkboxWrapper}>
						<FormControlLabel
							control={
								<Checkbox
									checked={acceptsTerms}
									onChange={handleInput}
									name="acceptsTerms"
									color="primary"
								/>
							}
							label={
								<span>
									I have read and agree to the{' '}
									<a href="/EULA" target="_blank">
										Terms of Service
									</a>{' '}
									and{' '}
									<a href="/Privacy" target="_blank">
										Privacy Policy
									</a>
								</span>
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<Alert header="Something went wrong!" text={warningText} />
					</Grid>
					<Grid item xs={12}>
						<Button type="submit" disabled={submitting} fullWidth color="primary" variant="contained">
							Register
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<Button
							component={Link}
							to={!isNullOrUndefined(redirect) ? `/Login?redirect=${redirect}` : '/Login'}
							fullWidth
							variant="outlined"
							color="primary"
						>
							Sign In To Existing Account
						</Button>
					</Grid>
				</Grid>
			</form>
		);
	}

	function buildContent() {
		return (
			<Container maxWidth="xs">
				<Typography align="left" variant="h2" gutterBottom>
					Create Account
				</Typography>
				{buildRegisterForm()}
			</Container>
		);
	}

	return (
		<AuthLayout
			leftChild={
				<>
					<img className={classes.fsLogo} src={logoImg} alt="Logo" />
					<TorchbearerLogo />
				</>
			}
			rightChild={buildContent()}
		/>
	);
}

const mapStateToProps = state => ({
	Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
	PushHistory: data => dispatch(push(data)),
	LogIn: data => dispatch(LogIn(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);

Register.propTypes = {
	Auth: PropTypes.object,
	PushHistory: PropTypes.func,
	LogIn: PropTypes.func,
};
