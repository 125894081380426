import React from 'react';
import PropTypes from 'prop-types';
import currency from 'currency.js';
import { Autocomplete } from '@material-ui/lab';
import {
	makeStyles,
	Dialog,
	DialogTitle,
	Divider,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	Grid,
	InputAdornment,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import { ItemIconField } from '../../components/Item/ItemIconField';
import { getCategoryColour, isNullOrUndefined } from '../../helpers/Utils';
import { Alert } from '../../components/Common/Alert';
import { VariableItemPresets } from '../../helpers/ItemPresetLib';
import VariableController from '../../controllers/VariableController';
import { ItemIcons } from '../../helpers/ItemIconLib';
import { Category, ItemModifierType } from '../../helpers/Constants';

const useStyles = makeStyles(theme => ({
	numberInput: {
		'& input': {
			Appearance: 'textfield',
		},
		'& input::-webkit-inner-spin-button, input::-webkit-outer-spin-button': {
			WebkitAppearance: 'none',
			margin: 0,
		},
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
	},
}));

export function VariableAddEdit(props) {
	const { categories, variable, open, date, onAdd, onClose } = props;
	const [name, setName] = React.useState('');
	const [category, setCategory] = React.useState('');
	const [iconName, setIconName] = React.useState('default');
	const [amount, setAmount] = React.useState('');
	const [warningText, setWarningText] = React.useState(null);
	const [submitting, setSubmitting] = React.useState(false);
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('xs'));

	// update with new routine data
	React.useEffect(() => {
		setName(variable?.name ?? '');
		setCategory(variable?.categoryId ?? '');
		setIconName(variable?.iconName ?? 'default');
		setAmount(variable?.amount ?? '');
	}, [variable]);

	const categoryColour = () => getCategoryColour(categories?.find(e => e.id === category)?.name ?? Category.Basics);

	function closeAndClearData(newRoutine = null) {
		setName('');
		setCategory('');
		setIconName('default');
		setAmount('');
		setSubmitting(false);
		onClose(newRoutine);
	}

	async function handleSubmit(event) {
		event.preventDefault();
		setWarningText(null);
		setSubmitting(true);

		const adding = isNullOrUndefined(variable?.id);
		const response = adding
			? await VariableController.addVariable(
					name,
					iconName,
					categoryColour(),
					currency(amount).toJSON(),
					category
			  )
			: await VariableController.editVariable(
					variable.id,
					name,
					iconName,
					categoryColour(),
					currency(amount).toJSON(),
					category
			  );
		if (response.hasError) {
			setWarningText(response.data);
			setSubmitting(false);
			return;
		} else if (adding) {
			onAdd(ItemModifierType.Instance, { variable: response.data, date });
		}

		const occasionInstances = variable.occasionInstances ?? [];
		closeAndClearData({ ...response.data, occasionInstances });
	}

	function handleInput(event) {
		const { name, value } = event.target;
		switch (name) {
			case 'name':
				setName(value?.name ?? value);
				//handleUpdateAutofillName(value);
				break;
			case 'category':
				if (category !== value) {
					setIconName(ItemIcons.DEFAULT.name);
				}
				setCategory(value);
				break;
			case 'amount':
				setAmount(
					value.length > 0
						? currency(value.toString().replace(/-/g, ''), {
								symbol: '',
								precision: 0,
								separator: ',',
						  }).format()
						: value
				);
				break;
			default:
				return;
		}
	}

	function handleUpdateAutofillName(value) {
		if (isNullOrUndefined(value)) {
			return;
		}

		const { category, iconName } = value;
		if (!isNullOrUndefined(category)) {
			setCategory(categories.filter(e => e.name === category)[0]?.id);
		}
		if (!isNullOrUndefined(iconName)) {
			setIconName(iconName);
		}
	}

	function handleIconUpdate(name) {
		setIconName(name);
	}

	return (
		<Dialog
			PaperComponent="form"
			PaperProps={{ className: classes.paper }}
			onSubmit={handleSubmit}
			open={open}
			fullScreen={matches}
		>
			<DialogTitle>Add/Edit Variable Item</DialogTitle>
			<Divider />
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<Autocomplete
							options={VariableItemPresets}
							name="name"
							value={name}
							onChange={(_, value) => handleInput({ target: { name: 'name', value } })}
							onInputChange={(_, value) => handleInput({ target: { name: 'name', value } })}
							getOptionLabel={e => e?.name ?? name}
							renderOption={e => e.name}
							renderInput={params => (
								<TextField
									{...params}
									label="Item Name"
									variant="filled"
									name="school"
									inputProps={{
										...params.inputProps,
										autoComplete: 'new-password', // disable autocomplete and autofill
									}}
									required
									fullWidth
								/>
							)}
							autoHighlight
							fullWidth
							freeSolo
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<TextField
							variant="filled"
							label="Value"
							value={amount}
							className={classes.numberInput}
							name="amount"
							onChange={handleInput}
							fullWidth
							InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}
							required
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControl variant="filled" fullWidth required>
							<InputLabel id="category-label">Category</InputLabel>
							<Select
								fullWidth
								labelId="category-label"
								value={category}
								name="category"
								onChange={handleInput}
							>
								{categories.map(cat => (
									<MenuItem key={cat.id} value={cat.id}>
										{cat.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<ItemIconField
							category={categories.filter(e => e.id === category)[0]?.name ?? ''}
							iconName={iconName ?? 'default'}
							iconBGColour={categoryColour()}
							itemName={name ?? ''}
							itemValue={currency(amount, { symbol: '£', precision: 0, separator: ',' }).format()}
							onChange={handleIconUpdate}
						/>
					</Grid>
					<Grid item xs={12}>
						<Alert header="Something went wrong!" text={warningText} />
					</Grid>
				</Grid>
			</DialogContent>
			<Divider />
			<DialogActions>
				<Button color="primary" onClick={() => closeAndClearData()}>
					Cancel
				</Button>
				<Button color="primary" type="submit" disabled={submitting}>
					{`${submitting ? 'Saving' : 'Save'}`}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

VariableAddEdit.propTypes = {
	categories: PropTypes.array.isRequired,
	variable: PropTypes.object,
	date: PropTypes.object,
	open: PropTypes.bool.isRequired,
	onAdd: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};
