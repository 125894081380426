import React from 'react';
import TBLogo from '../../images/tb-logo.svg';
import { makeStyles, Typography } from '@material-ui/core';
import { Colours } from '../../helpers/Colours';

const useStyles = makeStyles(() => ({
	root: {
		position: 'relative',
		maxWidth: '100%',
		width: 160,
		margin: 40,
		'& object': {
			maxWidth: '100%',
			pointerEvents: 'none',
		},
		'& .MuiTypography-root': {
			position: 'absolute',
			color: Colours.bodyText,
			fontSize: 10,
			top: 0,
			right: 0,
		},
	},
}));

export function TorchbearerLogo() {
	const classes = useStyles();
	return (
		<a className={classes.root} href="https://torchbearer.dev" target="_blank" rel="noopener noreferrer">
			<Typography variant="caption">Powered by</Typography>
			<object type="image/svg+xml" data={TBLogo}>
				Torchbearer
			</object>
		</a>
	);
}
