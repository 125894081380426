import React from 'react';
import logoImg from '../../images/logo.svg';
import querystring from 'query-string';
import PropTypes from 'prop-types';
import UserController from '../../controllers/UserController';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { TextField, Button, Grid, Container, Typography, makeStyles, Divider } from '@material-ui/core';
import { isNullOrUndefined, checkPassword } from '../../helpers/Utils';
import { Alert } from '../../components/Common/Alert';
import { LogIn } from '../../stores/Actions/Authentication';
import { AuthLayout } from '../../components/Layout/AuthLayout';
import { TorchbearerLogo } from '../../components/Torchbearer/TorchbearerLogo';

const useStyles = makeStyles(() => ({
	checkboxWrapper: {
		textAlign: 'center',
	},
	forgotPassword: {
		textTransform: 'none',
	},
	fsLogo: {
		maxWidth: '100%',
		padding: 32,
	},
}));

function Login(props) {
	const classes = useStyles();
	const { redirect } = querystring.parse(window.location.search);
	const [email, setEmail] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [twoFactorTokenRequired, setTwoFactorTokenRequired] = React.useState(false);
	const [twoFactorToken, setTwoFactorToken] = React.useState('');
	const [passwordResetRequired, setPasswordResetRequired] = React.useState(false);
	const [passwordReset, setPasswordReset] = React.useState('');
	const [passwordResetConfirmation, setPasswordResetConfirmation] = React.useState(null);
	const [passwordRequirements, setPasswordRequirements] = React.useState(null);
	const [passwordError, setPasswordError] = React.useState(null);
	const [submitting, setSubmitting] = React.useState(false);
	const [redirectUrl, setRedirectUrl] = React.useState(null);
	const [warningText, setWarningText] = React.useState(null);

	// initialise
	React.useEffect(() => {
		async function init() {
			const pwordReqResponse = await UserController.getPasswordRequirements();
			if (!pwordReqResponse.hasError) {
				setPasswordRequirements(pwordReqResponse.data);
			} else {
				setWarningText('Failed to fetch password requirements');
			}
		}
		init();
	}, []);

	// redirect
	React.useEffect(() => {
		if (isNullOrUndefined(redirectUrl)) {
			return;
		}
		props.PushHistory(redirectUrl);
	}, [redirectUrl, props]);

	async function handleSubmit(event) {
		event.preventDefault();
		setSubmitting(true);
		setWarningText(null);

		const response = passwordResetRequired
			? await UserController.loginWithPasswordReset(
					email,
					password,
					false,
					twoFactorToken,
					passwordReset,
					passwordResetConfirmation
			  )
			: await UserController.login(email, password, false, twoFactorToken);
		if (!response.hasError) {
			const { userName, role } = response.data;
			login(userName, role);
		} else {
			if (response.data?.response?.data === 'TwoFactorRequired') {
				setTwoFactorTokenRequired(true);
			} else if (response.data?.response?.data === 'PasswordResetRequired') {
				setPasswordResetRequired(true);
			} else {
				setWarningText(response.data);
			}
			setSubmitting(false);
		}
	}

	function handleInput(event) {
		const { name, value } = event.target;
		switch (name) {
			case 'email':
				setEmail(value);
				break;
			case 'password':
				setPassword(value);
				break;
			case 'twoFactorToken':
				setTwoFactorToken(value);
				break;
			case 'newPassword':
				setPasswordReset(value);
				break;
			case 'newPasswordConfirmation':
				setPasswordResetConfirmation(value);
				break;
			default:
				return;
		}
	}

	function login(userName, role) {
		props.LogIn({
			userName,
			role,
			isLoggingIn: false,
			isAuthenticated: true,
		});
		if (!isNullOrUndefined(redirect)) {
			setRedirectUrl(`/${redirect}`);
		} else {
			setRedirectUrl('/');
		}
	}

	async function checkPasswordIsValid() {
		setPasswordError(checkPassword(passwordRequirements, password));
	}

	function buildTwoFactorTextField() {
		return twoFactorTokenRequired ? (
			<Grid item xs={12}>
				<TextField
					id="twoFactorToken-input"
					label="twoFactorToken"
					type="twoFactorToken"
					required
					defaultValue={twoFactorToken}
					onChange={handleInput}
					name="twoFactorToken"
					fullWidth
				/>
			</Grid>
		) : null;
	}

	function buildPasswordResetForm() {
		return (
			<>
				<Grid item xs={12}>
					<TextField
						id="newPassword-input"
						label="New Password"
						type="password"
						required
						value={passwordReset}
						onChange={handleInput}
						name="newPassword"
						fullWidth
						helperText={passwordError}
						onBlur={() => checkPasswordIsValid()}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="newPasswordConfirmation-input"
						label="New Password Confirmation"
						type="password"
						required
						value={passwordResetConfirmation}
						onChange={handleInput}
						name="newPasswordConfirmation"
						fullWidth
					/>
				</Grid>
				{buildTwoFactorTextField()}
			</>
		);
	}

	function buildLoginForm() {
		return (
			<>
				<Grid item xs={12}>
					<TextField
						id="email-input"
						label="Email"
						type="text"
						variant="filled"
						required
						value={email}
						onChange={handleInput}
						name="email"
						fullWidth
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="password-input"
						label="Password"
						type="password"
						variant="filled"
						required
						value={password}
						onChange={handleInput}
						name="password"
						fullWidth
					/>
				</Grid>
				{buildTwoFactorTextField()}
			</>
		);
	}

	function buildFormContent() {
		return (
			<form onSubmit={handleSubmit}>
				<Grid container spacing={3}>
					{passwordResetRequired ? (
						<>
							{buildPasswordResetForm()}
							<Grid item xs={12}>
								<Alert severity="info" text="Password reset required. Please enter a new password." />
							</Grid>
						</>
					) : (
						buildLoginForm()
					)}
					<Grid item xs={12}>
						<Alert header="Login failed!" text={warningText} />
					</Grid>
					<Grid item xs={12}>
						<Button fullWidth type="submit" disabled={submitting} variant="contained" color="primary">
							Login
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Button
							fullWidth
							component={Link}
							to="/ForgotPassword"
							className={classes.forgotPassword}
							color="primary"
						>
							Forgot password?
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<Button
							fullWidth
							component={Link}
							variant="outlined"
							to={!isNullOrUndefined(redirect) ? `/Register?redirect=${redirect}` : '/Register'}
							disableElevation
							color="primary"
						>
							Register New Account
						</Button>
					</Grid>
				</Grid>
			</form>
		);
	}

	function buildContent() {
		return (
			<Container maxWidth="xs">
				<Typography align="left" variant="h2" gutterBottom>
					Sign In
				</Typography>
				{buildFormContent()}
			</Container>
		);
	}

	return (
		<AuthLayout
			leftChild={
				<>
					<img className={classes.fsLogo} src={logoImg} alt="Logo" />
					<TorchbearerLogo />
				</>
			}
			rightChild={buildContent()}
		/>
	);
}

const mapStateToProps = state => ({
	Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
	PushHistory: data => dispatch(push(data)),
	LogIn: data => dispatch(LogIn(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

Login.propTypes = {
	Auth: PropTypes.object,
	PushHistory: PropTypes.func,
	LogIn: PropTypes.func,
};
