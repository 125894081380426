import React from 'react';
import currency from 'currency.js';
import PropTypes from 'prop-types';
import VariableController from '../../controllers/VariableController';
import CategoryController from '../../controllers/CategoryController';
import {
	makeStyles,
	Container,
	Typography,
	Grid,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	Paper,
	TableBody,
	Button,
	IconButton,
	DialogTitle,
	DialogContent,
	DialogActions,
	Dialog,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { isNullOrUndefined } from '../../helpers/Utils';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { Alert } from '../../components/Common/Alert';
import { Colours } from '../../helpers/Colours';
import { VariableAddEdit } from './VariableAddEdit';
import { ItemIcon } from '../../components/Item/ItemIcon';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

const useStyles = makeStyles(theme => ({
	root: {
		marginTop: 80,
		[theme.breakpoints.down('sm')]: {
			marginTop: 40,
		},
	},
	subtitle: {
		fontSize: 30,
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			marginBottom: 16,
		},
	},
	routines: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	tableWrapper: {
		marginBottom: 40,
		borderRadius: 0,
		position: 'relative',
		overflowX: 'unset',
	},
	table: {
		'& th, td': {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			maxWidth: 200,
		},
		'& thead': {
			backgroundColor: Colours.primary,
			'& th': {
				color: Colours.white,
			},
		},
		'& tr': {
			maxHeight: 53,
			'&:nth-child(even)': {
				backgroundColor: Colours.primary25,
			},
		},
		'& .hide-at-sm': {
			[theme.breakpoints.down('sm')]: {
				display: 'none',
			},
		},
		'& .hide-at-xs': {
			[theme.breakpoints.down('xs')]: {
				display: 'none',
			},
		},
	},
	options: {
		marginTop: 56,
		marginBottom: 40,
	},
	optionRow: {
		display: 'flex',
		flexDirection: 'row',
		marginLeft: 8,
		height: 73,
	},
	addBtn: {
		position: 'absolute',
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
		right: 0,
	},
	iconBtn: {
		borderRadius: 4,
	},
}));

function Variable(props) {
	const { Auth, PushHistory } = props;
	const [redirectUrl, setRedirectUrl] = React.useState(null);
	const [showAddEdit, setShowAddEdit] = React.useState(false);
	const [showDelete, setShowDelete] = React.useState(false);
	const [targetVariable, setTargetVariable] = React.useState(null);
	const [variables, setVariables] = React.useState([]);
	const [categories, setCategories] = React.useState([]);
	const [warningText, setWarningText] = React.useState(null);
	const [loading, setLoading] = React.useState(true);
	const [deleting, setDeleting] = React.useState(false);
	const classes = useStyles();

	const saveVariables = React.useCallback((variables = []) => {
		setVariables(
			variables.sort((a, b) => {
				return a?.categoryId - b?.categoryId || a?.name.localeCompare(b?.name, 'en', { sensitivity: 'base' });
			})
		);
	}, []);

	const fetchVariables = React.useCallback(async () => {
		setWarningText(null);
		const response = await VariableController.getVariables();
		if (!response.hasError) {
			setVariables(response.data);
		} else {
			setWarningText(response.data);
		}
	}, []);

	const fetchCategories = React.useCallback(async () => {
		setWarningText(null);
		const response = await CategoryController.getCategories();
		if (!response.hasError) {
			setCategories(response.data);
		} else {
			setWarningText(response.data);
		}
	}, []);

	// initialise
	React.useEffect(() => {
		async function init() {
			if (!Auth.isAuthenticated) {
				setRedirectUrl('/Login');
				return;
			}
			await fetchCategories();
			await fetchVariables();
			setLoading(false);
		}
		init();
	}, [Auth, fetchVariables, fetchCategories]);

	// redirect
	React.useEffect(() => {
		if (!isNullOrUndefined(redirectUrl)) {
			PushHistory(redirectUrl);
		}
	}, [redirectUrl, PushHistory]);

	function handleNewVariable() {
		setTargetVariable(null);
		setShowAddEdit(true);
	}

	function handleEditVariable(newTargetRoutine) {
		setTargetVariable(newTargetRoutine);
		setShowAddEdit(true);
	}

	function handleDeleteVariable(routine) {
		setTargetVariable(routine);
		setShowDelete(true);
	}

	function handleCancelDelete() {
		setShowDelete(false);
		setTargetVariable(null);
	}

	function handleCloseAddEdit(variable = null) {
		if (!isNullOrUndefined(variable)) {
			handleAddEditVariable(variable);
		}
		setShowAddEdit(false);
		setTargetVariable(null);
	}

	function handleAddEditVariable(variable) {
		if (variables.filter(e => e.id === variable.id).length > 0) {
			// replace an existing variable
			saveVariables(variables.map(e => (e.id === variable.id ? variable : e)));
		} else {
			// add new variable to array
			saveVariables([variable, ...variables]);
		}
	}

	async function handleDelete() {
		setDeleting(true);
		setWarningText(null);

		const response = await VariableController.deleteVariable(targetVariable?.id);
		if (response.hasError) {
			setWarningText(response.data);
		} else {
			saveVariables(variables.filter(e => e.id !== targetVariable.id));
		}

		setDeleting(false);
		setShowDelete(false);
	}

	function buildDeleteDialog() {
		return (
			<Dialog open={showDelete} onClose={handleCancelDelete}>
				<DialogTitle>Confirm Delete</DialogTitle>
				<DialogContent>
					<Typography variant="body2" paragraph>
						If you delete this variable it will no longer be available in your forecast.
					</Typography>
					<Typography variant="body2">Are you sure you want to delete this variable item?</Typography>
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={handleCancelDelete}>
						Cancel
					</Button>
					<Button color="primary" onClick={handleDelete}>
						I&apos;m Sure
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	function buildTable() {
		return (
			<div className={classes.routines}>
				<TableContainer component={Paper} className={classes.tableWrapper}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell className="hide-at-xs" align="center">
									Category
								</TableCell>
								<TableCell align="center">Item</TableCell>
								<TableCell align="center">Icon</TableCell>
								<TableCell className="hide-at-xs" align="center">
									Value
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{variables.map(variable => (
								<TableRow key={variable.id}>
									<TableCell className="hide-at-xs" align="center">
										{categories.find(e => e.id === variable?.categoryId).name}
									</TableCell>
									<TableCell align="center">{variable?.name}</TableCell>
									<TableCell align="center">
										<ItemIcon
											itemName={variable?.name}
											iconName={variable?.iconName}
											iconBGColour={variable?.iconBGColour}
										/>
									</TableCell>
									<TableCell className="hide-at-xs" align="center">
										{currency(variable?.amount, { symbol: '£' }).format()}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					<Button
						variant="contained"
						color="secondary"
						className={classes.addBtn}
						startIcon={<AddIcon />}
						onClick={handleNewVariable}
					>
						Add
					</Button>
				</TableContainer>
				<div className={classes.options}>
					{variables.map(routine => (
						<div className={classes.optionRow} key={routine.id}>
							<IconButton
								aria-label="Edit"
								size="small"
								onClick={() => handleEditVariable(routine)}
								className={classes.iconBtn}
							>
								<EditIcon />
							</IconButton>
							<IconButton
								aria-label="Delete"
								size="small"
								onClick={() => handleDeleteVariable(routine)}
								className={classes.iconBtn}
							>
								<DeleteIcon />
							</IconButton>
						</div>
					))}
				</div>
			</div>
		);
	}

	return (
		<Container maxWidth="md" className={classes.root}>
			<LoadingOverlay loading={loading || deleting} />
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography variant="h2">
						Variable <span className={classes.subtitle}>(Receipts and Payments)</span>
					</Typography>
					<Typography variant="subtitle2">
						Define items whose values and timings vary, such as nights out, supermarket treats and the
						monthly credit card bill
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Alert header="Something went wrong!" text={warningText} />
				</Grid>
				<Grid item xs={12}>
					{buildTable()}
				</Grid>
				<Grid item xs={12}>
					<Button
						component={Link}
						to="/Visualise"
						disabled={variables.length === 0}
						variant="contained"
						color="primary"
					>
						Save &amp; Continue
					</Button>
				</Grid>
				<VariableAddEdit
					open={showAddEdit}
					onClose={handleCloseAddEdit}
					variable={targetVariable}
					categories={categories}
				/>
			</Grid>
			{buildDeleteDialog()}
		</Container>
	);
}

const mapStateToProps = state => ({
	Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
	PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Variable);

Variable.propTypes = {
	Auth: PropTypes.object,
	PushHistory: PropTypes.func,
};
