import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import currency from 'currency.js';
import {
	makeStyles,
	Dialog,
	DialogTitle,
	Divider,
	DialogContent,
	DialogActions,
	Button,
	Grid,
	Typography,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Switch,
	TextField,
	InputAdornment,
	useTheme,
	useMediaQuery,
	ButtonGroup,
} from '@material-ui/core';
import { ItemIcon } from '../../components/Item/ItemIcon';
import { getCategoryColour, isNullOrUndefined, isNullOrWhitespace } from '../../helpers/Utils';
import { Colours } from '../../helpers/Colours';
import { Accounts, Category, ItemModifierType } from '../../helpers/Constants';
import { VariableAddEdit } from '../Variable/VariableAddEdit';
import { Delete, Edit } from '@material-ui/icons';
import { RoutineAddEdit } from '../Routine/RoutineAddEdit';

const useStyles = makeStyles(() => ({
	listArea: {
		paddingLeft: 0,
		paddingRight: 0,
		'& .none': {
			color: Colours.bodyText,
		},
		'&:last-child': {
			marginBottom: 16,
		},
	},
	listItem: {
		paddingLeft: 0,
		paddingRight: 0,
		border: '1px solid transparent',
		borderRadius: 4,
		flexWrap: 'wrap',
		'&.inactive': {
			border: '1px solid ' + Colours.bg_grey_1,
			marginTop: 5,
			padding: 0,
			'& .MuiButtonGroup-groupedOutlinedVertical': {
				height: 44,
			},
		},
		'&.active': {
			backgroundColor: Colours.primary25,
			border: '1px solid ' + Colours.primary50,
			marginTop: 5,
			padding: 0,
		},
		'& .MuiListItemText-root': {
			maxWidth: 'calc(100% - 158px)',
		},
		'& .MuiListItemText-primary': {
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
		'& .MuiButtonGroup-groupedOutlinedVertical': {
			height: 67,
			width: 40,
			padding: 0,
		},
		'& .routine': {
			'& .MuiButtonGroup-groupedOutlinedVertical': {
				height: 44,
			},
		},
	},
	addEditSubtitle: {
		marginTop: 8,
	},
}));

export function TimelineAddRemove(props) {
	const {
		date,
		account,
		category,
		categories,
		routines,
		variables,
		onClose,
		onAdd,
		onRemove,
		onEdit,
		onAddEditVariable,
		onAddEditRoutine,
		onDeleteVariable,
		onDeleteRoutine,
	} = props;
	const [addEditRoutine, setAddEditRoutine] = React.useState(null);
	const [addEditVariable, setAddEditVariable] = React.useState(null);
	const [deleteRoutine, setDeleteRoutine] = React.useState(null);
	const [deleteVariable, setDeleteVariable] = React.useState(null);
	const [variableValues, setVariableValues] = React.useState([]);
	const [routineValues, setRoutineValues] = React.useState([]);
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('xs'));

	const getVariableItems = React.useCallback(() => {
		try {
			return variables.filter(val =>
				account === Accounts[1] && category === categories[0].id
					? val.categoryId === categories[2].id
					: val.categoryId === category
			);
		} catch {
			return [];
		}
	}, [variables, account, categories, category]);

	const getRoutineItems = React.useCallback(() => {
		try {
			return routines.filter(val =>
				account === Accounts[1] && category === categories[0].id
					? val.categoryId === categories[2].id
					: val.categoryId === category && val.accountName === account
			);
		} catch {
			return [];
		}
	}, [routines, account, categories, category]);

	React.useEffect(() => {
		if (date != null) {
			const variables = getVariableItems().map(e => {
				const instances = e.occasionInstances.filter(o => {
					return (
						moment.utc(o.on).local().startOf('day').format() === date.startOf('day').format() &&
						(o.accountName === account || e.categoryId === categories[2].id)
					);
				});
				const value = instances[0]?.amount;
				return isNullOrUndefined(value)
					? ''
					: currency(value, { symbol: '', precision: 0, separator: ',' }).format();
			});

			const routines = getRoutineItems().map(e => {
				const updates = (e.updates ?? [])
					.filter(e => moment.utc(e.date).local().startOf('day').isSameOrBefore(date.startOf('day')))
					.sort(
						(a, b) =>
							moment.utc(b.date).local().startOf('day').valueOf() -
							moment.utc(a.date).local().startOf('day').valueOf()
					);
				const value = updates[0]?.amount ?? e?.amount;
				return isNullOrUndefined(value)
					? ''
					: currency(value, { symbol: '', precision: 0, separator: ',' }).format();
			});

			if (routines.length === 0) {
				setAddEditRoutine({ categoryId: category, starts: date, accountName: account });
			}
			setVariableValues(variables);
			setRoutineValues(routines);
		} else {
			setVariableValues([]);
			setRoutineValues([]);
		}
	}, [date, routines, variables, getVariableItems, getRoutineItems]);

	const categoryColour = category =>
		getCategoryColour(categories?.find(e => e.id === category)?.name ?? Category.Basics);

	function closeAndClearData() {
		onClose();
	}

	function handleUpdateRoutineValue(event, index) {
		const { value } = event?.target;
		setRoutineValues(
			routineValues.map((e, i) =>
				i === index
					? value.length > 0
						? currency(value.toString().replace(/-/g, ''), {
								symbol: '',
								precision: 0,
								separator: ',',
						  }).format()
						: ''
					: e
			)
		);
	}

	function handleRoutineInput(event, update, routineId) {
		const { name, value } = event.target;
		if (isNullOrWhitespace(value) || name !== 'amount') {
			return;
		}
		const newUpdate = { ...update, amount: currency(value).toJSON() };
		onEdit(ItemModifierType.Update, { update: newUpdate, routineId });
	}

	function handleCancelDelete() {
		setDeleteRoutine(null);
		setDeleteVariable(null);
	}

	function handleConfirmDelete() {
		if (!isNullOrUndefined(deleteRoutine)) {
			onDeleteRoutine(deleteRoutine);
			setDeleteRoutine(null);
		} else if (!isNullOrUndefined(deleteVariable)) {
			onDeleteVariable(deleteVariable);
			setDeleteVariable(null);
		}
		closeAndClearData();
	}

	function handleAddEditVariable(variable) {
		setAddEditVariable(null);

		if (isNullOrUndefined(variable) && routines.length === 0) {
			closeAndClearData();
		}

		if (!isNullOrUndefined(variable)) {
			onAddEditVariable(variable);
			onClose();
		}
	}

	function handleAddEditRoutine(routine) {
		setAddEditRoutine(null);

		const items = getRoutineItems();

		if (isNullOrUndefined(routine) && items.length === 0) {
			closeAndClearData();
		}

		if (!isNullOrUndefined(routine)) {
			onAddEditRoutine(routine);
			onClose();
		}
	}

	function buildRoutineList() {
		if (isNullOrUndefined(date)) {
			return null;
		}
		const items = getRoutineItems();
		return (
			<>
				<Grid item xs={12}>
					<Typography variant="subtitle1" className={classes.addEditSubtitle}>
						<b>Routines</b>
					</Typography>
				</Grid>
				<Grid item xs={12} className={classes.listArea}>
					{items.length === 0 ? (
						<Typography className="none" variant="body2">
							None Available
						</Typography>
					) : (
						items.map((e, i) => {
							const updates = (e.updates ?? [])
								.filter(e =>
									moment.utc(e.date).local().startOf('day').isSameOrBefore(date.startOf('day'))
								)
								.sort(
									(a, b) =>
										moment.utc(b.date).local().startOf('day').valueOf() -
										moment.utc(a.date).local().startOf('day').valueOf()
								);
							const selected =
								updates.filter(
									e =>
										moment.utc(e.date).local().startOf('day').format() ===
										date.startOf('day').format()
								).length > 0;
							const value = updates[0]?.amount ?? e?.amount;
							return (
								<ListItem key={i} className={`${classes.listItem} ${selected ? 'active' : 'inactive'}`}>
									<ButtonGroup variant="outlined" className="routine" orientation="vertical">
										<Button size="small" onClick={() => setAddEditRoutine(e)}>
											<Edit />
										</Button>
										<Button size="small" onClick={() => setDeleteRoutine(e)}>
											<Delete />
										</Button>
									</ButtonGroup>
									<ListItemAvatar>
										<ItemIcon
											itemName={e.name}
											iconName={e.iconName}
											iconBGColour={categoryColour(e.categoryId)}
										/>
									</ListItemAvatar>
									<ListItemText
										component="div"
										primary={e.name}
										secondary={
											selected ? (
												<TextField
													variant="filled"
													label="Value"
													value={routineValues[i]}
													size="small"
													name="amount"
													onChange={e => handleUpdateRoutineValue(e, i)}
													onBlur={event => handleRoutineInput(event, updates[0], e.id)}
													fullWidth
													InputProps={{
														startAdornment: (
															<InputAdornment position="start">£</InputAdornment>
														),
													}}
												/>
											) : (
												currency(value, { symbol: '£', precision: 0, separator: ',' }).format()
											)
										}
									/>
								</ListItem>
							);
						})
					)}
				</Grid>
				<Grid item xs={12}>
					<Button
						fullWidth
						variant="outlined"
						color="primary"
						onClick={() => setAddEditRoutine({ categoryId: category, starts: date, accountName: account })}
					>
						Add Routine
					</Button>
				</Grid>
			</>
		);
	}

	return (
		<>
			<Dialog
				open={
					!isNullOrUndefined(date) && isNullOrUndefined(addEditVariable) && isNullOrUndefined(addEditRoutine)
				}
				fullScreen={matches}
			>
				<DialogTitle>Add/Edit/Remove Items</DialogTitle>
				<Divider />
				<DialogContent>
					<Grid container spacing={1}>
						{buildRoutineList()}
					</Grid>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button color="primary" onClick={() => closeAndClearData()}>
						Close
					</Button>
				</DialogActions>
			</Dialog>
			<VariableAddEdit
				categories={categories}
				variable={addEditVariable}
				open={!isNullOrUndefined(addEditVariable)}
				date={date}
				onAdd={onAdd}
				onClose={handleAddEditVariable}
			/>
			<RoutineAddEdit
				categories={categories}
				routine={addEditRoutine}
				open={!isNullOrUndefined(addEditRoutine)}
				onClose={handleAddEditRoutine}
			/>
			<Dialog open={!isNullOrUndefined(deleteRoutine) || !isNullOrUndefined(deleteVariable)} maxWidth="xs">
				<DialogTitle>Confirm Delete</DialogTitle>
				<DialogContent>
					This will remove all instances of this item in your timeline. Are you sure you want to delete it?
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={handleConfirmDelete}>
						Confirm
					</Button>
					<Button color="primary" onClick={handleCancelDelete}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

TimelineAddRemove.propTypes = {
	date: PropTypes.object,
	account: PropTypes.string.isRequired,
	category: PropTypes.string,
	categories: PropTypes.array.isRequired,
	routines: PropTypes.array.isRequired,
	variables: PropTypes.array.isRequired,
	onClose: PropTypes.func.isRequired,
	onAdd: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired,
	onEdit: PropTypes.func.isRequired,
	onAddEditVariable: PropTypes.func.isRequired,
	onAddEditRoutine: PropTypes.func.isRequired,
	onDeleteVariable: PropTypes.func.isRequired,
	onDeleteRoutine: PropTypes.func.isRequired,
};
