import Axios from 'axios';
import UserController from './UserController';
import { APIPath, APIRoute } from '../helpers/Constants';
import { generateResponse } from '../helpers/Utils';

const VariableController = {
	getVariables,
	addVariable,
	editVariable,
	deleteVariable,
	addInstance,
	editInstance,
	removeInstance,
};

async function getVariables() {
	const userHeaders = await UserController.getUserHeaders();
	return Axios.get(APIRoute + APIPath.GET_OCCASIONS, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function addVariable(name, iconName, iconBGColour, amount, categoryId) {
	const userHeaders = await UserController.getUserHeaders();
	const variableModel = {
		name,
		iconName,
		iconBGColour,
		amount,
		categoryId,
	};
	return Axios.post(APIRoute + APIPath.ADD_OCCASION, variableModel, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function editVariable(id, name, iconName, iconBGColour, amount, categoryId) {
	const userHeaders = await UserController.getUserHeaders();
	const variableModel = {
		id,
		name,
		iconName,
		iconBGColour,
		amount,
		categoryId,
	};
	return Axios.post(APIRoute + APIPath.EDIT_OCCASION, variableModel, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function deleteVariable(id) {
	const userHeaders = await UserController.getUserHeaders();
	return Axios.post(APIRoute + APIPath.DELETE_OCCASION + id, null, userHeaders)
		.then(() => {
			return generateResponse(false, id);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function addInstance(occasionId, accountName, on, amount) {
	const userHeaders = await UserController.getUserHeaders();
	const instanceModel = {
		occasionId,
		accountName,
		on,
		amount,
	};
	return Axios.post(APIRoute + APIPath.ADD_OCCASION_INSTANCE, instanceModel, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function editInstance(instanceId, occasionId, accountName, on, amount) {
	const userHeaders = await UserController.getUserHeaders();
	const instanceModel = {
		occasionId,
		accountName,
		on,
		amount,
	};
	return Axios.post(APIRoute + APIPath.EDIT_OCCASION_INSTANCE + instanceId, instanceModel, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function removeInstance(id) {
	const userHeaders = await UserController.getUserHeaders();
	return Axios.post(APIRoute + APIPath.REMOVE_OCCASION_INSTANCE + id, null, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

export default VariableController;
