import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import ReactCardFlip from 'react-card-flip';
import { isNullOrUndefined, returnNumberWithCommas } from '../../helpers/Utils';
import { findIcon } from '../../helpers/ItemIconLib';
import { CheckCircle, Delete, ErrorOutline } from '@material-ui/icons';
import { Colours } from '../../helpers/Colours';

const useStyles = makeStyles(theme => ({
	root: {
		margin: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(1),
		},
	},
	card: props => ({
		position: 'relative',
		backgroundColor: props.color,
		borderRadius: 6,
		width: 182,
		height: 182,
		[theme.breakpoints.down('xs')]: {
			width: 160,
			height: 160,
		},
	}),
	noValues: {
		transition: 'opacity 0.5s',
		cursor: 'pointer',
		opacity: props => (props.isFlipped ? 0 : 1),
		backgroundColor: props => (props.valid ? props.color : props.altColor),
		'&.pulse': {
			animation: `$item-pulse 3s infinite ease-out`,
		},
	},
	faceIcon: {
		position: 'absolute',
		color: Colours.white,
		bottom: 2,
	},
	delete: {
		left: 2,
		cursor: 'pointer',
		[theme.breakpoints.down('xs')]: {
			left: 0,
			height: 18,
		},
	},
	valid: {
		right: 2,
		[theme.breakpoints.down('xs')]: {
			height: 18,
			right: 0,
		},
	},
	form: {
		position: 'relative',
		padding: `2px 8px`,
		height: '100%',
		[theme.breakpoints.down('xs')]: {
			padding: `${theme.spacing(0.25)}px ${theme.spacing(0.5)}px`,
		},
		'& label': {
			fontSize: 11,
			fontWeight: 800,
			[theme.breakpoints.down('xs')]: {
				fontSize: 9,
			},
		},
	},
	itemTotal: {
		position: 'absolute',
		color: Colours.white,
		fontSize: 18,
		top: 2,
		right: 5,
		[theme.breakpoints.down('xs')]: {
			fontSize: 14,
		},
	},
	icon: props => ({
		position: 'absolute',
		height: '100%',
		width: '100%',
		objectFit: 'contain',
		backgroundSize: '50%',
		backgroundImage: `url(${props.iconImg})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		[theme.breakpoints.down('xs')]: {
			backgroundSize: '50%',
		},
		'&.translucent': {
			opacity: 0.5,
		},
	}),
	inputWrapper: {
		position: 'relative',
		'& input': {
			width: '100%',
			height: 17,
			paddingLeft: 16,
			paddingTop: 0,
			fontSize: 11,
			[theme.breakpoints.down('xs')]: {
				fontSize: 9,
				height: 14,
			},
		},
		'&:before': {
			bottom: 0,
			left: 0,
			height: '100%',
			width: 20,
			position: 'absolute',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			[theme.breakpoints.down('xs')]: {
				height: 16,
			},
		},
		'&.cash': {
			'&:before': {
				content: '"£"',
			},
		},
		'&.multiply': {
			'&:before': {
				content: '"x"',
			},
		},
		'&.description': {
			'& input': {
				paddingLeft: 2,
			},
		},
	},
	buttons: {
		position: 'absolute',
		display: 'flex',
		bottom: 0,
		left: 0,
		right: 0,

		'& button': {
			width: '50%',

			fontSize: 11,
			paddingTop: 2,
			paddingBottom: 2,
			[theme.breakpoints.down('xs')]: {
				fontSize: 8,
			},
		},
		'& :first-child': {
			borderRadius: '0px 0px 0px 5px',
		},
		'& :last-child': {
			borderRadius: '0px 0px 5px 0px',
		},
	},
	'@keyframes item-pulse': {
		'0%': {
			transform: 'scale(0.85)',
		},
		'50%': {
			transform: 'scale(1)',
		},
		'100%': {
			transform: 'scale(0.85)',
		},
	},
}));

export default function MoneyPlanIconItem(props) {
	const { item, flipped, total, onChange, onSave, onCancel, onDelete, instance } = props;
	const [value, setValue] = React.useState('');
	const [description, setDescription] = React.useState('');
	const [timesPerYear, setTimesPerYear] = React.useState('');
	const [isFlipped, setIsFlipped] = React.useState(flipped);
	const [saving, setSaving] = React.useState(false);
	const icon = findIcon(item.icon);
	const valid = validateInputs();
	const classes = useStyles({ ...props, iconImg: icon.icon, isFlipped, valid });

	React.useEffect(() => {
		if (!isNullOrUndefined(item)) {
			const realValue = !isNullOrUndefined(item.tempValue) ? item.tempValue : item.value;
			const realTimesPerYear = !isNullOrUndefined(item.tempYearlyOccurrence)
				? item.tempYearlyOccurrence
				: item.yearlyOccurrence;
			setValue(realValue === 0 ? '' : realValue);
			setTimesPerYear(realTimesPerYear === 0 ? '' : realTimesPerYear);
			setDescription(item.tempDescription ?? item.description ?? '');
		}
	}, [item]);

	React.useEffect(() => {
		setIsFlipped(flipped);
	}, [flipped]);

	function validateInputs() {
		const parsedValue = parseInt(value);
		const parsedTimesPerYear = parseInt(timesPerYear);
		if (!isNaN(parsedTimesPerYear) && !isNaN(parsedValue)) {
			if (parsedTimesPerYear > -1 && parsedValue > -1) {
				return true;
			}
		}
		return false;
	}

	async function handleSubmit(event) {
		event.preventDefault();
		setSaving(true);
		await onSave(item.id);
		setIsFlipped(false);
		setTimeout(() => setSaving(false), 500);
	}

	function handleCancel() {
		onCancel(item.id);
		setIsFlipped(false);
	}

	function handleChange(value, timesPerYear, description) {
		onChange(
			item.id,
			value.length === 0 ? 0 : parseInt(value),
			timesPerYear.length === 0 ? 0 : parseInt(timesPerYear),
			description
		);
	}

	function handleDescription(newDescription) {
		setDescription(newDescription);
		handleChange(value, timesPerYear, newDescription);
	}

	function handleNewValue(newValue) {
		const realValue = handleValidateInput(newValue, value);
		setValue(realValue);
		handleChange(realValue, timesPerYear, description);
	}

	function handleNewTimesPerYear(newTimesPerYear) {
		const realTimesPerYear = handleValidateInput(newTimesPerYear, timesPerYear);
		setTimesPerYear(realTimesPerYear);
		handleChange(value, realTimesPerYear, description);
	}

	function handleValidateInput(newInput, oldValue) {
		const parsed = parseInt(newInput.replace(/,/g, ''));
		if (newInput.length === 0) {
			return newInput;
		} else if (!isNaN(parsed)) {
			return parsed;
		} else {
			return oldValue;
		}
	}

	function handleIsFlipped() {
		if (instance !== 1) {
			setIsFlipped(true);
		}
	}

	function buildFace() {
		return (
			<div className={`${classes.card} ${classes.noValues}${valid || isFlipped ? '' : ` pulse`}`}>
				{(instance === 2 || instance === 3) && (
					<div className={classes.itemTotal}>£{returnNumberWithCommas(total)}</div>
				)}
				<div className={classes.icon} onClick={() => handleIsFlipped()}></div>
				<Delete className={`${classes.delete} ${classes.faceIcon}`} onClick={onDelete} />
				{valid ? (
					<CheckCircle className={`${classes.valid} ${classes.faceIcon}`} />
				) : (
					<ErrorOutline className={`${classes.valid} ${classes.faceIcon}`} />
				)}
			</div>
		);
	}

	function buildInputs() {
		return (
			<form onSubmit={handleSubmit} className={classes.card}>
				<div className={`${classes.icon} translucent`}></div>
				<div className={classes.form}>
					<label>
						Value
						<div className={`${classes.inputWrapper} cash`}>
							<input
								disabled={saving}
								required
								onChange={e => handleNewValue(e.target.value)}
								value={returnNumberWithCommas(value)}
							/>
						</div>
					</label>
					<label>
						Times Per Year
						<div className={`${classes.inputWrapper} multiply`}>
							<input
								disabled={saving}
								required
								onChange={e => handleNewTimesPerYear(e.target.value)}
								value={timesPerYear}
							/>
						</div>
					</label>
					<label>
						Yearly Value
						<div className={`${classes.inputWrapper} cash`}>
							<input disabled={saving} value={returnNumberWithCommas(total)} readOnly />
						</div>
					</label>
					<label>
						Description
						<div className={`${classes.inputWrapper} description`}>
							<input
								disabled={saving}
								onChange={e => handleDescription(e.target.value)}
								value={description}
							/>
						</div>
					</label>
					<div className={classes.buttons}>
						<Button
							disabled={saving}
							onClick={handleCancel}
							variant="contained"
							size="small"
							disableElevation
						>
							Cancel
						</Button>
						<Button
							disabled={saving || !valid}
							type="submit"
							variant="contained"
							size="small"
							color="primary"
							disableElevation
						>
							Save
						</Button>
					</div>
				</div>
			</form>
		);
	}

	return (
		<div className={classes.root}>
			<ReactCardFlip isFlipped={isFlipped}>
				{buildFace()}
				{buildInputs()}
			</ReactCardFlip>
		</div>
	);
}

MoneyPlanIconItem.propTypes = {
	item: PropTypes.object.isRequired,
	flipped: PropTypes.bool.isRequired,
	total: PropTypes.number.isRequired,
	color: PropTypes.string.isRequired,
	altColor: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	instance: PropTypes.number.isRequired,
};
