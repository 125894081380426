import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { isNullOrUndefined } from '../../helpers/Utils';
import { Colours } from '../../helpers/Colours';

const useStyles = makeStyles(() => ({
	loading: {
		width: 90,
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderLeft: `1px solid ${Colours.primary25}`,
		borderRight: `1px solid ${Colours.primary25}`,
		backgroundColor: Colours.bg_grey_1,
		textTransform: 'uppercase',
		color: Colours.bodyText,
	},
}));

function DeferRender(props) {
	const { children } = props;
	const [rendering, setRendering] = React.useState(false);
	const element = React.useRef();
	const classes = useStyles();

	React.useState(() => {
		setRendering(false);
	}, [children]);

	function loop() {
		setTimeout(() => {
			if (rendering || isNullOrUndefined(element.current)) {
				return;
			}
			const rect = element.current.getBoundingClientRect();
			const isInViewport =
				rect.left >= -200 && rect.right <= (window.innerWidth || document.documentElement.clientWidth) + 200;
			if (isInViewport) {
				setRendering(true);
			} else {
				loop();
			}
		}, [1000]);
	}
	loop();

	function buildLoadingArea() {
		return <div className={classes.loading}>Loading</div>;
	}

	return (
		<div {...props} ref={element}>
			{rendering ? children : buildLoadingArea()}
		</div>
	);
}

DeferRender.propTypes = {
	children: PropTypes.any,
};

export default DeferRender;
