// This project contains the following icons, provided under a creative commons licence by https://thenounproject.com/ and https://www.flaticon.com/authors/freepik
// * SEE THE TAG ICON PROP FOR ATTRIBUTIONS *

import OtherIcon from '../images/Icons/Other.png';

// Money In
import SalaryIcon from '../images/Icons/MoneyIn/Salary.png';
import BenefitsIcon from '../images/Icons/MoneyIn/Benefits.png';
import SaleIcon from '../images/Icons/MoneyIn/Sale.png';
import TransferIcon from '../images/Icons/MoneyIn/Transfer.png';
import PocketIcon from '../images/Icons/MoneyIn/Pocket.png';
import OtherIncomeIcon from '../images/Icons/MoneyIn/Other.png';

// Basics
import CarFuelIcon from '../images/Icons/Basics/CarFuel.png';
import CarRoadTaxIcon from '../images/Icons/Basics/CarRoadTax.png';
import CarServiceIcon from '../images/Icons/Basics/CarService.png';
import ClothingIcon from '../images/Icons/Basics/Clothing.png';
import CouncilTaxIcon from '../images/Icons/Basics/CouncilTax.png';
import ElectricIcon from '../images/Icons/Basics/Electric.png';
import GasIcon from '../images/Icons/Basics/Gas.png';
import InsuranceIcon from '../images/Icons/Basics/Insurance.png';
import InsuranceCarIcon from '../images/Icons/Basics/InsuranceCar.png';
import InsuranceContentsIcon from '../images/Icons/Basics/InsuranceContents.png';
import InsuranceTravelIcon from '../images/Icons/Basics/InsuranceTravel.png';
import InternetIcon from '../images/Icons/Basics/Internet.png';
import LoanIcon from '../images/Icons/Basics/Loan.png';
import MobileIcon from '../images/Icons/Basics/Mobile.png';
import MortgageIcon from '../images/Icons/Basics/Mortgage.png';
import OtherBasicIcon from '../images/Icons/Basics/Other.png';
import RentIcon from '../images/Icons/Basics/Rent.png';
import SavingsEmergencyIcon from '../images/Icons/Basics/SavingsEmergency.png';
import SupermarketIcon from '../images/Icons/Basics/Supermarket.png';
import TransportIcon from '../images/Icons/Basics/Transport.png';
import TVLicenceIcon from '../images/Icons/Basics/TVLicence.png';
import UtilitiesIcon from '../images/Icons/Basics/Utilities.png';
import WaterIcon from '../images/Icons/Basics/Water.png';

// Credit Card
import CreditCardIcon from '../images/Icons/CreditCard/CreditCard.png';

// Choices
import CafeIcon from '../images/Icons/Choices/Cafe.png';
import CinemaIcon from '../images/Icons/Choices/Cinema.png';
import ComputerGameIcon from '../images/Icons/Choices/ComputerGame.png';
import ConcertIcon from '../images/Icons/Choices/Concert.png';
import FashionIcon from '../images/Icons/Choices/Fashion.png';
import FilmMusicIcon from '../images/Icons/Choices/FilmMusic.png';
import HolidayIcon from '../images/Icons/Choices/Holiday.png';
import MealOutIcon from '../images/Icons/Choices/MealOut.png';
import NightOutIcon from '../images/Icons/Choices/NightOut.png';
import OtherChoiceIcon from '../images/Icons/Choices/Other.png';
import SavingsFutureIcon from '../images/Icons/Choices/SavingsFuture.png';
import SportsIcon from '../images/Icons/Choices/Sports.png';
import SportsEventIcon from '../images/Icons/Choices/SportsEvent.png';
import TakeawayIcon from '../images/Icons/Choices/Takeaway.png';
import SodaIcon from '../images/Icons/Choices/Soda.png';
import SweetsIcon from '../images/Icons/Choices/Sweets.png';
import TreatsIcon from '../images/Icons/Choices/Treats.png';

// Income
import Sales from '../images/Icons/Income/sales.png';
import OtherIncome from '../images/Icons/Income/other-income.png';

// Expenditure
import BusinessRates from '../images/Icons/Expenditure/business-rates.png';
import ComputerAndSoftware from '../images/Icons/Expenditure/computer-and-software.png';
import Contractors from '../images/Icons/Expenditure/contractors.png';
import Delivery from '../images/Icons/Expenditure/delivery.png';
import Energy from '../images/Icons/Expenditure/energy.png';
import InsuranceNew from '../images/Icons/Expenditure/insurance.png';
import InterestPayable from '../images/Icons/Expenditure/interest-payable.png';
import LegalAndProfessional from '../images/Icons/Expenditure/legal-and-professional.png';
import Marketing from '../images/Icons/Expenditure/marketing.png';
import Material from '../images/Icons/Expenditure/material.png';
import OtherPayments from '../images/Icons/Expenditure/other-payments.png';
import Rent from '../images/Icons/Expenditure/rent.png';
import Suppliers from '../images/Icons/Expenditure/suppliers.png';
import Tax from '../images/Icons/Expenditure/tax.png';
import Travel from '../images/Icons/Expenditure/travel.png';
import Vat from '../images/Icons/Expenditure/vat.png';
import Wages from '../images/Icons/Expenditure/wages.png';

// Payments
import LoanRepayment from '../images/Icons/Payments/loan-repayment.png';
import Paye from '../images/Icons/Payments/paye.png';

// Receipts
import LoanAdvance from '../images/Icons/Receipts/loan-advance.png';

export const ItemIcons = {
	// Default
	DEFAULT: {
		name: 'default',
		icon: null,
		tag: 'Default',
		available: true,
		budgeting: true,
	},
	OTHER: {
		name: 'other',
		icon: OtherIcon,
		tag: 'Icon made by Freepik',
		available: false,
		budgeting: true,
	},

	// Money In
	MI_WAGES: {
		name: 'mi-wages',
		icon: SalaryIcon,
		tag: 'salary by Anagaja Design from the Noun Project',
		available: false,
		budgeting: true,
	},
	MI_BENEFITS: {
		name: 'mi-benefits',
		icon: BenefitsIcon,
		tag: 'receiving by Stephen Plaster from the Noun Project',
		available: false,
		budgeting: true,
	},
	MI_SALE_OF_UNWANTED_ITEMS: {
		name: 'mi-sale-of-unwanted-items',
		icon: SaleIcon,
		tag: 'sale by Valeriy from the Noun Project',
		available: false,
		budgeting: true,
	},
	MI_TRANSFER_FROM_SAVINGS: {
		name: 'mi-transfer-savings',
		icon: TransferIcon,
		tag: 'Pound by Curve from the Noun Project',
		available: false,
		budgeting: true,
	},
	MI_POCKET_MONEY: {
		name: 'mi-pocket-money',
		icon: PocketIcon,
		tag: 'Created by b farias from Noun Project',
		available: false,
		budgeting: true,
	},
	MI_OTHER: {
		name: 'mi-other',
		icon: OtherIncomeIcon,
		tag: 'other by Adrien Coquet from the Noun Project',
		available: false,
		budgeting: true,
	},

	// Basics
	BA_SUPERMARKET: {
		name: 'ba-supermarket',
		icon: SupermarketIcon,
		tag: 'Shopping Cart by Max Ten from the Noun Project',
		available: false,
		budgeting: true,
	},
	BA_RENT: {
		name: 'ba-rent',
		icon: RentIcon,
		tag: 'rent by Adreine from the Noun Project',
		available: false,
		budgeting: true,
	},
	BA_COUNCIL_TAX: {
		name: 'ba-council-tax',
		icon: CouncilTaxIcon,
		tag: 'college by Olena Panasovska from the Noun Project',
		available: false,
		budgeting: true,
	},
	BA_GAS: {
		name: 'ba-gas',
		icon: GasIcon,
		tag: 'Gas by joeartcon from the Noun Project',
		available: false,
		budgeting: true,
	},
	BA_ELECTRIC: {
		name: 'ba-electric',
		icon: ElectricIcon,
		tag: 'Electricity by Adnen Kadri from the Noun Project',
		available: false,
		budgeting: true,
	},
	BA_WATER: {
		name: 'ba-water',
		icon: WaterIcon,
		tag: 'tap water by Francesca Ameglio from the Noun Project',
		available: false,
		budgeting: true,
	},
	BA_UTILITIES: {
		name: 'ba-utilities',
		icon: UtilitiesIcon,
		tag: 'utilities check by Berkah Icon from the Noun Project',
		available: false,
		budgeting: true,
	},
	BA_INTERNET: {
		name: 'ba-internet',
		icon: InternetIcon,
		tag: 'internet by Srinivas Agra from the Noun Project',
		available: false,
		budgeting: true,
	},
	BA_MOBILE: {
		name: 'ba-mobile',
		icon: MobileIcon,
		tag: 'mobile phone by Hawraa Alsalman from the Noun Project',
		available: false,
		budgeting: true,
	},
	BA_CLOTHES: {
		name: 'ba-clothes',
		icon: ClothingIcon,
		tag: 'clothing by Studio Fibonacci from the Noun Project',
		available: false,
		budgeting: true,
	},
	BA_TV_LICENCE: {
		name: 'ba-tv-licence',
		icon: TVLicenceIcon,
		tag: 'warranty by hafiudin from the Noun Project',
		available: false,
		budgeting: true,
	},
	BA_INSURANCE_CONTENTS: {
		name: 'ba-insurance-contents',
		icon: InsuranceContentsIcon,
		tag: 'insurance by nyokon from the Noun Project',
		available: false,
		budgeting: true,
	},
	BA_SAVINGS_EMERGENCY: {
		name: 'ba-savings-emergency',
		icon: SavingsEmergencyIcon,
		tag: 'emergency by Saifurrijal from the Noun Project',
		available: false,
		budgeting: true,
	},
	BA_CAR_SERVICE: {
		name: 'ba-car-service',
		icon: CarServiceIcon,
		tag: 'Car Service by Arthur Shlain from the Noun Project',
		available: false,
		budgeting: true,
	},
	BA_CAR_ROAD_TAX: {
		name: 'ba-car-road-tax',
		icon: CarRoadTaxIcon,
		tag: 'road tax by ProSymbols from the Noun Project',
		available: false,
		budgeting: true,
	},
	BA_CAR_FUEL: {
		name: 'ba-car-fuel',
		icon: CarFuelIcon,
		tag: 'Petrol by TTHNga from the Noun Project',
		available: false,
		budgeting: true,
	},
	BA_INSURANCE: {
		name: 'ba-insurance',
		icon: InsuranceIcon,
		tag: 'insurance by Kartiko Setiaji from the Noun Project',
		available: false,
		budgeting: true,
	},
	BA_INSURANCE_CAR: {
		name: 'ba-insurance-car',
		icon: InsuranceCarIcon,
		tag: 'car insurance by BomSymbols from the Noun Project',
		available: false,
		budgeting: true,
	},
	BA_INSURANCE_TRAVEL: {
		name: 'ba-insurance-travel',
		icon: InsuranceTravelIcon,
		tag: 'Travel Insurance by Pedro from the Noun Project',
		available: false,
		budgeting: true,
	},
	BA_LOAN: {
		name: 'ba-loan',
		icon: LoanIcon,
		tag: 'transfer by Bakunetsu Kaito from the Noun Project',
		available: false,
		budgeting: true,
	},
	BA_MORTGAGE: {
		name: 'ba-mortgage',
		icon: MortgageIcon,
		tag: 'Mortgage by Fuse Studio from the Noun Project',
		available: false,
		budgeting: true,
	},
	BA_TRANSPORT: {
		name: 'ba-transport',
		icon: TransportIcon,
		tag: 'transport by Vectors Point from the Noun Project',
		available: false,
		budgeting: true,
	},
	BA_OTHER: {
		name: 'ba-other',
		icon: OtherBasicIcon,
		tag: 'other by Larea from the Noun Project',
		available: false,
		budgeting: true,
	},

	// Credit Card Bill
	CC_BILL: {
		name: 'cc-bill',
		icon: CreditCardIcon,
		tag: 'transfer by Phạm Thanh Lộc from the Noun Project',
		available: false,
		budgeting: true,
	},

	// Choices
	CH_CAFE: {
		name: 'ch-cafe',
		icon: CafeIcon,
		tag: 'Cafe by Andrei Yushchenko from the Noun Project',
		available: false,
		budgeting: true,
	},
	CH_CINEMA: {
		name: 'ch-cinema',
		icon: CinemaIcon,
		tag: 'Cinema by ibrandify from the Noun Project',
		available: false,
		budgeting: true,
	},
	CH_COMPUTER_GAMES: {
		name: 'ch-computer-games',
		icon: ComputerGameIcon,
		tag: 'computer game by DinosoftLab from the Noun Project',
		available: false,
		budgeting: true,
	},
	CH_FASHION: {
		name: 'ch-fashion',
		icon: FashionIcon,
		tag: 'fashion by fae frey from the Noun Project',
		available: false,
		budgeting: true,
	},
	CH_FILM_MUSIC: {
		name: 'ch-film-music',
		icon: FilmMusicIcon,
		tag: 'Video Download by Hea Poh Lin from the Noun Project',
		available: false,
		budgeting: true,
	},
	CH_HOLIDAY: {
		name: 'ch-holiday',
		icon: HolidayIcon,
		tag: 'holidays by Srinivas Agra from the Noun Project',
		available: false,
		budgeting: true,
	},
	CH_MEAL_OUT: {
		name: 'ch-meal-out',
		icon: MealOutIcon,
		tag: 'dinner by Gracelle Mesina from the Noun Project',
		available: false,
		budgeting: true,
	},
	CH_NIGHT_OUT: {
		name: 'ch-night-out',
		icon: NightOutIcon,
		tag: 'Cheers by Hayley Parke from the Noun Project',
		available: false,
		budgeting: true,
	},
	CH_CONCERT: {
		name: 'ch-concert',
		icon: ConcertIcon,
		tag: 'Pop Female Singer by Vectors Market from the Noun Project',
		available: false,
		budgeting: true,
	},
	CH_SAVINGS_FUTURE_EVENT: {
		name: 'ch-savings-future-event',
		icon: SavingsFutureIcon,
		tag: 'savings by Maxim Basinski from the Noun Project',
		available: false,
		budgeting: true,
	},
	CH_SPORT: {
		name: 'ch-sport',
		icon: SportsIcon,
		tag: 'Sports by Econceptive from the Noun Project',
		available: false,
		budgeting: true,
	},
	CH_SPORTS_EVENT: {
		name: 'ch-sports-event',
		icon: SportsEventIcon,
		tag: 'Stadium',
		available: false,
		budgeting: true,
	},
	CH_TREATS: {
		name: 'ch-treats',
		icon: TreatsIcon,
		tag: 'Favorite Items by Tomas Knopp from the Noun Project',
		available: false,
		budgeting: true,
	},
	CH_TAKEAWAY: {
		name: 'ch-takeaway',
		icon: TakeawayIcon,
		tag: 'Takeaway by Alice Design from the Noun Project',
		available: false,
		budgeting: true,
	},
	CH_SODA: {
		name: 'ch-soda',
		icon: SodaIcon,
		tag: 'Created by Aiden Cooke from Noun Project',
		available: false,
		budgeting: true,
	},
	CH_SWEETS: {
		name: 'ch-sweets',
		icon: SweetsIcon,
		tag: 'sweets by Made from Noun Project',
		available: false,
		budgeting: true,
	},
	CH_OTHER: {
		name: 'ch-other',
		icon: OtherChoiceIcon,
		tag: 'other by Adrien Coquet from the Noun Project',
		available: false,
		budgeting: true,
	},

	// Income
	INC_SALES: {
		name: 'mi-sales',
		icon: Sales,
		tag: ' Project',
		available: true,
		budgeting: true,
	},
	INC_OTHER_INCOME: {
		name: 'mi-other-income',
		icon: OtherIncome,
		tag: ' Project',
		available: true,
		budgeting: true,
	},

	// Expenditure
	EXP_BUSINESS_RATES: {
		name: 'ba-business-rates',
		icon: BusinessRates,
		tag: '',
		available: true,
		budgeting: true,
	},
	EXP_COMPUTER_AND_SOFTWARE: {
		name: 'ba-computer-and-software',
		icon: ComputerAndSoftware,
		tag: '',
		available: true,
		budgeting: true,
	},
	EXP_CONTRACTORS: {
		name: 'ba-contractors',
		icon: Contractors,
		tag: '',
		available: true,
		budgeting: true,
	},
	EXP_DELIVERY: {
		name: 'ba-delivery',
		icon: Delivery,
		tag: '',
		available: true,
		budgeting: true,
	},
	EXP_ENERGY: {
		name: 'ba-energy',
		icon: Energy,
		tag: '',
		available: true,
		budgeting: true,
	},
	EXP_INSURANCE_NEW: {
		name: 'ba-insurance-new',
		icon: InsuranceNew,
		tag: '',
		available: true,
		budgeting: true,
	},
	EXP_INTEREST_PAYABLE: {
		name: 'ba-interest-payable',
		icon: InterestPayable,
		tag: '',
		available: true,
		budgeting: true,
	},
	EXP_LEGAL_AND_PROFESSIONAL: {
		name: 'ba-legal-and-professional',
		icon: LegalAndProfessional,
		tag: '',
		available: true,
		budgeting: true,
	},
	EXP_MARKETING: {
		name: 'ba-marketing',
		icon: Marketing,
		tag: '',
		available: true,
		budgeting: true,
	},
	EXP_MATERIAL: {
		name: 'ba-material',
		icon: Material,
		tag: '',
		available: true,
		budgeting: true,
	},
	EXP_OTHER_PAYMENTS: {
		name: 'ba-other-payments',
		icon: OtherPayments,
		tag: '',
		available: true,
		budgeting: true,
	},
	EXP_RENT_NEW: {
		name: 'ba-rent',
		icon: Rent,
		tag: '',
		available: true,
		budgeting: true,
	},
	EXP_SUPPLIERS: {
		name: 'ba-suppliers',
		icon: Suppliers,
		tag: '',
		available: true,
		budgeting: true,
	},
	EXP_TAX: {
		name: 'ba-tax',
		icon: Tax,
		tag: '',
		available: true,
		budgeting: true,
	},
	EXP_TRAVEL: {
		name: 'ba-travel',
		icon: Travel,
		tag: '',
		available: true,
		budgeting: true,
	},
	EXP_VAT: {
		name: 'ba-vat',
		icon: Vat,
		tag: '',
		available: true,
		budgeting: true,
	},
	EXP_WAGES: {
		name: 'ba-wages',
		icon: Wages,
		tag: '',
		available: true,
		budgeting: true,
	},

	// Receipts
	REC_CUSTOMERS: {
		name: 'res-customers',
		icon: Sales,
		tag: ' Project',
		available: true,
		budgeting: false,
	},
	REC_LOAN_ADVANCE: {
		name: 'res-load-advance',
		icon: LoanAdvance,
		tag: ' Project',
		available: true,
		budgeting: false,
	},
	REC_OTHER_RECEIPTS: {
		name: 'res-other-receipts',
		icon: OtherIncome,
		tag: ' Project',
		available: true,
		budgeting: false,
	},

	// Payments (Cash Forecast)
	PAY_RENT: {
		name: 'pay-rent',
		icon: Rent,
		tag: ' Project',
		available: true,
		budgeting: false,
	},
	PAY_BUSINESS_RATES: {
		name: 'pay-business-rates',
		icon: BusinessRates,
		tag: ' Project',
		available: true,
		budgeting: false,
	},
	PAY_ENERGY: {
		name: 'pay-energy',
		icon: Energy,
		tag: ' Project',
		available: true,
		budgeting: false,
	},
	PAY_SUPPLIERS: {
		name: 'pay-suppliers',
		icon: Suppliers,
		tag: ' Project',
		available: true,
		budgeting: false,
	},
	PAY_WAGES: {
		name: 'pay-wages',
		icon: Wages,
		tag: ' Project',
		available: true,
		budgeting: false,
	},
	PAY_LOAN_REPAYMENT: {
		name: 'pay-loan-repayment',
		icon: LoanRepayment,
		tag: ' Project',
		available: true,
		budgeting: false,
	},
	PAY_PAYE: {
		name: 'pay-paye',
		icon: Paye,
		tag: ' Project',
		available: true,
		budgeting: false,
	},
	PAY_OTHER_PAYMENTS: {
		name: 'pay-other-payments',
		icon: OtherPayments,
		tag: ' Project',
		available: true,
		budgeting: false,
	},
	PAY_TAX: {
		name: 'pay-tax',
		icon: Tax,
		tag: ' Project',
		available: true,
		budgeting: false,
	},
	PAY_VAT: {
		name: 'pay-vat',
		icon: Vat,
		tag: ' Project',
		available: true,
		budgeting: false,
	},
};

export const IncomeIcons = [
	ItemIcons.DEFAULT,
	// === OLD ICONS ===
	ItemIcons.MI_WAGES,
	ItemIcons.MI_BENEFITS,
	ItemIcons.MI_SALE_OF_UNWANTED_ITEMS,
	ItemIcons.MI_TRANSFER_FROM_SAVINGS,
	ItemIcons.CH_SAVINGS_FUTURE_EVENT,
	ItemIcons.MI_POCKET_MONEY,
	ItemIcons.MI_OTHER,
	// === NEW ICONS ===
	// income (budgeting)
	ItemIcons.INC_SALES,
	ItemIcons.INC_OTHER_INCOME,
	// receipts (cash forecast)
	ItemIcons.REC_CUSTOMERS,
	ItemIcons.REC_LOAN_ADVANCE,
	ItemIcons.REC_OTHER_RECEIPTS,
];

export const ExpenseIcons = [
	ItemIcons.DEFAULT,
	// === OLD ICONS ===
	// basics
	ItemIcons.BA_SUPERMARKET,
	ItemIcons.BA_RENT,
	ItemIcons.BA_COUNCIL_TAX,
	ItemIcons.BA_GAS,
	ItemIcons.BA_ELECTRIC,
	ItemIcons.BA_WATER,
	ItemIcons.BA_UTILITIES,
	ItemIcons.BA_INTERNET,
	ItemIcons.BA_MOBILE,
	ItemIcons.BA_CLOTHES,
	ItemIcons.BA_TV_LICENCE,
	ItemIcons.BA_INSURANCE_CONTENTS,
	ItemIcons.BA_SAVINGS_EMERGENCY,
	ItemIcons.BA_CAR_SERVICE,
	ItemIcons.BA_CAR_ROAD_TAX,
	ItemIcons.BA_CAR_FUEL,
	ItemIcons.BA_INSURANCE,
	ItemIcons.BA_INSURANCE_CAR,
	ItemIcons.BA_INSURANCE_TRAVEL,
	ItemIcons.BA_LOAN,
	ItemIcons.BA_MORTGAGE,
	ItemIcons.BA_TRANSPORT,
	ItemIcons.BA_OTHER,
	ItemIcons.OTHER,
	// credit card
	ItemIcons.CC_BILL,
	// choices
	ItemIcons.CH_CAFE,
	ItemIcons.CH_CINEMA,
	ItemIcons.CH_COMPUTER_GAMES,
	ItemIcons.CH_FASHION,
	ItemIcons.CH_FILM_MUSIC,
	ItemIcons.CH_HOLIDAY,
	ItemIcons.CH_MEAL_OUT,
	ItemIcons.CH_NIGHT_OUT,
	ItemIcons.CH_CONCERT,
	ItemIcons.CH_SAVINGS_FUTURE_EVENT,
	ItemIcons.CH_SPORT,
	ItemIcons.CH_SPORTS_EVENT,
	ItemIcons.CH_TREATS,
	ItemIcons.CH_TAKEAWAY,
	ItemIcons.CH_SODA,
	ItemIcons.CH_SWEETS,
	ItemIcons.CH_OTHER,
	// === NEW ICONS ===
	// expenses (budgeting)
	ItemIcons.EXP_BUSINESS_RATES,
	ItemIcons.EXP_COMPUTER_AND_SOFTWARE,
	ItemIcons.EXP_CONTRACTORS,
	ItemIcons.EXP_DELIVERY,
	ItemIcons.EXP_ENERGY,
	ItemIcons.EXP_INSURANCE_NEW,
	ItemIcons.EXP_INTEREST_PAYABLE,
	ItemIcons.EXP_LEGAL_AND_PROFESSIONAL,
	ItemIcons.EXP_MARKETING,
	ItemIcons.EXP_MATERIAL,
	ItemIcons.EXP_OTHER_PAYMENTS,
	ItemIcons.EXP_RENT_NEW,
	ItemIcons.EXP_SUPPLIERS,
	ItemIcons.EXP_TAX,
	ItemIcons.EXP_TRAVEL,
	ItemIcons.EXP_VAT,
	ItemIcons.EXP_WAGES,
	// payment (cash forecast)
	ItemIcons.PAY_RENT,
	ItemIcons.PAY_BUSINESS_RATES,
	ItemIcons.PAY_ENERGY,
	ItemIcons.PAY_SUPPLIERS,
	ItemIcons.PAY_WAGES,
	ItemIcons.PAY_LOAN_REPAYMENT,
	ItemIcons.PAY_PAYE,
	ItemIcons.PAY_OTHER_PAYMENTS,
	ItemIcons.PAY_TAX,
	ItemIcons.PAY_VAT,
];

export const RepaymentOfLoansIcons = [ItemIcons.PAY_LOAN_REPAYMENT];

export const ObligatoryExpenditureIcons = [
	ItemIcons.PAY_RENT,
	ItemIcons.PAY_BUSINESS_RATES,
	ItemIcons.PAY_ENERGY,
	ItemIcons.PAY_SUPPLIERS,
	ItemIcons.PAY_WAGES,
	ItemIcons.PAY_PAYE,
	ItemIcons.PAY_OTHER_PAYMENTS,
	ItemIcons.PAY_TAX,
	ItemIcons.PAY_VAT,
];

export const DiscretionaryExpenditureIcons = [
	ItemIcons.PAY_SUPPLIERS,
	ItemIcons.PAY_WAGES,
	ItemIcons.PAY_OTHER_PAYMENTS,
];

export const DefaultIcons = [ItemIcons.DEFAULT];

export function findIcon(iconName) {
	return Object.values(ItemIcons).filter(e => e.name === iconName)[0];
}
