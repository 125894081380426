import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Container, Typography, Grid, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Colours } from '../../helpers/Colours';
import { push } from 'connected-react-router';
import logoImg from '../../images/logo.svg';

const useStyles = makeStyles(theme => ({
	masthead: {
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: `100%`,
		minHeight: '100vh',
		backgroundColor: Colours.bg_grey_1,
		'& .logo-wrapper': {
			display: 'flex',
			'& img': {
				maxWidth: '100%',
				maxHeight: 200,
				padding: 48,
				borderRadius: 4,
				[theme.breakpoints.down('xs')]: {
					margin: 'auto',
					padding: 12,
					marginBottom: 32,
				},
			},
		},
		'&.logged-in': {
			minHeight: 'calc(100vh - 48px)',
		},
		'& .makeStyles-masthead-17': {
			padding: '0px !important',
		},
	},
	mastheadInner: {
		display: 'flex',
		position: 'relative',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		[theme.breakpoints.down('xs')]: {
			paddingBottom: 60,
			paddingTop: 60,
		},
		'& .icon-wrapper': {
			position: 'absolute',
			top: 0,
			right: 0,
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		'& .MuiGrid-item': {
			zIndex: 1,
		},
		'& .image': {
			maxHeight: 250,
		},
	},
	btnMarginRight: {
		marginRight: 16,
	},
	btnMarginBottom: {
		marginBottom: 16,
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
	},
	item: {
		flex: 1,
		display: 'flex',
		alignItems: 'center',
		backgroundColor: Colours.white,
		minHeight: 300,
		width: '100vw',
	},
}));

function Home(props) {
	const { Auth, PushHistory } = props;
	const classes = useStyles();

	async function handleStartForecasting() {
		PushHistory('/CashForecast');
	}

	function buildButtons(text, onStart, loginRedirect, howItWorksLink, disabled = false) {
		if (Auth.isAuthenticated) {
			return (
				<>
					<Button
						color="primary"
						variant="contained"
						onClick={onStart}
						className={`${classes.btnMarginRight} ${classes.btnMarginBottom}`}
					>
						{text}
					</Button>
					<Button
						color="primary"
						variant="outlined"
						component={Link}
						to={howItWorksLink}
						className={classes.btnMarginBottom}
						disabled={disabled}
					>
						How it Works
					</Button>
				</>
			);
		} else {
			return (
				<Button color="primary" variant="contained" component={Link} to={`/Login?redirect=${loginRedirect}`}>
					Sign In / Register
				</Button>
			);
		}
	}

	return (
		<div className={`${classes.masthead} ${Auth.isAuthenticated ? 'logged-in' : ''}`}>
			<div className={classes.container}>
				<div className={classes.item}>
					<Container maxWidth="md" className={classes.mastheadInner}>
						<Grid container>
							<Grid item xs={12} className="logo-wrapper">
								<img src={logoImg} alt="Logo" />
							</Grid>
						</Grid>
					</Container>
				</div>
				<div className={classes.item} style={{ backgroundColor: '#dfdfdf' }}>
					<Container maxWidth="md" className={classes.mastheadInner}>
						<Grid container>
							<Grid item sm={7} xs={12}>
								<Typography variant="h4" gutterBottom style={{ marginBottom: 24 }}>
									Cash Flow Forecasting App
								</Typography>
								{buildButtons(
									'Start Forecasting',
									() => handleStartForecasting(),
									'CashForecast',
									'/CashForecastGuide'
								)}
							</Grid>
						</Grid>
					</Container>
				</div>
				<div className={classes.item}>
					<Container maxWidth="md" className={classes.mastheadInner}>
						<Grid container>
							<Grid item sm={7} xs={12}>
								<Typography variant="h4" gutterBottom style={{ marginBottom: 24 }}>
									Budgeting App
								</Typography>
								{buildButtons(
									'Start Budgeting',
									() => PushHistory('/BudgetPlan'),
									'BudgetPlan',
									'/BudgetPlanGuide',
									true
								)}
							</Grid>
						</Grid>
					</Container>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = state => ({
	Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
	PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);

Home.propTypes = {
	Auth: PropTypes.object,
	PushHistory: PropTypes.func,
};
