import React from 'react';
import PropTypes from 'prop-types';
import UserController from '../../controllers/UserController';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { TextField, Button, Grid, Container, Typography } from '@material-ui/core';
import { Alert } from '../../components/Common/Alert';

export function EmailChange(props) {
	const [currentPassword, setCurrentPassword] = React.useState('');
	const [newEmail, setNewEmail] = React.useState('');
	const [showSuccess, setShowSuccess] = React.useState(false);
	const [submitting, setSubmitting] = React.useState(false);
	const [warningText, setWarningText] = React.useState(null);

	// initialise
	React.useEffect(() => {
		if (!props.Auth.isAuthenticated) {
			props.PushHistory('/Login');
		}
	}, [props]);

	async function handleSubmit(event) {
		event.preventDefault();
		setSubmitting(true);
		const response = await UserController.requestEmailChange(newEmail, currentPassword);
		if (response.hasError) {
			setWarningText(response.data);
		} else {
			setShowSuccess(true);
		}
		setSubmitting(false);
	}

	function handleInput(event) {
		const name = event.target.name;
		const value = event.target.value;

		switch (name) {
			case 'currentPassword':
				setCurrentPassword(value);
				break;
			case 'newEmail':
				setNewEmail(value);
				break;
			default:
				return;
		}
	}

	function buildChangeEmailForm() {
		return (
			<form onSubmit={handleSubmit}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TextField
							id="newEmail-input"
							label="New Email"
							value={newEmail}
							onChange={handleInput}
							name="newEmail"
							variant="filled"
							required
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="new-password-input"
							label="Password"
							type="password"
							value={currentPassword}
							onChange={handleInput}
							name="currentPassword"
							variant="filled"
							required
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<Alert header="Something went wrong!" text={warningText} />
					</Grid>
					<Grid item xs={12}>
						<Button fullWidth disabled={submitting} variant="contained" color="primary" type="submit">
							Request Email Change
						</Button>
					</Grid>
				</Grid>
			</form>
		);
	}

	function buildConfirmChange() {
		return (
			<>
				<Typography align="center" paragraph>
					We have sent an email to {newEmail}
				</Typography>
				<Typography align="center" paragraph>
					Please click the link within that email to confirm the change
				</Typography>
			</>
		);
	}

	return (
		<Container maxWidth="xs">
			<Typography variant="h2" align="center" gutterBottom style={{ marginTop: 40 }}>
				{showSuccess ? 'Request Sent' : 'Change Email'}
			</Typography>
			{showSuccess ? buildConfirmChange() : buildChangeEmailForm()}
		</Container>
	);
}

const mapStateToProps = state => ({
	Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
	PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailChange);

EmailChange.propTypes = {
	Auth: PropTypes.object,
	PushHistory: PropTypes.func,
};
