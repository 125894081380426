import React from 'react';
import PropTypes from 'prop-types';
import MoneyPlanController from '../../controllers/MoneyPlanController';
import {
	makeStyles,
	GridList,
	GridListTile,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	DialogActions,
	Button,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import { DefaultIcons, IncomeIcons, ExpenseIcons } from '../../helpers/ItemIconLib';
import { MoneyPlanCategory } from '../../helpers/Constants';
import { Colours } from '../../helpers/Colours';
import { isNullOrUndefined } from '../../helpers/Utils';

const useStyles = makeStyles(theme => ({
	root: ({ smallScreen }) => ({
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'start',
		alignItems: 'center',
		overflowY: 'auto',
		...(smallScreen ? { padding: '12px 0px' } : { padding: '12px' }),
	}),
	gridList: {
		width: 500,
		overflowY: 'unset',
		[theme.breakpoints.down('xs')]: {
			width: '90vw',
		},
	},
	imageTile: {
		marginBottom: 10,
		'& .icon': {
			borderRadius: 5,
			backgroundColor: Colours.bg_grey_1,
			width: 150,
			height: 150,
			maxHeight: 150,
			padding: 25,
			[theme.breakpoints.down('xs')]: {
				width: '27vw',
				height: '27vw',
				padding: 15,
			},
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			'& img': {
				objectFit: 'contain',
				objectPosition: 'center',
				height: '100%',
				width: '100%',
			},
			'&:hover': {
				backgroundColor: Colours.secondary,
				cursor: 'pointer',
			},
		},
	},
}));

export default function MoneyPlanIconList(props) {
	const { category, onDone, onCancel, onError } = props;
	const [loading, setLoading] = React.useState(false);
	const theme = useTheme();
	const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
	const classes = useStyles({ smallScreen });

	async function handleSubmit(item) {
		if (loading) {
			return;
		}
		setLoading(true);
		const response = await MoneyPlanController.addNewValue(item.name, category.id, 0, 0);
		if (!response.hasError) {
			onDone(response.data);
		} else {
			onError(response.data);
		}
		setLoading(false);
	}

	function getIconList() {
		switch (category?.id) {
			case MoneyPlanCategory.Income.id:
				return IncomeIcons;
			case MoneyPlanCategory.Basics.id:
			case MoneyPlanCategory.RepayDebts.id:
			case MoneyPlanCategory.Choices.id:
				return ExpenseIcons;
			default:
				return DefaultIcons;
		}
	}

	function buildIconList() {
		const icons = getIconList().filter((e, i) => i !== 0 && e.available && e.budgeting);
		return (
			<>
				<GridList cellHeight="auto" className={classes.gridList} cols={3}>
					{icons.map(icon => (
						<GridListTile key={icon.name} className={classes.imageTile}>
							<div className="icon" onClick={() => handleSubmit(icon)}>
								<img src={icon.icon} alt={icon.name} />
							</div>
						</GridListTile>
					))}
				</GridList>
			</>
		);
	}

	return (
		<Dialog open={!isNullOrUndefined(category)} fullScreen={smallScreen} fullWidth>
			<DialogTitle>Select {category?.name} Icon</DialogTitle>
			<Divider />
			<DialogContent className={classes.root}>{buildIconList()}</DialogContent>
			<Divider />
			<DialogActions>
				<Button color="primary" onClick={onCancel}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}

MoneyPlanIconList.propTypes = {
	category: PropTypes.any,
	onDone: PropTypes.func,
	onCancel: PropTypes.func,
	onError: PropTypes.func,
};
