import React from 'react';
import PropTypes from 'prop-types';
import {
	makeStyles,
	ListItemAvatar,
	ListItem,
	ListItemText,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Card,
	Divider,
	DialogActions,
	Typography,
} from '@material-ui/core';
import {
	DefaultIcons,
	IncomeIcons,
	ExpenseIcons,
	RepaymentOfLoansIcons,
	DiscretionaryExpenditureIcons,
	ObligatoryExpenditureIcons,
} from '../../helpers/ItemIconLib';
import { ItemIcon } from './ItemIcon';
import { Colours } from '../../helpers/Colours';
import { Category } from '../../helpers/Constants';
import {
	isNullOrWhitespace,
	isNullOrUndefined,
	getRenamedCategory,
	getIconName,
	getIconFontSize,
} from '../../helpers/Utils';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		backgroundColor: theme.palette.grey[200],
		padding: '8px 16px 8px 0px',
		borderRadius: 4,
		'& .btn': {
			width: '50%',
		},
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
			'& .btn': {
				width: '100%',
				marginTop: 16,
				marginLeft: 16,
			},
		},
	},
	iconWrapper: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		flexWrap: 'wrap',
		maxWidth: 324,
	},
	iconCard: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: 6,
		marginBottom: 6,
		backgroundColor: Colours.bg_grey_2,
		'& .icon': {
			height: 100,
			width: 100,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			'& img': {
				objectFit: 'contain',
				objectPosition: 'center',
				height: '50%',
				width: '50%',
			},
			[theme.breakpoints.down('xs')]: {
				height: 60,
				width: 60,
			},
		},
		'&.active': {
			backgroundColor: Colours.primary50,
		},
		'&:hover': {
			backgroundColor: Colours.primary25,
			cursor: 'pointer',
		},
	},
	tip: {
		color: Colours.bodyText,
		marginTop: 8,
	},
	noLeftRightPad: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	colorWrapper: {
		backgroundColor: theme.palette.grey[300],
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexWrap: 'wrap',
	},
	colorBlock: {
		display: 'inline-block',
		height: 50,
		width: 50,
		'&:hover': {
			borderColor: Colours.white + ' !important',
			cursor: 'pointer',
		},
		'&.selected': {
			borderColor: Colours.white + ' !important',
		},
	},
}));

export function ItemIconField(props) {
	const { category, itemName, itemValue, iconName, iconBGColour, onChange } = props;
	const [name, setName] = React.useState(iconName?.toString());
	const [showIconMenu, setShowIconMenu] = React.useState(false);
	const classes = useStyles();

	React.useEffect(() => {
		setName(iconName?.toString());
	}, [iconName, iconBGColour]);

	function handleSubmit() {
		onChange(name);
		setShowIconMenu(false);
	}

	function getIconList() {
		switch (category) {
			case Category.MoneyIn:
				return IncomeIcons;
			case Category.Basics:
				return ObligatoryExpenditureIcons;
			case Category.CreditCardBill:
				return RepaymentOfLoansIcons;
			case Category.Choices:
				return DiscretionaryExpenditureIcons;
			default:
				return DefaultIcons;
		}
	}

	function buildIcon(icon) {
		if (isNullOrUndefined(icon.icon)) {
			const name = getIconName(itemName);
			const fontSize = getIconFontSize(name);

			return (
				<span
					style={{
						fontWeight: 'bold',
						color: Colours.white,
						fontSize,
					}}
				>
					{name}
				</span>
			);
		}
		return <img src={icon.icon} alt={icon.tag} />;
	}

	function buildIconMenuDialog() {
		const icons = getIconList().filter(e => e.available && !e.budgeting);
		return (
			<Dialog open={showIconMenu} onClose={() => setShowIconMenu(false)}>
				<DialogTitle style={{ fontSize: 18 }}>Choose {getRenamedCategory(category)} Icon</DialogTitle>
				<Divider />
				<DialogContent>
					<ListItem className={classes.noLeftRightPad}>
						<ListItemAvatar>
							<ItemIcon itemName={itemName} iconName={name} iconBGColour={iconBGColour} />
						</ListItemAvatar>
						<ListItemText primary={itemName} secondary={itemValue} />
					</ListItem>
					<div className={classes.iconWrapper}>
						{icons.map(icon => (
							<Card
								className={`${classes.iconCard} ${icon.name === name ? 'active' : 'inactive'}`}
								key={icon.name}
								variant="outlined"
								onClick={() => setName(icon.name)}
								about={icon.tag}
							>
								<div className="icon">{buildIcon(icon)}</div>
							</Card>
						))}
					</div>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button onClick={() => setShowIconMenu(false)} color="primary">
						Cancel
					</Button>
					<Button onClick={() => handleSubmit()} color="primary">
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	return (
		<>
			<div className={classes.root}>
				<ListItem>
					<ListItemAvatar>
						<ItemIcon itemName={itemName} iconName={iconName} iconBGColour={iconBGColour} />
					</ListItemAvatar>
					<ListItemText primary={itemName} secondary={itemValue} />
				</ListItem>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => setShowIconMenu(true)}
					className="btn"
					disabled={isNullOrWhitespace(category) || isNullOrWhitespace(itemName)}
					style={{ textTransform: 'unset' }}
				>
					Choose Icon (optional)
				</Button>
			</div>
			{buildIconMenuDialog()}
		</>
	);
}

ItemIconField.propTypes = {
	category: PropTypes.string.isRequired,
	itemName: PropTypes.string.isRequired,
	itemValue: PropTypes.string.isRequired,
	iconName: PropTypes.string,
	iconBGColour: PropTypes.string,
	onChange: PropTypes.func.isRequired,
};
