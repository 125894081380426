import Axios from 'axios';
import UserController from './UserController';
import { APIPath, APIRoute } from '../helpers/Constants';
import { generateResponse } from '../helpers/Utils';

const ForecastController = {
	getForecast,
	setForecast,
};

async function getForecast() {
	const userHeaders = await UserController.getUserHeaders();
	return Axios.get(APIRoute + APIPath.GET_CURRENT_FORECAST, userHeaders)
		.then(response => {
			return generateResponse(false, response.data);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

async function setForecast(startDate, bankStart, creditStart) {
	const userHeaders = await UserController.getUserHeaders();
	const forecastModel = {
		startDate,
		bankStart,
		creditStart,
	};
	return Axios.post(APIRoute + APIPath.SET_CURRENT_FORECAST, forecastModel, userHeaders)
		.then(() => {
			return generateResponse(false, null);
		})
		.catch(reason => {
			return generateResponse(true, reason);
		});
}

export default ForecastController;
