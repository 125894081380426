import React from 'react';
import { Grid, Typography, Container, Card, CardContent, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	root: {
		overflowY: 'hidden',
		marginTop: 120,
		marginBottom: 120,
		paddingTop: 20,
	},
}));

function EULA() {
	const classes = useStyles();

	return (
		<Container maxWidth="sm" component={Card} variant="outlined" className={classes.root}>
			<CardContent>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography variant="h2" gutterBottom>
							End User Licence Agreement
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							PLEASE READ THESE LICENCE TERMS CAREFULLY
						</Typography>
						<Typography variant="body2" paragraph>
							BY USING THE WEB-BASED APP YOU AGREE TO THESE TERMS AND ACKNOWLEDGE IN PARTICULAR THE
							LIMITATIONS ON LIABILITY HIGHLIGHTED BELOW.
						</Typography>
						<Typography variant="body2" paragraph>
							IF YOU DO NOT AGREE TO THESE TERMS DO NOT USE THE APP.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							WHO WE ARE AND WHAT THIS AGREEMENT DOES
						</Typography>
						<Typography variant="body2" paragraph>
							We, The Financial Literacy Innovation Project CIC of Ground Floor, 32 Park Cross Street,
							Leeds, LS1 2QH, license you to use:
						</Typography>
						<ul>
							<li>Cash Forecasting Web App (App).</li>
						</ul>
						<Typography variant="body2" paragraph>
							as permitted in these terms.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							YOUR PRIVACY
						</Typography>
						<Typography variant="body2" paragraph>
							We only use any personal data we collect through your use of the App in the ways set out in
							our <a href="/Privacy">Privacy Policy</a>.
						</Typography>
						<Typography variant="body2" paragraph>
							Please be aware that internet transmissions are never completely private or secure and that
							any message or information you send using the App may be read or intercepted by others, even
							if there is a special notice that a particular transmission is encrypted.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							SYSTEM REQUIREMENTS
						</Typography>
						<Typography variant="body2" paragraph>
							Use of this App is optimised with systems having a modern desk-based browser.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							SUPPORT FOR THE APP AND HOW TO TELL US ABOUT PROBLEMS
						</Typography>
						<Typography variant="body2" paragraph>
							Support. There are no support services provided for this App as the purpose of this license
							is for gathering market research and opinion in order for us to develop a commercial end
							product.
						</Typography>
						<Typography variant="body2" paragraph>
							<b>Contacting us.</b> If you wish to contact us directly, please use our address above.
						</Typography>
						<Typography variant="body2" paragraph>
							<b>How we will communicate with you.</b> If we need to contact you we will do so by using
							any contact details you have provided directly to us.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							WE MAY COLLECT TECHNICAL DATA ABOUT YOUR COMPUTER
						</Typography>
						<Typography variant="body2" paragraph>
							By using the App, you agree to us collecting and using technical information about the
							computer on which you use the App and related software, hardware and peripherals to improve
							our products.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							WE ARE NOT RESPONSIBLE FOR OTHER WEBSITES TO WHICH YOU LINK
						</Typography>
						<Typography variant="body2" paragraph>
							The App may contain links to other independent websites which are not provided by us. Such
							independent sites are not under our control, and we are not responsible for and have not
							checked and approved their content or their privacy policies (if any). You will need to make
							your own independent judgement about whether to use any such independent sites, including
							whether to buy any products or services offered by them.
						</Typography>
						<Typography variant="h6" paragraph>
							LICENSE RESTRICTIONS
						</Typography>
						<Typography variant="body2" paragraph>
							You agree that you will:
						</Typography>
						<ul>
							<li>
								not transfer, rent, lease, sub-license, loan, provide, or otherwise make available, the
								App in any form, in whole or in part to any person;
							</li>
							<li>
								not translate, merge, adapt, vary, alter or modify, the whole or any part of the App,
								nor permit the App to be combined with, or become incorporated in, any other programs,
								except as necessary to use the App on the device as permitted in these terms;
							</li>
							<li>
								not disassemble, de-compile, reverse engineer or create derivative works based on the
								whole or any part of the App nor attempt to do any such things;
							</li>
							<li>
								comply with all applicable technology control or export laws and regulations that apply
								to the technology used or supported by the App.
							</li>
						</ul>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							ACCEPTABLE USE RESTRICTIONS
						</Typography>
						<Typography variant="body2" paragraph>
							You must:
						</Typography>
						<ul>
							<li>
								not use the App in any unlawful manner, for any unlawful purpose, or in any manner
								inconsistent with these terms, or act fraudulently or maliciously, for example, by
								hacking into or inserting malicious code, such as viruses, or harmful data, into the App
								or any operating system;
							</li>
							<li>
								not infringe our intellectual property rights or those of any third party in relation to
								your use of the App, including by the submission of any material (to the extent that
								such use is not licensed by these terms);
							</li>
							<li>
								not transmit any material that is defamatory, offensive or otherwise objectionable in
								relation to your use of the App; and
							</li>
							<li>
								not use the App in a way that could damage, disable, overburden, impair or compromise
								our systems or security or interfere with other users.
							</li>
						</ul>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							INTELLECTUAL PROPERTY RIGHTS
						</Typography>
						<Typography variant="body2" paragraph>
							All intellectual property rights in the App throughout the world belong to us and the rights
							in the App are licensed (not sold) to you. You have no intellectual property rights in, or
							to, the App other than the right to use them in accordance with these terms.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							WARRANTY
						</Typography>
						<Typography variant="body2" paragraph>
							We do not give any warranties as to the performance of the App.
						</Typography>
						<Typography variant="body2" paragraph>
							We do not warrant that the use of the App will be uninterrupted or error-free.
						</Typography>
						<Typography variant="body2" paragraph>
							All other conditions, warranties or other terms which might have effect between us or be
							implied or incorporated into this license, whether by statute, common law or otherwise, are
							hereby excluded, including implied conditions, warranties or other terms as to satisfactory
							quality, fitness for purpose or the use of reasonable skill and care.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							LIMITATIONS ON LIABILITY
						</Typography>
						<Typography variant="body2" paragraph>
							You acknowledge that the App has not been developed to meet your individual requirements.
						</Typography>
						<Typography variant="body2" paragraph>
							The App is provided for gathering market research and opinion in order for us to develop a
							commercial end product only. It does not offer advice on which you should rely. If you use
							the App for any commercial, business or resale purpose we will have no liability to you for
							any loss of profit, loss of business, business interruption, or loss of business
							opportunity.
						</Typography>
						<Typography variant="body2" paragraph>
							We shall not, except for the EXCLUSIONS below, in any circumstances whatever be liable to
							you for any loss or damage which may be suffered by you (or any person claiming under or
							through you), whether arising in contract, tort (through negligence), breach of statutory
							duty, or otherwise, whether the same are suffered directly or indirectly or are immediate or
							consequential under or in connection with this license including any third party claim for
							copyright infringement EXCEPT for:
						</Typography>
						<ul>
							<li>Death or personal injury caused by our negligence; or</li>
							<li>Fraud or fraudulent misrepresentation; or</li>
							<li>Any other liability that cannot be excluded or limited by English Law.</li>
						</ul>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							TERMINATION
						</Typography>
						<Typography variant="body2" paragraph>
							This license ends on the date the App ceases to be made available on the internet.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							WE MAY END YOUR RIGHTS TO USE THE APP ON NOTICE OR IF YOU BREAK THESE TERMS
						</Typography>
						<Typography variant="body2" paragraph>
							We may end this license on 14 days’ notice at any time.
						</Typography>

						<Typography variant="body2" paragraph>
							We may end your rights to use the App at any time by contacting you if you have broken these
							terms in a serious way. If what you have done can be put right we will give you a reasonable
							opportunity to do so.
						</Typography>
						<Typography variant="body2" paragraph>
							If we end your rights to use the App:
						</Typography>
						<ul>
							<li>
								You must stop all activities authorised by these terms, including your use of the App.
							</li>
						</ul>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							WE MAY TRANSFER THIS AGREEMENT TO SOMEONE ELSE
						</Typography>
						<Typography variant="body2" paragraph>
							We may transfer our rights and obligations under these terms to another organisation. We
							will take all reasonable steps to ensure that the transfer will not affect your rights under
							the contract.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							NO RIGHTS FOR THIRD PARTIES
						</Typography>
						<Typography variant="body2" paragraph>
							This agreement does not give rise to any rights under the Contracts (Rights of Third
							Parties) Act 1999 to enforce any term of this agreement.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							IF A COURT FINDS PART OF THIS CONTRACT ILLEGAL, THE REST WILL CONTINUE IN FORCE
						</Typography>
						<Typography variant="body2" paragraph>
							Each of the paragraphs of these terms operates separately. If any court or relevant
							authority decides that any of them are unlawful, the remaining paragraphs will remain in
							full force and effect.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							EVEN IF WE DELAY IN ENFORCING THIS CONTRACT, WE CAN STILL ENFORCE IT LATER
						</Typography>
						<Typography variant="body2" paragraph>
							Even if we delay in enforcing this contract, we can still enforce it later. If we do not
							insist immediately that you do anything you are required to do under these terms, or if we
							delay in taking steps against you in respect of your breaking this contract, that will not
							mean that you do not have to do those things and it will not prevent us taking steps against
							you at a later date.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							WHICH LAWS APPLY TO THIS CONTRACT AND WHERE YOU MAY BRING LEGAL PROCEEDINGS
						</Typography>
						<Typography variant="body2" paragraph>
							These terms are governed by English law and you can bring legal proceedings in respect of
							the products in the English courts. If you live in Scotland you can bring legal proceedings
							in respect of the products in either the Scottish or the English courts. If you live in
							Northern Ireland you can bring legal proceedings in respect of the products in either the
							Northern Irish or the English courts.
						</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Container>
	);
}

export default EULA;
