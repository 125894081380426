import React from 'react';
import { Grid, Typography, Container, Card, CardContent, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	root: {
		overflowY: 'hidden',
		marginTop: 120,
		marginBottom: 120,
		paddingTop: 20,
	},
}));

function PrivacyPolicy() {
	const classes = useStyles();

	return (
		<Container maxWidth="sm" component={Card} variant="outlined" className={classes.root}>
			<CardContent>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography variant="h2" gutterBottom>
							Privacy Policy
						</Typography>
						<Typography variant="subtitle2" gutterBottom>
							Last updated August 21, 2020
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body2" paragraph>
							Thank you for choosing to be part of our community at Financial Literacy Innovation Project
							CIC (“Company”, “we”, “us”, or “our”). We are committed to protecting your personal
							information and your right to privacy. If you have any questions or concerns about this
							privacy notice, or our practices with regards to your personal information, please contact
							us at stephen@flipside.uk.com.
						</Typography>
						<Typography variant="body2" paragraph>
							When you visit our website http://www.flipside.uk.com (the “Website”), and more generally,
							use any of our services (the “Services”, which include the Website), we appreciate that you
							are trusting us with your personal information. We take your privacy very seriously. In this
							privacy notice, we seek to explain to you in the clearest way possible what information we
							collect, how we use it and what rights you have in relation to it. We hope you take some
							time to read through it carefully, as it is important. If there are any terms in this
							privacy notice that you do not agree with, please discontinue use of our Services
							immediately.
						</Typography>
						<Typography variant="body2" paragraph>
							This privacy notice applies to all information collected through our Services (which, as
							described above, includes our Website), as well as any related services, sales, marketing or
							events.
						</Typography>
						<Typography variant="body2" paragraph>
							<b>
								Please read this privacy notice carefully as it will help you understand what we do with
								the information that we collect.
							</b>
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							1. WHAT INFORMATION DO WE COLLECT?
						</Typography>
						<Typography variant="body2" paragraph>
							<b>Personal information you disclose to us</b>
						</Typography>
						<Typography variant="body2" paragraph>
							<b>In Short:</b> We collect information that you provide to us
						</Typography>
						<Typography variant="body2" paragraph>
							We collect personal information that you voluntarily provide to us when you express an
							interest in obtaining information about us or our products and Services, when you
							participate in activities on the Website or otherwise when you contact us.
						</Typography>
						<Typography variant="body2" paragraph>
							The personal information that we collect depends on the context of your interactions with us
							and the Website, the choices you make and the products and features you use. The personal
							information we collect may include the following:
						</Typography>
						<Typography variant="body2" paragraph>
							<b>Personal Information Provided by You.</b> We collect email addresses; usernames;
							passwords; and other similar information.
						</Typography>
						<Typography variant="body2" paragraph>
							All personal information that you provide to us must be true, complete and accurate, and you
							must notify us of any changes to such personal information.
						</Typography>
						<br />
						<Typography variant="body2" paragraph>
							<b>Information automatically collected</b>
						</Typography>
						<Typography variant="body2" paragraph>
							<b>In Short:</b> Some information — such as your Internet Protocol (IP) address and/or
							browser and device characteristics — is collected automatically when you visit our Website.
						</Typography>
						<Typography variant="body2" paragraph>
							We automatically collect certain information when you visit, use or navigate the Website.
							This information does not reveal your specific identity (like your name or contact
							information) but may include device and usage information, such as your IP address, browser
							and device characteristics, operating system, language preferences, referring URLs, device
							name, country, location, information about who and when you use our Website and other
							technical information. This information is primarily needed to maintain the security and
							operation of our Website, and for our internal analytics and reporting purposes.
						</Typography>
						<Typography variant="body2" paragraph>
							Like many businesses, we also collect information through cookies and similar technologies.
						</Typography>
						<Typography variant="body2" paragraph>
							The information we collect includes:
						</Typography>
						<ul>
							<li>
								Log and Usage Data. Log and usage data is service-related, diagnostic usage and
								performance information our servers automatically collect when you access or use our
								Website and which we record in log files. Depending on how you interact with us, this
								log data may include your IP address, device information, browser type and settings and
								information about your activity in the Website (such as the date/time stamps associated
								with your usage, pages and files viewed, searches and other actions you take such as
								which features you use), device event information (such as system activity, error
								reports (sometimes called &amp;crash dumps&amp;) and hardware settings).
							</li>
						</ul>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							2. HOW DO WE USE YOUR INFORMATION?
						</Typography>
						<Typography variant="body2" paragraph>
							<b>In Short:</b> We process your information for purposes based on legitimate business
							interests, the fulfillment of our contract with you, compliance with our legal obligations,
							and/or your consent.
						</Typography>
						<Typography variant="body2" paragraph>
							We use personal information collected via our Website for a variety of business purposes
							described below. We process your personal information for these purposes in reliance on our
							legitimate business interests, in order to enter into or perform a contract with you, with
							your consent, and/or for compliance with our legal obligations. We indicate the specific
							processing grounds we rely on next to each purpose listed below.
						</Typography>
						<Typography variant="body2" paragraph>
							We use the information we collect or receive:
						</Typography>
						<ul>
							<li>
								<b>To facilitate account creation and logon process.</b> If you choose to link your
								account with us to a third-party account (such as your Google or Facebook account), we
								use the information you allowed us to collect from those third parties to facilitate
								account creation and logon process for the performance of the contract.
							</li>
							<li>
								<b>To post testimonials.</b> We post testimonials on our Website that may contain
								personal information. Prior to posting a testimonial, we will obtain your consent to use
								your name and the consent of the testimonial. If you wish to update, or delete your
								testimonial, please contact us at stephen@flipside.uk.com and be sure to include your
								name, testimonial location, and contact information.
							</li>
							<li>
								<b>Request feedback.</b> We may use your information to request feedback and to contact
								you about your use of our Website.
							</li>
							<li>
								<b>To enable user-to-user communications.</b> We may use your information in order to
								enable user-to-user communications with each user&amp;s consent.
							</li>
							<li>
								<b>To manage user accounts.</b> We may use your information for the purposes of managing
								our account and keeping it in working order.
							</li>
							<li>
								<b>Fulfill and manage your orders.</b> We may use your information to fulfill and manage
								your orders, payments, returns, and exchanges made through the Website.
							</li>
							<li>
								<b>Administer prize draws and competitions.</b> We may use your information to
								administer prize draws and competitions when you elect to participate in our
								competitions.
							</li>
							<li>
								<b>To deliver and facilitate delivery of services to the user.</b> We may use your
								information to provide you with the requested service.
							</li>
							<li>
								<b>To respond to user inquiries/offer support to users.</b> We may use your information
								to respond to your inquiries and solve any potential issues you might have with the use
								of our Services.
							</li>
						</ul>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
						</Typography>
						<Typography variant="body2" paragraph>
							<b>In Short:</b> We only share information with your consent, to comply with laws, to
							provide you with services, to protect your rights, or to fulfill business obligations.
						</Typography>
						<Typography variant="body2" paragraph>
							We may process or share your data that we hold based on the following legal basis:
						</Typography>
						<ul>
							<li>
								<b>Consent:</b> We may process your data if you have given us specific consent to use
								your personal information in a specific purpose.
							</li>
							<li>
								<b>Legitimate Interests:</b> We may process your data when it is reasonably necessary to
								achieve our legitimate business interests.
							</li>
							<li>
								<b>Performance of a Contract:</b> Where we have entered into a contract with you, we may
								process your personal information to fulfill the terms of our contract.
							</li>
							<li>
								<b>Legal Obligations:</b> We may disclose your information where we are legally required
								to do so in order to comply with applicable law, governmental requests, a judicial
								proceeding, court order, or legal process, such as in response to a court order or a
								subpoena (including in response to public authorities to meet national security or law
								enforcement requirements).
							</li>
							<li>
								<b>Vital Interests:</b> We may disclose your information where we believe it is
								necessary to investigate, prevent, or take action regarding potential violations of our
								policies, suspected fraud, situations involving potential threats to the safety of any
								person and illegal activities, or as evidence in litigation in which we are involved.
							</li>
						</ul>
						<Typography variant="body2" paragraph>
							More specifically, we may need to process your data or share your personal information in
							the following situations:
						</Typography>
						<ul>
							<li>
								<b>Business Transfers.</b> We may share or transfer your information in connection with,
								or during negotiations of, any merger, sale of company assets, financing, or acquisition
								of all or a portion of our business to another company.
							</li>
							<li>
								<b>Affiliates.</b> We may share your information with our affiliates, in which case we
								will require those affiliates to honor this privacy notice. Affiliates include our
								parent company and any subsidiaries, joint venture partners or other companies that we
								control or that are under common control with us.
							</li>
							<li>
								<b>Business Partners.</b> We may share your information with our business partners to
								offer you certain products, services or promotions.
							</li>
						</ul>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
						</Typography>
						<Typography variant="body2" paragraph>
							<b>In Short:</b> We may use cookies and other tracking technologies to collect and store
							your information.
						</Typography>
						<Typography variant="body2" paragraph>
							We may use cookies and similar tracking technologies (like web beacons and pixels) to access
							or store information. Specific information about how we use such technologies and how you
							can refuse certain cookies is set out in our Cookie Notice.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							5. DO WE USE GOOGLE MAPS?
						</Typography>
						<Typography variant="body2" paragraph>
							<b>In Short:</b> Yes, we use Google Maps for the purpose of providing better service.
						</Typography>
						<Typography variant="body2" paragraph>
							This Website uses Google Maps APIs which is subject to Google&amp;s Terms of Service. You
							may find the Google Maps APIs Terms of Service{' '}
							<a
								href="https://developers.google.com/maps/terms"
								rel="noopener noreferrer"
								target="_blank"
							>
								here
							</a>
							. To find out more about Google&amp;s Privacy Policy, please refer to this{' '}
							<a href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank">
								link
							</a>
							.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							6. HOW LONG DO WE KEEP YOUR INFORMATION?
						</Typography>
						<Typography variant="body2" paragraph>
							<b>In Short:</b> We keep your information for as long as necessary to fulfill the purposes
							outlined in this privacy notice unless otherwise required by law.
						</Typography>
						<Typography variant="body2" paragraph>
							We will only keep your personal information for as long as it is necessary for the purposes
							set out in this privacy notice, unless a longer retention period is required or permitted by
							law (such as tax, accounting or other legal requirements). No purpose in this notice will
							require us keeping your personal information for longer than 2 years.
						</Typography>
						<Typography variant="body2" paragraph>
							When we have no ongoing legitimate business need to process your personal information, we
							will either delete or anonymize such information, or, if this is not possible (for example,
							because your personal information has been stored in backup archives), then we will securely
							store your personal information and isolate it from any further processing until deletion is
							possible.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							7. HOW DO WE KEEP YOUR INFORMATION SAFE?
						</Typography>
						<Typography variant="body2" paragraph>
							<b>In Short:</b> We aim to protect your personal information through a system of
							organizational and technical security measures.
						</Typography>
						<Typography variant="body2" paragraph>
							We have implemented appropriate technical and organizational security measures designed to
							protect the security of any personal information we process. However, despite our safeguards
							and efforts to secure your information, no electronic transmission over the Internet or
							information storage technology can be guaranteed to be 100% secure, so we cannot promise or
							guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able
							to defeat our security, and improperly collect, access, steal, or modify your information.
							Although we will do our best to protect your personal information, transmission of personal
							information to and from our Website is at your own risk. You should only access the Website
							within a secure environment.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							8. DO WE COLLECT INFORMATION FROM MINORS?
						</Typography>
						<Typography variant="body2" paragraph>
							<b>In Short:</b> We do not knowingly collect data from or market to children under 18 years
							of age.
						</Typography>
						<Typography variant="body2" paragraph>
							We do not knowingly solicit data from or market to children under 18 years of age. By using
							the Website, you represent that you are at least 18 or that you are the parent or guardian
							of such a minor and consent to such minor dependent’s use of the Website. If we learn that
							personal information from users less than 18 years of age has been collected, we will
							deactivate the account and take reasonable measures to promptly delete such data from our
							records. If you become aware of any data we may have collected from children under age 18,
							please contact us at stephen@flipside.uk.com.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							9. WHAT ARE YOUR PRIVACY RIGHTS?
						</Typography>
						<Typography variant="body2" paragraph>
							<b>In Short:</b> In some regions, such as the European Economic Area, you have rights that
							allow you greater access to and control over your personal information. You may review,
							change, or terminate your account at any time.
						</Typography>
						<Typography variant="body2" paragraph>
							In some regions (like the European Economic Area), you have certain rights under applicable
							data protection laws. These may include the right (i) to request access and obtain a copy of
							your personal information, (ii) to request rectification or erasure; (iii) to restrict the
							processing of your personal information; and (iv) if applicable, to data portability. In
							certain circumstances, you may also have the right to object to the processing of your
							personal information. To make such a request, please use the contact details provided below.
							We will consider and act upon any request in accordance with applicable data protection
							laws.
						</Typography>
						<Typography variant="body2" paragraph>
							If we are relying on your consent to process your personal information, you have the right
							to withdraw your consent at any time. Please note however that this will not affect the
							lawfulness of the processing before its withdrawal, nor will it affect the processing of
							your personal information conducted in reliance on lawful processing grounds other than
							consent.
						</Typography>
						<Typography variant="body2" paragraph>
							If you are resident in the European Economic Area and you believe we are unlawfully
							processing your personal information, you also have the right to complain to your local data
							protection supervisory authority. You can find their contact details here:
							http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
						</Typography>
						<Typography variant="body2" paragraph>
							If you are resident in Switzerland, the contact details for the data protection authorities
							are available here: https://www.edoeb.admin.ch/edoeb/en/home.html.
						</Typography>
						<Typography variant="body2" paragraph>
							<b>Cookies and similar technologies:</b> Most Web browsers are set to accept cookies by
							default. If you prefer, you can usually choose to set your browser to remove cookies and to
							reject cookies. If you choose to remove cookies or reject cookies, this could affect certain
							features or services of our Website. To opt-out of interest-based advertising by advertisers
							on our Website visit http://www.aboutads.info/choices/.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							10. CONTROLS FOR DO-NOT-TRACK FEATURES
						</Typography>
						<Typography variant="body2" paragraph>
							Most web browsers and some mobile operating systems and mobile applications include a
							Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference
							not to have data about your online browsing activities monitored and collected. At this
							stage, no uniform technology standard for recognizing and implementing DNT signals has been
							finalized. As such, we do not currently respond to DNT browser signals or any other
							mechanism that automatically communicates your choice not to be tracked online. If a
							standard for online tracking is adopted that we must follow in the future, we will inform
							you about that practice in a revised version of this privacy notice.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							11. DO WE MAKE UPDATES TO THIS NOTICE?
						</Typography>
						<Typography variant="body2" paragraph>
							<b>In Short:</b> Yes, we will update this notice as necessary to stay compliant with
							relevant laws.
						</Typography>
						<Typography variant="body2" paragraph>
							We may update this privacy notice from time to time. The updated version will be indicated
							by an updated “Revised” date and the updated version will be effective as soon as it is
							accessible. If we make material changes to this privacy notice, we may notify you either by
							prominently posting a notice of such changes or by directly sending you a notification. We
							encourage you to review this privacy notice frequently to be informed of how we are
							protecting your information.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" paragraph>
							12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
						</Typography>
						<Typography variant="body2" paragraph>
							If you have questions or comments about this notice, you may contact our Data Protection
							Officer (DPO), Stephen Flicker, by email at stephen@flipside.uk.com, by phone at 07803
							184300, or by post to:
						</Typography>
						<Typography variant="body2" paragraph>
							Financial Literacy Innovation Project CIC
							<br />
							Stephen Flicker
							<br />
							Ground Floor
							<br />
							32 Park Cross Street
							<br />
							Leeds LS1 2QH
							<br />
							United Kingdom
						</Typography>
						<br />
						<Typography variant="body2" paragraph>
							<b>REQUEST TO REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU</b>
						</Typography>
						<Typography variant="body2" paragraph>
							Based on the applicable laws of your country, you may have the right to request access to
							the personal information we collect from you, change that information, or delete it in some
							circumstances. To request to review, update, or delete your personal information, please
							submit a request form by contacting us with the details listed above. We will respond to
							your request within 30 days.
						</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Container>
	);
}

export default PrivacyPolicy;
